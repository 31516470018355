import { Reducer } from 'redux';

import { addUnit, deleteUnit, getUnitsByPropertyId, updateUnit } from './routines';
import { UnitsState } from './types';

const initialState: UnitsState = {
  loading: false,
  selectedUnit: undefined,
  units: [],
};

const reducer: Reducer<UnitsState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger

    case deleteUnit.TRIGGER:
    case updateUnit.TRIGGER:
    case getUnitsByPropertyId.TRIGGER:
    case addUnit.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }

    // success
    case getUnitsByPropertyId.SUCCESS: {
      return { ...state, units: action.payload };
    }
    // Failure

    case getUnitsByPropertyId.FAILURE:
    case deleteUnit.FAILURE:
    case updateUnit.FAILURE:
    case addUnit.FAILURE: {
      return { ...state, loading: false, errors: action.payload, units: [] };
    }

    // Fulfill

    case getUnitsByPropertyId.FULFILL:
    case deleteUnit.FULFILL:
    case updateUnit.FULFILL:
    case addUnit.FULFILL: {
      return { ...state, loading: false };
    }
    default: {
      return state;
    }
  }
};

export { reducer as unitsReducer };
