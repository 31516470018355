
import React from 'react';

import MuiSvgIcon from '@material-ui/core/SvgIcon';
import PartnerIcon from '@material-ui/icons/StoreOutlined'
import { IconProps } from '../icons';

const PartnersIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 24 24" fontSize="inherit">
    <PartnerIcon />
  </MuiSvgIcon>
);

export default PartnersIcon;
