import { get, isEmpty } from "lodash";
import queryString from "query-string";
import { AnyAction } from "redux";
import { Action } from 'redux-actions';
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import { ApplicationState } from "../";
import { Picture } from "../../components/forms/PictureForm";
import callApi from "../../utils/callApi";
import { errorHandler } from "../../utils/errorHandler";
import { addGTMDataLayerWithRawData } from "../../utils/GoogleTagManager";
import { enqueueSnackbar } from "../notifications";
import { OrganisationState } from '../organisation';
import {
  addPropertyStepper,
  deleteImageById,
  deleteProperty,
  deselectProperty,
  generatePreviewToken,
  getProperties,
  getPropertyById,
  savePropertyInfo,
  selectFeature,
  selectProperty,
  setSeenProperty,
  setSelectedProperty,
  updateField,
  updateFlowExtras,
  updatePropertyInfo,
  updatePropertyStatus,
  uploadImage,
} from "./routines";

// Get current role
function* getCurrentUser() {
  const { auth } = yield select((state: ApplicationState) => state);
  const roles = get(auth, "user.roles", []);
  const role = roles.includes("flow-admin") ? "admin/" : "";

  const landlordUser = get(auth, "user._id", undefined);
  return { role, landlordUser };
}

// Get current state
const getOrganisationState = (state: ApplicationState) => state.organisation;

const getOrganisationQueryString = ({ organisation, subOrganisation }: OrganisationState) => {
  const queryObj = {
    organisation: get(organisation, "_id") || undefined,
    subOrganisation: get(subOrganisation, "_id") || undefined
  };
  return queryString.stringify(queryObj);
};

// Get Properties
function* handleGetProperties(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);
    yield put(getProperties.request());
    const res = yield call(
      callApi,
      "get",
      `/v3/${role}properties?${queryString.stringify(action.payload)}`
    );

    yield put(getProperties.success(res.data));
  } catch (err) {
    const response = get(err, "response");
    if (response) {
      yield put(getProperties.failure(errorHandler(response)));
    } else {
      yield put(getProperties.failure("An unknown error occured."));
    }
  } finally {
    yield put(getProperties.fulfill());
  }
}

// updte flowExtras
function* handleUpdateFlowExtras(action: AnyAction) {
  try {
    const { flowExtras, propertyId, selectedExtra } = action.payload;
    yield put(getPropertyById.request());

    yield call(callApi, "put", `/v3/properties/${propertyId}/flowExtras`, {
      data: flowExtras,
    });

    yield put(updateFlowExtras.success({ selectedExtra }));
  } catch (err) {
    const response = get(err, "response");
    if (response) {
      yield put(updateFlowExtras.failure(errorHandler(response)));
    } else {
      yield put(updateFlowExtras.failure("An unknown error occured."));
    }
  } finally {
    yield put(updateFlowExtras.fulfill());
  }
}

function* handleGetPropertyById(action: Action<{ propertyId: string }>): any {
  try {
    const orgState = yield select(getOrganisationState);
    const { role } = yield call(getCurrentUser);
    const { propertyId } = action.payload;
    yield put(getPropertyById.request());

    const queryStr = getOrganisationQueryString(orgState);

    const res = yield call(
      callApi,
      "get",
      `/v3/${role}properties/${propertyId}?${queryStr}`
    );

    yield put(getPropertyById.success(res.data));
  } catch (err) {
    const response = get(err, "response");
    if (response) {
      yield put(getPropertyById.failure(errorHandler(response)));
    } else {
      yield put(getPropertyById.failure("An unknown error occured."));
    }
  } finally {
    yield put(getPropertyById.fulfill());
  }
}

// Select feature by name
function* handleSelectFeature(action: AnyAction) {
  yield put(selectFeature.request());
  yield put(selectFeature.success(action.payload));
}

// Update field
function* handleUpdateField(action: AnyAction) {
  yield put(updateField.request());
  yield put(updateField.success(action.payload));
}

// Select property
function* handleSelectProperty(action: AnyAction) {
  yield put(selectProperty.request());
  yield put(selectProperty.success(action.payload));
}

// Deselect property
function* handleDeselectProperty(action: AnyAction) {
  yield put(deselectProperty.request());
  yield put(deselectProperty.success(action.payload));
}

// Delete Property
function* handleDeleteProperty(action: AnyAction): any {
  try {
    const { propertyId } = action.payload;
    yield put(deleteProperty.request());
    const { role } = yield call(getCurrentUser);
    const orgState = yield select(getOrganisationState);
    const queryStr = getOrganisationQueryString(orgState);
    const res = yield call(
      callApi,
      "delete",
      `/v3/${role}properties/${propertyId}?${queryStr}`
    );

    yield put(deleteProperty.success(res.data));
  } catch (err) {
    const response = get(err, "response");
    if (response) {
      yield put(deleteProperty.failure(errorHandler(response)));
    } else {
      yield put(deleteProperty.failure("An unknown error occured."));
    }
  } finally {
    yield put(deleteProperty.fulfill());
  }
}

function* handleDeleteImageByID(action: AnyAction): any {
  try {
    const orgState = yield select(getOrganisationState);
    yield put(deleteImageById.request());
    const { role } = yield call(getCurrentUser);
    const { imageId, id, path } = action.payload;
    yield put(deleteProperty.request());

    const queryStr = getOrganisationQueryString(orgState);

    const res = yield call(
      callApi,
      "delete",
      `/v3/${role}${path}/${id}/image/${imageId}?${queryStr}`
    );
    yield put(deleteImageById.success(res.data));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(deleteImageById.failure(errorHandler(err)));
    }
  } finally {
    yield put(deleteImageById.fulfill());
  }
}

// Upload Property Image
function* handleUploadImage(action: AnyAction): any {
  try {
    yield put(uploadImage.request());
    const { role } = yield call(getCurrentUser);
    const { images, id, path } = action.payload;
    const orgState = yield select(getOrganisationState);
    const responseData = Object.keys(images).map((key, index) => {
      const image: Picture = images[key];
      if (image.file) {
        const formData = new FormData();
        formData.append("file", image.file);
        formData.append("title", image.caption);
        formData.append("index", index.toString());
        formData.append("type", image.imageType.toLowerCase());
        if (image.imageId) {
          formData.append("imageId", image.imageId);
        }
        const config = {
          data: formData,
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        const queryStr = getOrganisationQueryString(orgState);

        return call(
          callApi,
          "post",
          `/v3/${role}${path}/${id}/image?${queryStr}`,
          config
        );
      }
      return undefined;
    });

    const res = yield all(responseData);

    yield put(uploadImage.success(res.data));
  } catch (err) {
    const response = get(err, "response");
    if (response) {
      yield put(uploadImage.failure(errorHandler(response)));
    } else {
      yield put(uploadImage.failure(errorHandler(err)));
    }
  } finally {
    yield put(uploadImage.fulfill());
  }
}

// Add property stepper
function* handleAddPropertyStepper(action: AnyAction) {
  try {
    yield put(addPropertyStepper.request());
    yield put(addPropertyStepper.success(action.payload));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(addPropertyStepper.failure(errorHandler(err)));
    }
  } finally {
    yield put(addPropertyStepper.fulfill());
  }
}

// Generate preview token
function* handleGeneratePreviewToken(action: AnyAction): any {
  try {
    const { propertyId } = action.payload;
    const orgState = yield select(getOrganisationState);
    const { role } = yield call(getCurrentUser);

    const queryStr = getOrganisationQueryString(orgState);
    const url = `/v3/${role}properties/${propertyId}/preview?${queryStr}`

    yield put(generatePreviewToken.request());
    const res = yield call(callApi, "put", url);
    yield put(generatePreviewToken.success(res.data));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(generatePreviewToken.failure(errorHandler(err)));
    }
  } finally {
    yield put(generatePreviewToken.fulfill());
  }
}

// Save Property Information
function* handleSavePropertyInfo(action: AnyAction): any {
  try {
    const { organisation, user, property } = action.payload;
    yield put(savePropertyInfo.request());
    const { role } = yield call(getCurrentUser);
    const res = yield call(callApi, "post", `/v3/${role}properties`, {
      data: property,
    });

    yield put(savePropertyInfo.success(res.data));

    addGTMDataLayerWithRawData(
      {
        event: "StartedAddedProperty",
        page: "Add-property",
      },
      { organisation, property, user }
    );
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(savePropertyInfo.failure(errorHandler(err)));
    }
  } finally {
    yield put(savePropertyInfo.fulfill());
  }
}

// Update Property Information
function* handleUpdatePropertyInfo(action: AnyAction): any {
  try {
    const { role, landlordUser } = yield call(getCurrentUser);
    const { organisation, user, property, eventName } = action.payload;

    const orgState = yield select(getOrganisationState);

    yield put(updatePropertyInfo.request());

    const orgQueryStr = getOrganisationQueryString(orgState);

    const res = yield call(
      callApi,
      "put",
      `/v3/${role}properties/${property._id}?${orgQueryStr}&landlordUser=${landlordUser}`,
      {
        data: property,
      }
    );

    yield put(updatePropertyInfo.success(res.data));
    if (eventName !== "none") {
      addGTMDataLayerWithRawData(
        {
          event: !isEmpty(eventName) ? eventName : "UpdatedProperty",
          page: "Edit-property",
        },
        { organisation, property, user }
      );
    }
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(updatePropertyInfo.failure(errorHandler(err)));
    }
  } finally {
    yield put(updatePropertyInfo.fulfill());
  }
}

function* handleUpdatePropertyStatus(action: AnyAction): any {
  const { propertyId, status } = action.payload;
  const orgState = yield select(getOrganisationState);
  const { role, landlordUser } = yield call(getCurrentUser);
  try {
    yield put(updatePropertyStatus.request());

    const orgQueryStr = getOrganisationQueryString(orgState);

    const res = yield call(
      callApi,
      "put",
      `/v3/${role}properties/${propertyId}/status?status=${status}&${orgQueryStr}&landlordUser=${landlordUser}`
    );
    yield put(updatePropertyStatus.success(res.data));
  } catch (err) {
    const response = get(err, "response");
    if (response) {
      yield put(updatePropertyStatus.failure(errorHandler(response)));
    } else {
      yield put(updatePropertyStatus.failure("An unknown error occured."));
    }
  } finally {
    yield put(updatePropertyStatus.fulfill());
  }
}

// Error handlers
function* handlePropertyError(action: AnyAction) {
  yield put(
    enqueueSnackbar({
      message: action.payload,
      options: {
        variant: "error",
      },
    })
  );
}

function* handleSetSelectedProperty(action: AnyAction) {
  try {
    yield put(setSelectedProperty.request());
    yield put(setSelectedProperty.success(action.payload));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(setSelectedProperty.failure(errorHandler(err)));
    }
  } finally {
    yield put(setSelectedProperty.fulfill());
  }
}

function* handleSetSeenProperty(action: Action<string>): any {
  try {
    yield put(setSeenProperty.success(action.payload));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(setSeenProperty.failure(errorHandler(err)));
    }
  } finally {
    yield put(setSeenProperty.fulfill());
  }
}

function* getPropertiesWatcher() {
  yield takeEvery(getProperties.TRIGGER, handleGetProperties);
}

function* handleDeleteImageByIDWatcher() {
  yield takeEvery(deleteImageById.TRIGGER, handleDeleteImageByID);
}

function* handleUpdateFlowExtrasWatcher() {
  yield takeEvery(updateFlowExtras.TRIGGER, handleUpdateFlowExtras);
}

function* getPropertyByIdWatcher() {
  yield takeEvery(getPropertyById.TRIGGER, handleGetPropertyById);
}

function* selectFeatureWatcher() {
  yield takeEvery(selectFeature.TRIGGER, handleSelectFeature);
}

function* updateFieldWatcher() {
  yield takeEvery(updateField.TRIGGER, handleUpdateField);
}

function* selectPropertyWatcher() {
  yield takeEvery(selectProperty.TRIGGER, handleSelectProperty);
}

function* deselectPropertyWatcher() {
  yield takeEvery(deselectProperty.TRIGGER, handleDeselectProperty);
}

function* generatePreviewTokenWatcher() {
  yield takeEvery(generatePreviewToken.TRIGGER, handleGeneratePreviewToken);
}

function* uploadImageWatcher() {
  yield takeEvery(uploadImage.TRIGGER, handleUploadImage);
}

function* deletePropertyWatcher() {
  yield takeEvery(deleteProperty.TRIGGER, handleDeleteProperty);
}

function* updatePropertyStatusWatcher() {
  yield takeEvery(updatePropertyStatus.TRIGGER, handleUpdatePropertyStatus);
}

function* addPropertyStepperWatcher() {
  yield takeEvery(addPropertyStepper.TRIGGER, handleAddPropertyStepper);
}

function* savePropertyInfoWatcher() {
  yield takeEvery(savePropertyInfo.TRIGGER, handleSavePropertyInfo);
}

function* updatePropertyInfoWatcher() {
  yield takeEvery(updatePropertyInfo.TRIGGER, handleUpdatePropertyInfo);
}

function* setSelectedPropertyInfoWatcher() {
  yield takeEvery(setSelectedProperty.TRIGGER, handleSetSelectedProperty);
}

function* setSeenPropertyWatcher(): any {
  yield takeEvery(setSeenProperty.TRIGGER, handleSetSeenProperty);
}

function* propertyErrorWatcher() {
  yield takeEvery(
    [
      updateFlowExtras.FAILURE,
      getProperties.FAILURE,
      getPropertyById.FAILURE,
      deleteProperty.FAILURE,
      savePropertyInfo.FAILURE,
      updatePropertyStatus.FAILURE,
      updatePropertyInfo.FAILURE,
      uploadImage.FAILURE,
      generatePreviewToken.FAILURE,
      deleteImageById.FAILURE,
    ],
    handlePropertyError
  );
}

export function* propertiesSaga() {
  yield all([
    fork(getPropertiesWatcher),
    fork(getPropertyByIdWatcher),
    fork(selectFeatureWatcher),
    fork(propertyErrorWatcher),
    fork(deletePropertyWatcher),
    fork(addPropertyStepperWatcher),
    fork(savePropertyInfoWatcher),
    fork(updatePropertyInfoWatcher),
    fork(updatePropertyStatusWatcher),
    fork(updateFieldWatcher),
    fork(deselectPropertyWatcher),
    fork(uploadImageWatcher),
    fork(selectPropertyWatcher),
    fork(generatePreviewTokenWatcher),
    fork(handleUpdateFlowExtrasWatcher),
    fork(handleDeleteImageByIDWatcher),
    fork(setSelectedPropertyInfoWatcher),
    fork(setSeenPropertyWatcher),
  ]);
}
