import React, { FocusEventHandler } from "react";
import { Button } from "../buttons";
import styled from "../../utils/styledComponents";
import { isEmpty, get } from "lodash";

const StyledButton = styled(Button)<{ fontSize: string }>`
  && {
    padding: 0px;
    height: 0px;
    font-size: ${({ fontSize }) => fontSize} !important;
    font-weight: 500;
    min-width: auto;
  }
`;
interface OwnProps {
  url?: string;
  styles?: any;
  onBlur?: FocusEventHandler;
  onClick?: () => void;
  title: string;
}

type AllProps = OwnProps;

const CustomLink: React.FunctionComponent<AllProps> = ({
  url,
  title,
  styles,
  onBlur,
  onClick,
}) => {
  const openUrl = () => {
    window.open(url, "_blank");
  };
  const fontSize = get(styles, "fontSize", "16px");
  return (
    <StyledButton
      fontSize={fontSize}
      onBlur={onBlur}
      onClick={!isEmpty(url) ? openUrl : onClick}
    >
      {title}
    </StyledButton>
  );
};

export default CustomLink;
