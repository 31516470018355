import React from 'react';

import MuiSvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '../icons';

const GoogleIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 20 20">
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"google"}</title>
      <path
        d="M20 10.234C20 15.94 16.029 20 10.164 20 4.54 20 0 15.532 0 10S4.541 0 10.164 0c2.738 0 5.04.988 6.816 2.617l-2.767 2.617C10.594 1.798 3.865 4.379 3.865 10c0 3.488 2.832 6.315 6.299 6.315 4.025 0 5.533-2.84 5.77-4.311h-5.77v-3.44h9.676a8.63 8.63 0 01.16 1.67z"
        fill="#EA4336"
        fillRule="nonzero"
      />
    </svg>
  </MuiSvgIcon>
);

export default GoogleIcon;

