
import React from 'react';
import AsyncSelect from 'react-select/async';
import { ActionMeta, GroupedOptionsType, GroupTypeBase, OptionsType, OptionTypeBase } from 'react-select/src/types';
import { Option } from 'react-select/src/filters';

export interface OptiontypeProps {
  label: string;
  value: string;
}
export interface AsyncServerInputProps {
  onLoadPromiseOptions: (inputValue: string, callback: (options: OptionsType<OptionTypeBase>) => void) => Promise<any>;
  onSelect: (value: OptionTypeBase | null, action: ActionMeta<OptionTypeBase>) => void;
  defaultOption?: OptiontypeProps;
  placeholder?: string;
  isMulti?: boolean;
  defaultOptions?: boolean | OptionsType<OptionTypeBase> | GroupedOptionsType<OptionTypeBase, GroupTypeBase<OptionTypeBase>> | undefined;
  filterOption?: ((option: Option, rawInput: string) => boolean) | null | undefined;
}

const AsyncServerSearchInput: React.FunctionComponent<AsyncServerInputProps> = (props) => {
  const {
    onLoadPromiseOptions,
    onSelect,
    defaultOption,
    ...rest
  } = props;

  const loadOptionsCb = React.useCallback(onLoadPromiseOptions, []);

  return (
    <AsyncSelect
      {...rest}
      value={defaultOption}
      cacheOptions={false}
      backspaceRemovesValue={true}
      loadOptions={loadOptionsCb}
      onChange={onSelect}
      isClearable
      isSearchable
      styles={{
        container: base => ({
          ...base,
          width: '100%'
        }),
        multiValue: base => ({
          ...base,
          borderRadius: 8,
          backgroundColor: '#f2f2f2'
        }),
        control: base => ({
          ...base,
          borderStyle: 'inherit',
        }),
        menu: base => ({
          ...base,
          textAlign: 'left'
        })
      }}
    />
  );
}

export default AsyncServerSearchInput;
