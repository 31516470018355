import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import MuiIconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import MuiToolTip from "@material-ui/core/Tooltip";

import { FlowThemeInterface } from "../../styles/theme";
import { IconProps } from "../icons";
import { makeStyles } from "@material-ui/styles";

interface StyledIconButtonProps {
  active?: number;
}

const IconButton = styled(
  MuiIconButton as React.FunctionComponent<
    IconButtonProps & StyledIconButtonProps
  >
)`
  && {
    margin-top: 3px;
    margin-bottom: 3px;
    background-color: ${({ active, theme }) =>
      active ? theme.colors.grey[200] : "transparent"};
    height: 50px;
    width: 50px;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 10px;
`;

const StyledLabel = styled.div<{ active: boolean }>`
  font-size: 14px;
  margin-left: 15px;
  color: ${({ theme, active }) =>
    active ? theme.colors.grey4 : theme.colors.grey3};
`;

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: "14px", // Change the font size
    padding: "10px", // Adjust the padding
  },
}));

interface OwnProps {
  linkTo: string;
  iconComponent: React.FunctionComponent<IconProps>;
  currentRoute: string;
  activeColor?: string;
  defaultColor?: string;
  theme: FlowThemeInterface;
  label?: string;
  disableTooltip?: boolean;
}

type AllProps = OwnProps;

const SideBarButton: React.FunctionComponent<AllProps> = ({
  linkTo,
  iconComponent,
  currentRoute,
  defaultColor,
  activeColor,
  label,
  disableTooltip = true,
  ...props
}) => {
  const classes = useStyles();
  return (
    <StyledLink to={linkTo}>
      <MuiToolTip
        title={label}
        placement="right"
        classes={{ tooltip: classes.tooltip }}
        disableHoverListener={disableTooltip}
        disableFocusListener={disableTooltip}
        disableTouchListener={disableTooltip}
      >
        <IconButton
          aria-label="Communication"
          active={currentRoute.includes(linkTo) ? 1 : 0}
          {...props}
        >
          {iconComponent({
            nativeColor: currentRoute.includes(linkTo)
              ? activeColor
              : defaultColor,
          })}
        </IconButton>
      </MuiToolTip>
      <StyledLabel active={currentRoute.includes(linkTo)} {...props}>
        {label}
      </StyledLabel>
    </StyledLink>
  );
};

const StyledSideBarButton = styled(SideBarButton).attrs((props: AllProps) => ({
  activeColor: props.theme.colors.grey3,
  defaultColor: props.theme.colors.grey3,
}))``;

export default StyledSideBarButton;
