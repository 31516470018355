import React from "react";

import MuiSvgIcon from "@material-ui/core/SvgIcon";

import { IconProps } from "../icons";

const FacebookAudienceIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 40 41">
    <svg
      width={40}
      height={41}
      viewBox="0 0 40 41"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>FacebookAudience</title>
      <g
        id="CMS-Settings-V2"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="FacebookAudience" transform="translate(0.000000, 0.811707)">
          <path
            d="M14.4767857,28.9858537 L10.3303571,28.9858537 L10.3303571,15.6396886 L14.4767857,15.6396886 L14.4767857,28.9858537 Z M12.4013393,13.8191485 C11.0754464,13.8191485 10,12.7214699 10,11.3962239 C10,10.0706478 11.0751162,8.996056 12.4013393,8.996056 C13.7275623,8.996056 14.8026786,10.0706478 14.8026786,11.3962239 C14.8026786,12.7214699 13.7267857,13.8191485 12.4013393,13.8191485 Z M29.9955357,28.9858537 L25.8580357,28.9858537 L25.8580357,22.4890244 C25.8580357,20.9406729 25.8267857,18.9550348 23.7022321,18.9550348 C21.5464286,18.9550348 21.2160714,20.6372496 21.2160714,22.3774717 L21.2160714,28.9858537 L17.0741071,28.9858537 L17.0741071,15.6396886 L21.0508929,15.6396886 L21.0508929,17.4602287 L21.1089286,17.4602287 C21.6625,16.4116333 23.0147321,15.3050305 25.0321429,15.3050305 C29.2285714,15.3050305 30,18.0670753 30,21.6546102 L30,28.9858537 L29.9955357,28.9858537 Z"
            id="Shape-Copy-3"
            fill="#FFFFFF"
            fill-rule="nonzero"
          ></path>
          <rect
            id="Rectangle-Copy-21"
            fill="#F0F0F0"
            x="0"
            y="0"
            width="40"
            height="39.9804878"
            rx="19.9902439"
          ></rect>
          <g
            id="th-copy"
            transform="translate(8.0, 7.8)"
            fill="#868788"
            fill-rule="nonzero"
          >
            <path
              d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,18H6l0-1.61c0-1.18,0.68-2.26,1.76-2.73 C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1 C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85 C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M12,6c1.66,0,3,1.34,3,3 c0,1.66-1.34,3-3,3s-3-1.34-3-3C9,7.34,10.34,6,12,6z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </MuiSvgIcon>
);

export default FacebookAudienceIcon;
