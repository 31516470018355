import React from "react";
import styled, { mediaDown } from "../../utils/styledComponents";
import Button from "../buttons/Button";
import ActionDialog from "./ActionDialog";

const Header = styled.div`
  margin-bottom: 10px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey4};
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  font-size: 24px;
  line-height: 30px;
`;

const StyledContent = styled.div`
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.grey2};
  font-family: ${({ theme }) => theme.fonts.MS300};
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  font-size: 14px;
`;
const Footer = styled.div`
  display: flex;
  ${mediaDown.sm`flex-direction: column; width: 100%;`};
  > div:first-child {
    margin-right: 20px;
    ${mediaDown.sm`margin-bottom: 10px; margin-right: 0`}
  }
`;
const StyledButton = styled(Button)`
  && {
    height: 60px;
    font-size: 16px;
    margin: 10px;
    ${mediaDown.sm` max-width: 100% !important;`}
  }
`;

const StyledActionDialog = styled(ActionDialog)`
  && {
    .MuiPaper-root {
      border-radius: 10px;
    }
  }
`;

const DialogBody = styled.div`
  padding: 30px 0px;
  ${mediaDown.sm`padding: 20px; width: auto`};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  align-items: center;
`;
interface OwnProps {
  onContinue: () => void;
  onLogout: () => void;
  isOpen: boolean;
}
const IdleTimeOutDialog: React.FunctionComponent<OwnProps> = ({
  onContinue,
  onLogout,
  isOpen,
}) => {
  return (
    <StyledActionDialog
      open={isOpen}
      onClose={onContinue}
      onBackdropClick={onContinue}
      contentComponent={
        <DialogBody>
          <Header>
            <span>You have been idle for some while!</span>
          </Header>
          <StyledContent>
            <span>
              You will automatically get logged out in few seconds. You want to
              continue?
            </span>
          </StyledContent>
          <Footer>
            <StyledButton
              variant="outlined"
              fullWidth={true}
              onClick={onContinue}
            >
              Continue
            </StyledButton>
            <StyledButton
              variant="contained"
              fullWidth={true}
              onClick={onLogout}
            >
              Logout
            </StyledButton>
          </Footer>
        </DialogBody>
      }
    />
  );
};

export default IdleTimeOutDialog;
