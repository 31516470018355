import React from 'react';

import MuiSvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '../icons';

const CommunicationIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props}>
    <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
      <g
        stroke={nativeColor}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M15.5 1.5h-10a4 4 0 0 0-4 4v5a4 4 0 0 0 4 4h1v4l4.5-4h4.5a4 4 0 0 0 4-4v-5a4 4 0 0 0-4-4zM12.5 17.5V19a2.5 2.5 0 0 0 2.5 2.5h2.5l3 3v-3H22a2.5 2.5 0 0 0 2.5-2.5v-3a2.5 2.5 0 0 0-2.5-2.5h-.5" />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default CommunicationIcon;
