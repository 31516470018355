import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const createChat = createRoutine("@@chat/LISTING_ENQUIRY");
export const createChatPromise = promisifyRoutine(createChat);

export const getChats = createRoutine("@@chat/GET_CHATS");
export const getChatsPromise = promisifyRoutine(getChats);

export const updateChat = createRoutine("@@chat/UPDATE_CHATS");
export const updateChatPromise = promisifyRoutine(updateChat);

export const setChatUnreadCount = createRoutine("@@chat/LISTING_CHAT_COUNT");

export const getProfile = createRoutine("@@auth/GET_PROFILE_BY_ID");
export const getProfilePromise = promisifyRoutine(getProfile);

export const inviteTenant = createRoutine("@@chat/SEND_INVITE");
export const inviteTenantPromise = promisifyRoutine(inviteTenant);
