import { Reducer } from 'redux';

import { fetchTickets, replyToTicket, selectTicket } from './routines';
import { Ticket, TicketsState } from './types';

const replaceTicket = (tickets: Ticket[], newTicket: Ticket): Ticket[] => {
  const newTicketIndex = tickets.findIndex(
    (ticket: Ticket) => ticket.id === newTicket.id
  );
  tickets[newTicketIndex] = newTicket;
  return tickets;
};

const initialState: TicketsState = {
  errors: undefined,
  listLoading: false,
  replyLoading: false,
  selectLoading: false,
  selectedTicket: undefined,
  tickets: []
};

const reducer: Reducer<TicketsState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger
    case fetchTickets.TRIGGER: {
      return { ...state, listLoading: true, errors: undefined };
    }
    case replyToTicket.TRIGGER: {
      return { ...state, replyLoading: true, errors: undefined };
    }
    case selectTicket.TRIGGER: {
      return { ...state, selectLoading: true, errors: undefined };
    }
    // Success
    case fetchTickets.SUCCESS: {
      return { ...state, tickets: action.payload };
    }
    case selectTicket.SUCCESS: {
      return { ...state, selectedTicket: action.payload };
    }

    case replyToTicket.SUCCESS: {
      return {
        ...state,
        selectedTicket: action.payload,
        tickets: replaceTicket(state.tickets, action.payload)
      };
    }

    // Failure
    case fetchTickets.FAILURE: {
      return { ...state, tickets: [], errors: action.payload };
    }
    case selectTicket.FAILURE: {
      return { ...state, selectedTicket: undefined, errors: action.payload };
    }

    // Fullfill
    case fetchTickets.FULFILL:
    case selectTicket.FULFILL:
    case replyToTicket.FULFILL: {
      return {
        ...state,
        listLoading: false,
        replyLoading: false,
        selectLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as ticketsReducer };
