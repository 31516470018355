// styled-components.ts
import * as styledComponents from 'styled-components';

import { FlowThemeInterface } from '../styles/theme';

interface StyleInterface
  extends styledComponents.ThemedStyledComponentsModule<FlowThemeInterface> {
  mediaUp: any;
  mediaDown: any;
}

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme
} = styledComponents as StyleInterface;

interface SizeInterface {
  xxs: number;
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

const sizes: SizeInterface = {
  lg: 1280,
  md: 960,
  sm: 600,
  xl: 1920,
  xs: 0,
  xxs: 320
};

const mediaUp = Object.keys(sizes).reduce(
  (acc, label): any => {
    acc[label] = (...args: any) => {
      const [first] = args;
      // https://stackoverflow.com/questions/50706337/importing-html-to-typescript-to-use-as-templatestringliteral
      const stringArray = [first, ...args] as any;
      stringArray.raw = [first, ...args];
      return css`
        @media (min-width: ${sizes[label]}px) {
          ${css(stringArray as TemplateStringsArray)}
        }
      `;
    };
    return acc;
  },
  {} as any
);

const mediaDown = Object.keys(sizes).reduce(
  (acc, label): any => {
    acc[label] = (...args: any) => {
      const [first] = args;
      // https://stackoverflow.com/questions/50706337/importing-html-to-typescript-to-use-as-templatestringliteral
      const stringArray = [first, ...args] as any;
      stringArray.raw = [first, ...args];
      return css`
        @media (max-width: ${sizes[label] - 1}px) {
          ${css(stringArray as TemplateStringsArray)}
        }
      `;
    };
    return acc;
  },
  {} as any
);

const mediaHeightUp = Object.keys(sizes).reduce(
  (acc, label): any => {
    acc[label] = (...args: any) => {
      const [first] = args;
      // https://stackoverflow.com/questions/50706337/importing-html-to-typescript-to-use-as-templatestringliteral
      const stringArray = [first, ...args] as any;
      stringArray.raw = [first, ...args];
      return css`
        @media (min-height: ${sizes[label]}px) {
          ${css(stringArray as TemplateStringsArray)}
        }
      `;
    };
    return acc;
  },
  {} as any
);

const mediaHeightDown = Object.keys(sizes).reduce(
  (acc, label): any => {
    acc[label] = (...args: any) => {
      const [first] = args;
      // https://stackoverflow.com/questions/50706337/importing-html-to-typescript-to-use-as-templatestringliteral
      const stringArray = [first, ...args] as any;
      stringArray.raw = [first, ...args];
      return css`
        @media (max-height: ${sizes[label] - 1}px) {
          ${css(stringArray as TemplateStringsArray)}
        }
      `;
    };
    return acc;
  },
  {} as any
);

export {
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme,
  mediaUp,
  mediaDown,
  mediaHeightUp,
  mediaHeightDown,
  sizes
};
export default styled;
