import React from "react";

import MuiSvgIcon from "@material-ui/core/SvgIcon";

import { IconProps } from "../icons";

const AnalyticsIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => {
  return (
    <MuiSvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 26 25"
      >
        <path d="M5 20v2h-2v-2h2zm2-2h-6v6h6v-6zm6-1v5h-2v-5h2zm2-2h-6v9h6v-9zm6-2v9h-2v-9h2zm2-2h-6v13h6v-13zm0-11l-6 1.221 1.716 1.708-6.85 6.733-3.001-3.002-7.841 7.797 1.41 1.418 6.427-6.39 2.991 2.993 8.28-8.137 1.667 1.66 1.201-6.001z" />
      </svg>
    </MuiSvgIcon>
  );
};

export default AnalyticsIcon;
