import get from "lodash/get";

export function errorHandler(error: any): string {
  try {
    let errorMessage = "";
    if (error.data) {
      errorMessage = get(error, "data.message", get(error, "data", undefined));
    } else if (error.response && error.response.data) {
      errorMessage = error.response.data.message;
    } else if (error.response) {
      errorMessage = error.response.data;
    } else if (error.request) {
      errorMessage = JSON.parse(error.request.responseText).message;
    } else if (error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = error;
    }

    return errorMessage;
  } catch (error) {
    console.log("error", error);
    return "Error";
  }
}
