import { History } from 'history';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { ButtonProps } from '@material-ui/core/Button';

import Button from './Button';

interface OwnProps {
  title?: string;
}

interface ConnectedProps {
  history: History;
}

type AllProps = ConnectedProps & OwnProps & ButtonProps & RouteComponentProps;

const BackButton: React.FunctionComponent<AllProps> = ({
  history,
  title = "Cancel",
  disabled
}) => {
  const onClick = () => {
    history.goBack();
  };

  return (
    <Button variant="outlined" onClick={onClick} disabled={disabled}>
      {title}
    </Button>
  );
};

export default withRouter(BackButton) as any;
