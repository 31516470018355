import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const addUnit = createRoutine("@@units/ADD_UNIT");
export const deleteUnit = createRoutine("@@units/DELETE_UNIT");
export const updateUnit = createRoutine("@@units/UPDATE_UNIT");
export const getUnitsByPropertyId = createRoutine("@@units/GET_UNITS_BY_PROPERTYID")

export const addUnitPromise = promisifyRoutine(addUnit);
export const deleteUnitPromise = promisifyRoutine(deleteUnit);
export const updateUnitPromise = promisifyRoutine(updateUnit);
export const getUnitsByPropertyIdPromise = promisifyRoutine(getUnitsByPropertyId);
