import { initialState as initialOrgState } from './organisation';

export const migrations = {
  1: (previousVersionState?: any) => {
    if (!previousVersionState) return {};
    return ({
      ...previousVersionState,
      organisation: {
        ...initialOrgState,
        ...previousVersionState.organisation
      }
    });
  }
};
