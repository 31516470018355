import produce from 'immer';
import { Reducer } from 'redux';

import { routeChange, setMode } from './routines';
import { Modes, NavigationState } from './types';

// Type-safe initialState!
const initialState: NavigationState = {
  routeProps: {
    secure: false,
    title: ""
  },
  mode: Modes.Default
};

const reducer: Reducer<NavigationState> = (state = initialState, action) => {
  switch (action.type) {
    case routeChange.SUCCESS: {
      return {
        ...state,
        routeProps: action.payload.routeProps
      };
    }

    case setMode.SUCCESS: {
      const newDerivedState = produce<NavigationState>(state, (draft: NavigationState) => {
        draft.mode = action.payload;
      });

      return newDerivedState;
    }

    default: {
      return state;
    }
  }
};

export { reducer as navigationReducer };
