import React from "react";

import MuiSvgIcon from "@material-ui/core/SvgIcon";

import { IconProps } from ".";

const EditIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon>
    <svg>
      <g fillRule="nonzero" stroke="#4c4c4e" strokeWidth={1.5} fill="none">
        <path d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default EditIcon;
