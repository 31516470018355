import React from "react";
import { IconProps } from ".";
import MuiSvgIcon from "@material-ui/core/SvgIcon";

const NavContentIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon>
    <svg>
      <g
        stroke={nativeColor}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M5.005 11.19V12l6.998 4.042L19 12v-.81M5 16.15v.81L11.997 21l6.998-4.042v-.81M12.003 3 5.005 7.042l6.998 4.042L19 7.042 12.003 3Z" />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default NavContentIcon;
