import React from 'react';

import MuiSvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '../icons';

const Townhouse: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 40 40" fontSize="inherit">
    <svg width={40} height={40} {...props}>
      <g fill={nativeColor} fillRule="nonzero">
        <path d="M9.333 24.121h9.334v-6.666H9.333v6.666zm1.334-1.333v-2h6.666v2h-6.666zm6.666-4v.667h-6.666v-.667h6.666zM9.333 26.788v8h9.334v-8H9.333zm4 1.333v2h-2.666v-2h2.666zm-2.666 3.334h2.666v2h-2.666v-2zm4 2v-2h2.666v2h-2.666zm2.666-3.334h-2.666v-2h2.666v2zM21.333 24.121h9.334v-6.666h-9.334v6.666zm1.334-1.333v-.667h6.666v.667h-6.666zm6.666-4v2h-6.666v-2h6.666z" />
        <path d="M39.333 32.121a.666.666 0 0 0-.666.667v.667H38v-.667a.666.666 0 1 0-1.333 0v.667H36v-13.47a2.65 2.65 0 0 0 1.084.242 2.673 2.673 0 0 0 2.656-2.881 2.678 2.678 0 0 0-1.015-1.889L21.815 2.23 20 .545l-1.771 1.648L1.275 15.457a2.673 2.673 0 0 0-1.014 1.889c-.059.742.198 1.48.704 2.025a2.646 2.646 0 0 0 3.036.615v13.47h-.668v-.667a.666.666 0 1 0-1.333 0v.666h-.667v-.666a.666.666 0 1 0-1.333 0v6a.666.666 0 1 0 1.333 0v-.667H2v.667a.666.666 0 1 0 1.333 0v-.667H4v1.333h32v-1.333h.667v.667a.666.666 0 1 0 1.333 0v-.667h.667v.667a.666.666 0 1 0 1.333 0v-6a.667.667 0 0 0-.667-.668zM1.941 18.463a1.318 1.318 0 0 1-.351-1.012c.03-.376.21-.711.507-.944l16.996-13.3.908-.843.949.88 16.953 13.263c.298.233.478.568.508.944a1.32 1.32 0 0 1-.352 1.012 1.327 1.327 0 0 1-1.79.149L36 18.405 20 6.072 4.26 18.205v.001l-.528.406c-.55.423-1.319.36-1.79-.149zm-.608 18.325v-2H2v2h-.667zm2 0v-2H4v2h-.667zm19.334 1.333v-10h6.666v10h-6.666zm12 0h-4V26.788h-9.334v11.333h-16v-19.06L20 7.755 34.667 19.06v19.06zM36 36.788v-2h.667v2H36zm2 0v-2h.667v2H38z" />
      </g>
    </svg>
    ) )
  </MuiSvgIcon>
);

export default Townhouse;
