import { css } from "../utils/styledComponents";

export default css`
  html,
  body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: "Museo-Sans-300", Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    font-smooth: antialiased;
  }
  a {
    color: inherit;
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  img {
    max-width: 100%;
    position: relative;
    vertical-align: middle;
  }
  figure {
    margin: 2rem 0;
  }
  figcaption {
    font-size: 80%;
  }
  table {
    width: 100%;
    font-size: 85%;
    border-collapse: collapse;
  }

  div {
    &:focus {
      outline: 0;
    }
  }

  @font-face {
    font-family: "MuseoSans-300";
    src: url("/fonts/MuseoSans-300.woff2") format("woff2"),
      url("/fonts/MuseoSans-300.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "MuseoSans-500";
    src: url("/fonts/MuseoSans-500.woff2") format("woff2"),
      url("/fonts/MuseoSans-500.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "MuseoSans-700";
    src: url("/fonts/MuseoSans-700.woff2") format("woff2"),
      url("/fonts/MuseoSans-700.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "MuseoSans-900";
    src: url("/fonts/MuseoSans-900.woff2") format("woff2"),
      url("/fonts/MuseoSans-900.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  #root {
    height: 100%;
  }

  .pac-container {
    z-index: 9999;
  }
  .react-pagination-js-bootstrap ul {
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-radius: 0px !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-family: "MuseoSans-500", Helvetica, sans-serif !important;
  }
  .react-pagination-js-bootstrap ul li:first-child {
    border-radius: 10px !important;
  }
  .react-pagination-js-bootstrap ul li:nth-child(2) {
    border-radius: 10px 0px 0px 10px !important;
  }
  .react-pagination-js-bootstrap ul li:nth-last-child(2) {
    border-radius: 0px 10px 10px 0px !important;
  }
  .react-pagination-js-bootstrap ul li:last-child {
    border-radius: 10px !important;
  }
  .react-pagination-js-bootstrap .page {
    text-decoration: none;
    border: 1px solid #f0f0f0 !important;
    color: #4c4c4e !important;
    background-color: #f0f0f0 !important;
    margin: 1px !important;
    font-family: "MuseoSans-500", Helvetica, sans-serif !important;
    font-size: 16px !important;
    width: auto;
    text-transform: none;
    max-width: 315px;
  }
  .react-pagination-js-bootstrap .is-active {
    color: #fff;
    border: 1px solid rgba(255, 116, 24, 1);
    background-color: rgba(255, 116, 24, 1);
    margin: 1px !important;
    font-family: "MuseoSans-500", Helvetica, sans-serif !important;
    font-size: 16px !important;
    width: auto;
    text-transform: none;
    max-width: 315px;
  }
  .react-pagination-js-bootstrap .page:hover {
    background-color: rgba(176, 177, 179, 0.5) !important;
    color: rgba(255, 255, 255, 1) !important;
  }
  .react-pagination-js-bootstrap .pageElli {
    padding: 0 12px !important;
    border: none !important;
    color: #4c4c4e;
  }

  @media only screen and (max-width: 600px) {
    .text-on-left-size {
      font-size: 22px !important;
    }
  }
  .locale-tel-search {
    padding: 10px !important;
  }
  .locale-tel-search-box {
    width: 100% !important;
    margin-left: 0px !important;
    font-family: "Museo-Sans-300", Helvetica, sans-serif !important;
  }
  .locale-tel-container {
    font-family: "Museo-Sans-300", Helvetica, sans-serif !important;
    margin-bottom: 10px !important;
    margin-top: 8px !important;
  }
  .locale-tel-dropdown {
    width: 305px !important;
  }
  .locale-tel-input {
    width: 100% !important;
    border-radius: 0px !important;
    color: #4c4c4e !important;
    font-family: "MuseoSans-300", Helvetica, sans-serif !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    border: 0 !important;
    margin: 0 !important;
    animation-name: MuiInputBase-keyframes-auto-fill-cancel !important;
    -webkit-tap-highlight-color: transparent !important;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    padding: 19.5px 0px 6.5px 40px !important;
  }
  .locale-tel-input:focus {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    -webkit-box-shadow: 0px 1px rgba(0, 0, 0, 0.42) !important;
    box-shadow: 0px 1px rgba(0, 0, 0, 0.42) !important;
  }
  .locale-tel-container > .special-label {
    color: #b0b1b3 !important;
    font-size: 0.8rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 400 !important;
    line-height: 1 !important;
    padding: 0px !important;
    left: 0 !important;
    letter-spacing: 0.00938em !important;
    margin-bottom: 10px;
  }
  .locale-tel-container > .flag-dropdown > .selected-flag {
    padding: 0px !important;
    top: 3px !important;
  }
  .MuiChip-clickable {
    cursor: pointer;
    border-radius: 10px !important;
    -webkit-tap-highlight-color: transparent;
    background: transparent !important;
    border: #b0b1b3 2px solid !important;
    font-size: 12px !important;
    height: 25px !important;
    font-family: "Museo-Sans-700", Helvetica, sans-serif;
  }
  .MuiChip-deleteIcon {
    color: #868788 !important;
    width: 15px !important;
  }
  .wg-default {
    bottom: 30px !important;
  }
`;
