import React from 'react';

export interface HeaderContextInterface {
  isHeaderHidden: boolean;
}

const { Provider, Consumer } = React.createContext<HeaderContextInterface>({
  isHeaderHidden: false
});

export const HeaderContextProvider = Provider;
export const HeaderContextConsumer = Consumer;
