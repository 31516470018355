/* Primary colors */
export const brandPrimaryColors = {
  lightOrange: "#FFD5B9",
  lightblue: "rgba(152, 182, 205, 1)",
  orange: "rgba(255,116,24,1)",
  urbanblue: "rgba(51, 67, 100, 1)",
  white: "rgba(255, 255, 255, 1)",
};

/* Secondary colors */
export const brandSecondaryColors = {
  black: "rgba(0,0,0,1)",
  grey1: "#F0F0F0",
  grey2: "#B0B1B3",
  grey3: "#868788",
  grey4: "#4C4C4E",
  red: "#e65757",
  green: "#00CC09",
};

/** grey colors with more options */
export const greyColorOptions = {
  grey: {
    100: "hsl(0, 0%, 100%)",
    150: "hsl(0, 0%, 95%)",
    200: "hsl(0, 0%, 90%)",
    250: "hsl(0, 0%, 85%)",
    300: "hsl(0, 0%, 80%)",
    350: "hsl(0, 0%, 75%)",
    400: "hsl(0, 0%, 70%)",
    450: "hsl(0, 0%, 65%)",
    500: "hsl(0, 0%, 60%)",
    550: "hsl(0, 0%, 55%)",
    600: "hsl(0, 0%, 50%)",
    650: "hsl(0, 0%, 45%)",
    700: "hsl(0, 0%, 40%)",
    750: "hsl(0, 0%, 35%)",
    800: "hsl(0, 0%, 30%)",
    850: "hsl(0, 0%, 25%)",
    900: "hsl(0, 0%, 20%)",
    950: "hsl(0, 0%, 15%)",
    1000: "hsl(0, 0%, 10%)",
  },
};

/* Fonts */
export const brandFonts = {
  $ms300: "'Museo-Sans-300', Helvetica, sans-serif",
  $ms500: "'Museo-Sans-500', Helvetica, sans-serif",
  $ms700: "'Museo-Sans-700', Helvetica, sans-serif",
  $ms900: "'Museo-Sans-900', Helvetica, sans-serif",
};
