// third party
import 'react-dropzone-uploader/dist/styles.css';

import styledNormalize from 'styled-normalize';

import { createGlobalStyle } from '../utils/styledComponents';
import globals from './globals';

const GlobalStyle = createGlobalStyle`
${styledNormalize}
${globals}
`;

export default GlobalStyle;
