import { AnyAction } from 'redux';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import callApi from '../../utils/callApi';
import { errorHandler } from '../../utils/errorHandler';
import { enqueueSnackbar } from '../notifications';
import { fetchTickets, replyToTicket, selectTicket } from './routines';

function* handleFetchTickets(action: AnyAction) {
  try {
    const res = yield call(callApi, "get", `/tickets`, {
      params: {
        ticketType: "maintenance"
      }
    });

    yield put(fetchTickets.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(fetchTickets.failure(errorHandler(err.response)));
    } else {
      yield put(fetchTickets.failure("An uknown error occured"));
    }
  } finally {
    yield put(fetchTickets.fulfill());
  }
}

function* handleSelectTicket(action: AnyAction) {
  try {
    yield put(selectTicket.request());
    //  Check if tenant exist in store
    if (action.payload && action.payload.ticketId) {
      const res = yield call(
        callApi,
        "get",
        `/tickets/${action.payload.ticketId}`
      );

      yield put(selectTicket.success(res.data));
    } else {
      // Clear the selected Tenant
      yield put(selectTicket.failure());
    }
  } catch (error) {
    console.log(error);
    yield put(selectTicket.failure());
    yield put(
      enqueueSnackbar({
        message: "Ticket not found",
        options: {
          variant: "error"
        }
      })
    );
  } finally {
    yield put(selectTicket.fulfill());
  }
}

function* handleReplyToTicket(action: AnyAction) {
  console.log(action);
  try {
    yield put(replyToTicket.request());

    const res = yield call(
      callApi,
      "post",
      `/tickets/${action.payload.ticketId}/message`,
      {
        data: action.payload.data
      }
    );

    if (res.error) {
      yield put(fetchTickets.failure(res.error));
      yield put(
        enqueueSnackbar({
          message: "An error occured while sending the message",
          options: {
            variant: "error"
          }
        })
      );
    } else {
      yield put(replyToTicket.success(res.data));
    }
  } catch (error) {
    console.log(error);

    yield put(replyToTicket.failure());
  } finally {
    yield put(replyToTicket.fulfill());
  }
}

function* fetchTicketsWatcher() {
  yield takeEvery(fetchTickets.TRIGGER, handleFetchTickets);
}

function* selectTicketWatcher() {
  yield takeEvery(selectTicket.TRIGGER, handleSelectTicket);
}

function* replyToTicketWatcher() {
  yield takeEvery(replyToTicket.TRIGGER, handleReplyToTicket);
}

export function* ticketsSaga() {
  yield all([
    fork(fetchTicketsWatcher),
    fork(selectTicketWatcher),
    fork(replyToTicketWatcher)
  ]);
}
