import * as _ from 'lodash';
import { isString } from 'lodash';

const API_URL = process.env.REACT_APP_API_URL;

export enum imageSizes {
  AVATAR = "50x50",
  HERO = "1920x1080",
  LARGE = "1024x1024",
  MEDIUM = "300x300",
  THUMBNAIL = "150x150"
}

export const imageUrl = (
  url: string | { publicUrl: string },
  size: imageSizes
) => {
  if (url) {
    const urlObj =
      typeof url === "string" ? new URL(url) : new URL(url.publicUrl);
    const urlPathArr = _.split(urlObj.pathname, "/");

    urlPathArr.splice(urlPathArr.length - 1, 0, size);

    const resizedImageUrl = `${urlObj.origin}${_.join(urlPathArr, "/")}${
      urlObj.search ? urlObj.search : ""
    }`;

    return resizedImageUrl;
  }

  return "";
};

export const getImages = (
  imageObjs: Array<{ publicUrl: string }>
): string[] => {
  return imageObjs.map((img: { publicUrl: string }) =>
    imageUrl(img.publicUrl, imageSizes.LARGE)
  );
};

export const getHeroImage = (
  imageObjs: Array<{ publicUrl: string }>
): string => {
  return imageObjs.length > 0
    ? imageUrl(imageObjs[0].publicUrl, imageSizes.LARGE)
    : "";
};

export const getProtocolCompatibleImageUrl = (url: string) => {
  if (!url || !isString(url)) return url;

  const urlObj = new URL(url);
  if (urlObj.protocol === "https:") return url;

  const resource = urlObj.searchParams.get("resource") || url;
  return `${API_URL}/ad-templates/get-image?imageUrl=${resource}`;
};

export const getUrlViaProxy = (url: string) => {
  if (!url || !isString(url)) return url;

  const urlObj = new URL(url);
  const resource = urlObj.searchParams.get("resource") || url;
  return `${API_URL}/ad-templates/get-image?imageUrl=${resource}`;
};
