import MuiSvgIcon from "@material-ui/core/SvgIcon";
import React from "react";
import { IconProps } from ".";

const CheckIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon viewBox="0 0 25 26" {...props} >
    <svg width={25} height={26} {...props}>
      <path
        d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"
        transform="translate(1 1)"
        fillRule="nonzero"
        stroke={props.stroke || "rgba(255,116,24,1)"}
        strokeWidth={1.5}
        fill={props.fill || "rgba(255,116,24,1)"}
      />
    </svg>
  </MuiSvgIcon>
);

export default CheckIcon;
