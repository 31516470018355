import * as React from "react";

import MuiAvatar, { AvatarProps } from "@material-ui/core/Avatar";

import logo from "../../assets/images/brand/circleLogo.png";
import styled from "../../utils/styledComponents";
import SideBarButton from "../buttons/SideBarButton";
import {
  CommunicationIcon,
  NavContentIcon,
  NavSettingsIcon,
  ProfileIcon,
  CampaignIcon,
  AnalyticsIcon,
  SubscriptionPlansIcon,
  BillingIcon,
  AngleLeftIcon,
} from "../icons";
import PartnersIcon from "../icons/PartnersIcon";
import { theme } from "../../styles/theme";

const StyledSideBar = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-content: center;
  background-color: ${({ theme }) => theme.colors.grey1};
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.colors.grey[300]};
`;

const Wrapper = styled.div`
  top: 0;
  position: fixed;
`;

const Logo = styled(MuiAvatar as React.FunctionComponent<AvatarProps>)`
  && {
    width: 20px;
    height: 20px;
  }
`;

const LogoWrapper = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuItemsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

const ExpandAndCollapseButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[300]};
  padding: 5px 0;
`;

const IconWrapper = styled.div<{ isExpanded: boolean }>`
  margin-right: 5px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  transform: ${({ isExpanded }) =>
    isExpanded ? "rotate(0)" : "rotate(180deg)"};
`;

interface OwnProps {
  activeRoute: string;
  whiteLabelEnabled: boolean;
  referralPartnerEnabled: boolean;
  billingAndWalletEnabled: boolean;
  hasAdminRights: boolean | undefined;
  isExpanded?: boolean;
  toggleMenuExpanded?: () => void;
}

const SideBar: React.FunctionComponent<OwnProps> = ({
  activeRoute,
  whiteLabelEnabled,
  referralPartnerEnabled,
  billingAndWalletEnabled,
  hasAdminRights,
  isExpanded,
  toggleMenuExpanded,
}) => {
  return (
    <StyledSideBar>
      <Wrapper>
        <LogoWrapper>{!whiteLabelEnabled && <Logo src={logo} />}</LogoWrapper>
        <MenuItemsWrapper>
          <SideBarButton
            iconComponent={CampaignIcon}
            linkTo="/campaigns"
            currentRoute={activeRoute}
            label="Campaigns"
            disableTooltip={isExpanded}
          />

          <SideBarButton
            iconComponent={AnalyticsIcon}
            linkTo="/dashboard"
            currentRoute={activeRoute}
            label="Analytics"
            disableTooltip={isExpanded}
          />

          {billingAndWalletEnabled && (
            <SideBarButton
              iconComponent={BillingIcon}
              linkTo="/billing-and-wallet"
              currentRoute={activeRoute}
              label="Billing & Wallet"
              disableTooltip={isExpanded}
            />
          )}

          <SideBarButton
            iconComponent={NavContentIcon}
            linkTo="/content"
            currentRoute={activeRoute}
            label="Content"
            disableTooltip={isExpanded}
          />
          <SideBarButton
            iconComponent={CommunicationIcon}
            linkTo="/chats"
            currentRoute={activeRoute}
            label="Enquiries"
            disableTooltip={isExpanded}
          />
          {referralPartnerEnabled && (
            <SideBarButton
              iconComponent={PartnersIcon}
              linkTo="/partners"
              currentRoute={activeRoute}
              label="Partner Referrals"
              disableTooltip={isExpanded}
            />
          )}

          {hasAdminRights && (
            <SideBarButton
              iconComponent={SubscriptionPlansIcon}
              linkTo="/packages"
              currentRoute={activeRoute}
              label="Packages"
              disableTooltip={isExpanded}
            />
          )}

          {hasAdminRights && (
            <SideBarButton
              iconComponent={NavSettingsIcon}
              linkTo="/settings"
              currentRoute={activeRoute}
              label="Settings"
              disableTooltip={isExpanded}
            />
          )}

          <SideBarButton
            iconComponent={ProfileIcon}
            linkTo="/account"
            currentRoute={activeRoute}
            label="Profile"
            disableTooltip={isExpanded}
          />
        </MenuItemsWrapper>
      </Wrapper>
      <ExpandAndCollapseButtonWrapper>
        <IconWrapper
          isExpanded={isExpanded ? isExpanded : false}
          onClick={() => {
            if (toggleMenuExpanded) {
              toggleMenuExpanded();
            }
          }}
        >
          <AngleLeftIcon nativeColor={theme.colors.grey4} />
        </IconWrapper>
      </ExpandAndCollapseButtonWrapper>
    </StyledSideBar>
  );
};

export default SideBar;
