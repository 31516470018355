import React from "react";

import { ButtonProps } from "@material-ui/core/Button";
import MuiCircularProgress, {
  CircularProgressProps
} from "@material-ui/core/CircularProgress";

import PlayStoreBadge from "../../assets/images/thirdParty/google-play-badge.png";
import styled from "../../utils/styledComponents";
import { redirectToStore } from "../banners/DownloadBanner";
import Button from "./Button";

interface OwnProps {
  loading?: boolean;
}

type AllProps = ButtonProps & OwnProps;
const StyledButton = styled(Button)`
  && {
    background: url(${PlayStoreBadge});
    background-repeat: no-repeat;
    background-size: 164px 49px;
    width: 164px;
    height: 49px;
    border: none !important;
    border-radius: 10px;
    &:hover {
      background: url(${PlayStoreBadge}) !important;
      background-repeat: no-repeat !important;
      background-size: 164px 49px !important;
      border: none !important;
    }
  }
`;

const StyledProgress = styled(
  MuiCircularProgress as React.FunctionComponent<CircularProgressProps>
)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  color: ${({ theme }) => theme.colors.white};
`;

const Wrapper = styled.div`
  position: relative;
  display: inline;
`;

const GooglePlayStoreButton: React.FunctionComponent<AllProps> = ({
  loading,
  ...props
}) => {
  const handleClick = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    redirectToStore("android");
  };

  return (
    <Wrapper>
      <StyledButton
        onClick={handleClick}
        variant="contained"
        defaultValue="google"
        outline={true}
        {...props}
      >
        {" "}
      </StyledButton>
      {loading && <StyledProgress size={24} color="inherit" />}
    </Wrapper>
  );
};

export default GooglePlayStoreButton;
