import { AnyAction } from "redux";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import callApi from "../../utils/callApi";
import { errorHandler } from "../../utils/errorHandler";
import { fetchBehaviours } from "./routines";

function* handleFetchBehaviours(action: AnyAction) {
  try {
    const res = yield call(callApi, "get", "/behaviours", {
      params: { filter: { active: true } }
    });
    yield put(fetchBehaviours.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(fetchBehaviours.failure(errorHandler(err.response)));
    } else {
      yield put(fetchBehaviours.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchBehaviours.fulfill());
  }
}

function* fetchBehavioursWatcher() {
  yield takeEvery(fetchBehaviours.TRIGGER, handleFetchBehaviours);
}

export function* behaviourSaga() {
  yield all([fork(fetchBehavioursWatcher)]);
}
