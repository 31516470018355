import { Location } from "history";
import { Cancelable, cloneDeep, debounce, get, isArray, isEmpty } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { ActionMeta, OptionTypeBase } from "react-select/src/types";
import { bindActionCreators, Dispatch } from "redux";
import { bindPromiseCreators } from "redux-saga-routines";

import {
  Drawer,
  Link as MuiLink,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { DrawerProps } from "@material-ui/core/Drawer";
import Grid, { GridProps } from "@material-ui/core/Grid";
import { ListItemProps } from "@material-ui/core/ListItem";
import { Option } from "react-select/src/filters";

import Avatar from "../components/avatars/UserAvatar";
import DownloadDialog from "../components/dialogs/DownloadDialog";
// import { calculateTierPricing } from '../components/forms/SubscriptionPlanForm';
import {
  AnalyticsIcon,
  CampaignIcon,
  CommunicationIcon,
  HelpIcon,
  LogoutIcon,
  NavContentIcon,
  NavSettingsIcon,
  ProfileIcon,
  PartnersIcon,
  SubscriptionPlansIcon,
  BillingIcon,
} from "../components/icons";
import AsyncAutoCompleteInput, {
  AsyncSelectInstance,
  OptionType,
} from "../components/inputs/AsyncAutoCompleteInput";
import AsyncServerSearchInput, {
  OptiontypeProps,
} from "../components/inputs/AsyncServerSearchInput";
import NavBar from "../components/navigation/NavBar";
import SideBar from "../components/navigation/SideBar";
import { ApplicationState } from "../store";
import {
  Account,
  fetchAccountSubscriptions,
  SubscriptionTier,
} from "../store/account";
import { logEvent } from "../store/analytics";
import { AuthenticatedUser, logout, setPrivilege } from "../store/auth";
import {
  fetchInvitedMembersPromise,
  fetchMemberRoles,
  fetchOrganisationByIdPromise,
  fetchOrganisationByNamePromise,
  fetchSubOrganisationByIdPromise,
  Organisation,
  patchOrganisationStatePromise,
  selectOrganisation,
  selectSubOrganisation,
} from "../store/organisation";
import { Tenant } from "../store/tenants";
import { getPrivileges, isOwnerOrAdmin } from "../utils/general";
import styled, { mediaDown, mediaUp } from "../utils/styledComponents";
import { isVerificationRequired } from "../utils/verification";
import { History } from "history";
import { Modes } from "../store/navigation";
import { brandSecondaryColors } from "../styles/defaults";
import HelpDialog from "../components/dialogs/HelpDialog";

const Container = styled(Grid)`
  && {
    height: 100%;
  }
`;

const StyledImage = styled.img`
  height: 50px;
  margin: 0 10px 5px 20px;
  border-radius: 5px;
  max-width: 100%;
  position: relative;
  vertical-align: middle;
`;

// eslint-disable-next-line
const SideBarGrid = styled(Grid as React.FunctionComponent<GridProps>)<{
  isExpanded: boolean;
}>`
  width: ${({ theme, isExpanded }) =>
    isExpanded ? theme.widths.sidebarExpanded : theme.widths.sidebar};
  ${mediaDown.sm`display: none;`}
  transition: width 0.2s ease-in-out;
`;

const NavBarGrid = styled(Grid as React.FunctionComponent<GridProps>)`
  height: ${({ theme }) => theme.heights.navbar};
  z-index: 999;
`;

const PageGrid = styled(Grid as React.FunctionComponent<GridProps>)`
  height: ${({ theme }) => `calc(100% - ${theme.heights.navbar})`};
`;

const ContentAreaContainer = styled(Grid as React.FunctionComponent<GridProps>)`
  position: relative;
  height: 100%;
`;

const MobileMenu = styled(Drawer as React.FunctionComponent<DrawerProps>)`
  ${mediaUp.md`display: none;`}
  ul {
    padding-top: 68px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Logout = styled.div`
  flex-shrink: 0;
  margin-left: 15px;
  min-width: 50px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledAsyncSearchInput = styled(AsyncServerSearchInput)`
  && {
    width: 400px;
    ${mediaDown.sm`width: 300px;
      margin:5px 5px;
    `}
    ${mediaDown.xxs`width: 200px;
    `}
  }
`;

const MenuListItem = styled(ListItem as React.FunctionComponent<ListItemProps>)`
  font-size: 24px;
`;

const StyledLink = styled(MuiLink)`
  && {
    .MuiTypography-root {
      color: ${({ theme }) => theme.colors.grey4};
      font-family: ${({ theme }) => theme.fonts.MS500};
      font-weight: ${({ theme }) => theme.fontWeights.MS500};
    }
  }
`;

interface OwnProps {
  showTitle: boolean;
  history: History;
}

interface PropsFromState {
  authenticated: boolean;
  location: Location;
  title?: string;
  tenants: Tenant[];
  user?: AuthenticatedUser;
  account?: Account;
  subscriptionTiers?: SubscriptionTier[];
  isTenant: boolean;
  verified: boolean;
  organisation: Organisation;
  memberOrganisations: Organisation[];
  isOrgOwnerOrAdmin: boolean;
  subOrganisation?: Organisation;
  mode: Modes;
  organisationSearchResults: Pick<Organisation, "_id" | "name">[];
}
interface PropsFromDispatch {
  logout: typeof logout;
  logEvent: typeof logEvent;
  fetchAccountSubscriptions: typeof fetchAccountSubscriptions;
  selectOrganisation: typeof selectOrganisation;
  setPrivilege: typeof setPrivilege;
  fetchOrganisationByNamePromise: typeof fetchOrganisationByNamePromise;
  fetchSubOrganisationByIdPromise: typeof fetchSubOrganisationByIdPromise;
  fetchInvitedMembersPromise: typeof fetchInvitedMembersPromise;
  fetchMemberRoles: typeof fetchMemberRoles;
  selectSubOrganisation: typeof selectSubOrganisation;
  patchOrganisationStatePromise: typeof patchOrganisationStatePromise;
  fetchOrganisationByIdPromise: typeof fetchOrganisationByIdPromise;
}

interface State {
  mobileMenuOpen: boolean;
  title: string;
  dialogOpen: boolean;
  defaultOption: OptiontypeProps | undefined;
  isSideMenuExpanded: boolean;
  helpDialogOpen: boolean;
}

type AllProps = PropsFromState & PropsFromDispatch & OwnProps;

class NavigationContainer extends React.Component<AllProps, State> {
  public static defaultProps = {
    showTitle: true,
  };

  public state: State = {
    dialogOpen: false,
    mobileMenuOpen: false,
    title: "",
    defaultOption: undefined,
    isSideMenuExpanded: true,
    helpDialogOpen: false,
  };

  private selectOfficeRef = React.createRef<AsyncSelectInstance>();

  public componentDidMount(): void {
    if (this.props.subOrganisation || this.props.organisation)
      this.setupDefaultOption();
  }

  public componentDidUpdate(prevProps: AllProps) {
    const { organisation, subOrganisation } = this.props;

    const prevOrgId = get(prevProps, "organisation._id");
    const prevSubOrgId = get(prevProps, "subOrganisation._id");
    if (
      (subOrganisation && prevSubOrgId !== subOrganisation._id) ||
      (organisation && prevOrgId !== organisation._id)
    ) {
      this.setupDefaultOption();

      if (this.selectOfficeRef.current) {
        const { select } = this.selectOfficeRef.current;

        const currentSelectedOption = select.state && select.state.value;
        if (
          !currentSelectedOption ||
          currentSelectedOption.value !==
            get(subOrganisation, "_id", get(organisation, "_id"))
        ) {
          select.setState({
            value: {
              value: get(
                this.props,
                "subOrganisation._id",
                get(this.props, "organisation._id")
              ),
              label: get(
                this.props,
                "subOrganisation.name",
                get(this.props, "organisation.name")
              ),
            },
          });
        }
      }
    }

    if (
      prevProps.authenticated !== this.props.authenticated &&
      prevProps.authenticated !== true
    ) {
      this.props.fetchAccountSubscriptions();

      //Get Organisation Roles
      this.props.fetchMemberRoles({});
    }
  }

  public setupDefaultOption() {
    const defaultOption = {
      label: get(
        this.props,
        "subOrganisation.name",
        get(this.props, "organisation.name")
      ),
      value: get(
        this.props,
        "subOrganisation._id",
        get(this.props, "organisation._id")
      ),
    };
    this.setState({ defaultOption });
  }

  public filterOrgs = (
    inputValue: string,
    orgs: { label: string; value: string }[]
  ) => {
    return orgs.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  public onSelectSubOrg = async (option: any) => {
    if (option && option.value) {
      let selectedOrg;
      if (isArray(this.props.memberOrganisations)) {
        selectedOrg = this.props.memberOrganisations.find(
          (org) => org._id === option.value
        );
        if (selectedOrg)
          this.props.patchOrganisationStatePromise({
            organisation: selectedOrg,
            subOrganisation: undefined,
          });
      }

      if (!selectedOrg) {
        //get sub-organisation
        try {
          selectedOrg = await this.props.fetchSubOrganisationByIdPromise({
            subOrganisation: option.value,
          });
        } catch (err) {
          console.error(err);
        }
      } else {
        //get members
        this.props.fetchInvitedMembersPromise({
          organisationId: selectedOrg._id,
        });
      }

      if (selectedOrg) {
        const location = cloneDeep(this.props.location);
        Object.assign(location, { search: `organisation=${selectedOrg._id}` });
        this.props.history.replace(location);

        const privileges = getPrivileges(this.props.user!, selectedOrg);
        this.props.setPrivilege({ privileges });
      }
    } else {
      this.props.selectSubOrganisation({ subOrganisation: undefined });
    }
  };

  public getSubOrganisations = (organisation: Organisation): OptionType[] => {
    const mainOrg = this.props.organisation;
    if (organisation) {
      const { subOrganisations } = organisation;

      const organisations = [];
      if (isArray(this.props.memberOrganisations)) {
        organisations.push(...this.props.memberOrganisations);
        for (const org of this.props.memberOrganisations) {
          if (
            isOwnerOrAdmin(this.props.user, org) &&
            isArray(org.subOrganisations)
          ) {
            organisations.push(...org.subOrganisations);
          }
        }
      } else if (isArray(subOrganisations))
        organisations.push(...subOrganisations);

      return organisations
        .filter((org: { name: string; _id: string }) => org._id && org.name)
        .map((org: { name: string; _id: string }) => ({
          label: org.name,
          value: org._id,
        }));
    }
    return [
      { label: get(mainOrg, "name", ""), value: get(mainOrg, "_id", "") },
    ];
  };

  public onSelect = (
    value: OptionTypeBase | null,
    action: ActionMeta<OptionTypeBase>
  ) => {
    if (action.action === "clear") {
      const privileges = { isAllowed: false, isAdmin: false };
      this.props.setPrivilege({ privileges });
      this.setState({ defaultOption: undefined });
      this.props.selectOrganisation({ selectedOrg: undefined });

      return;
    }

    const { organisationSearchResults, fetchOrganisationByIdPromise } =
      this.props;
    const orgId: string = get(value, "value", "");

    fetchOrganisationByIdPromise({ organisation: orgId }).then(
      (organisation: Organisation) => {
        this.props.selectOrganisation({ selectedOrg: organisation });
        const privileges = getPrivileges(this.props.user!, organisation);
        this.props.setPrivilege({ privileges });
      }
    );

    const selectedOrg = organisationSearchResults.filter(
      (org: Pick<Organisation, "_id" | "name">) => org._id === orgId
    );

    if (selectedOrg.length === 1) {
      this.setState({
        defaultOption: {
          label: selectedOrg[0].name,
          value: selectedOrg[0]._id,
        },
      });
    }
  };

  private async searchOrgByName(searchText: string): Promise<Option[]> {
    let organisationSearchResults: Pick<Organisation, "_id" | "name">[] = [];

    try {
      organisationSearchResults =
        await this.props.fetchOrganisationByNamePromise({
          keyword: searchText,
        });
    } catch (err) {
      console.error(err);
    }

    return organisationSearchResults.map(
      (org: Pick<Organisation, "_id" | "name">) => ({
        value: org._id,
        label: org.name,
        data: null,
      })
    );
  }

  private debouncedSearchOrgByName: ((
    searchText: string
  ) => Promise<Option[]>) &
    Cancelable = debounce(this.searchOrgByName, 500, {
    leading: true,
    trailing: true,
  });

  private handleLoadOptionsAsync(searchText: string): Promise<Option[]> {
    if (searchText.length < 4) return Promise.resolve([]);

    return this.debouncedSearchOrgByName(searchText);
  }

  private handleFilterOption(option: Option, searchText: string): boolean {
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  }

  private upgradeSubscriptionEvent = () => {
    return this.props.logEvent({
      event: "landlord_clicked_upgrade_subscription",
    });
  };

  private RightMenu = () => {
    const { user, verified } = this.props;
    const requireVerification = isVerificationRequired();
    const organisationLogoUrl = get(this.props, "organisation.logoUrl", "");

    return user ? (
      <MenuWrapper>
        {!requireVerification ||
          (verified && (
            <Link to="/account/profile">
              <Avatar
                displayName={user.displayName}
                profileImage={
                  user.profileImage && user.profileImage.publicUrl
                    ? user.profileImage.publicUrl
                    : ""
                }
              />
            </Link>
          ))}
        {!isEmpty(organisationLogoUrl) && (
          <StyledImage src={organisationLogoUrl} alt="company-logo" />
        )}
        <Logout onClick={this.handleLogout}>Log out</Logout>
      </MenuWrapper>
    ) : null;
  };

  public render() {
    const {
      children,
      authenticated,
      account,
      location,
      showTitle,
      // tenants,
      title,
      user,
      // subscriptionTiers,
      isTenant,
      verified,
      organisation,
      subOrganisation,
      mode,
    } = this.props;
    const isSuperUser = user && user.isSuperUser;
    const isHeadOffice = get(organisation, "headOffice", false);
    const isMultipleOrganisations = this.props.memberOrganisations.length > 1;
    const subOrganisations = this.getSubOrganisations(organisation);
    const requireVerification = isVerificationRequired();
    const defaultValue = {
      value: get(subOrganisation || organisation, "_id", ""),
      label: get(subOrganisation || organisation, "name", ""),
    };

    const isLinkOutMode = mode.toLowerCase() === Modes.LinkOut.toLowerCase();

    if (user) {
      // const userTags = user.tags.filter(
      //   (tag: { id: string; name: string }) =>
      //     tag.name === "tenant" ||
      //     tag.name === "landlord" ||
      //     tag.name === "agent"
      // );
      // const { totalPrice } = calculateTierPricing(
      //   tenants.length + 200,
      //   subscriptionTiers
      // );
      // const YearlyPrice =
      //   (account &&
      //     account.billingDetails &&
      //     account.billingDetails.paymentInterval) === "Yearly"
      //     ? (totalPrice || 0) * 10
      //     : (totalPrice || 0) * 12;
      // IntercomUser = {
      //   ...accountInfo,
      //   UserType: userTags[0] ? userTags[0].name : "not assigned",
      //   email: user.email,
      //   name: user.displayName,
      //   phone: user.contactNumber,
      //   user_id: user.id,
      // };
    }

    const logoUrl = get(organisation, "logoUrl", "");

    const whiteLabelEnabled =
      get(organisation, "settings.colors.whiteLabelPlatform.active") || false;

    const referralPartnerEnabled =
      get(organisation, "settings.partners.showReferralPartners") || false;

    const billingAndWalletEnabled = get(
      organisation,
      "settings.paymentMethods.wallet",
      false
    ); //||
    //get(organisation, "settings.paymentMethods.account", false);

    const showOrganisationSelector =
      ((isHeadOffice && this.props.isOrgOwnerOrAdmin) ||
        isMultipleOrganisations) &&
      !isSuperUser;

    const hasAdminRights = this.props.isOrgOwnerOrAdmin || isSuperUser;

    return (
      <>
        {/* {user ? (
          <Intercom appID={INTERCOM_APP_ID} {...IntercomUser} />
        ) : (
          <Intercom appID={INTERCOM_APP_ID} />
        )} */}

        <Container container>
          {authenticated &&
            !isTenant &&
            (!requireVerification || verified) &&
            !isLinkOutMode && (
              <SideBarGrid
                isExpanded={this.state.isSideMenuExpanded}
                item={true}
              >
                <SideBar
                  activeRoute={location.pathname}
                  whiteLabelEnabled={whiteLabelEnabled}
                  referralPartnerEnabled={referralPartnerEnabled}
                  billingAndWalletEnabled={billingAndWalletEnabled}
                  hasAdminRights={hasAdminRights}
                  isExpanded={this.state.isSideMenuExpanded}
                  toggleMenuExpanded={() => {
                    this.setState({
                      isSideMenuExpanded: !this.state.isSideMenuExpanded,
                    });
                  }}
                />
              </SideBarGrid>
            )}
          <Grid item={true} xs={true}>
            <ContentAreaContainer container={true} direction="column">
              <DownloadDialog
                open={this.state.dialogOpen}
                title="Get the Flow app"
                description="Download the flow app from your app store by following the links below."
                onClose={this.handleDialogClose}
                rounded="yes"
              />
              <HelpDialog
                openDialog={this.state.helpDialogOpen}
                onClose={() => {
                  this.setState({ helpDialogOpen: false });
                }}
              />
              {!isLinkOutMode && (
                <NavBarGrid item={true}>
                  <NavBar
                    title={title ? title : ""}
                    logoUrl={logoUrl}
                    showTitle={showTitle}
                    rightMenuComponent={this.RightMenu}
                    mobileToggle={this.handleDrawerToggle}
                    authenticated={authenticated}
                    account={account}
                    user={user}
                    upgradeSubscriptionEvent={this.upgradeSubscriptionEvent}
                    showDialog={this.handleShowDialog}
                  >
                    {showOrganisationSelector && (
                      <AsyncAutoCompleteInput
                        ref={this.selectOfficeRef}
                        options={subOrganisations}
                        isMulti={false}
                        defaultValue={defaultValue}
                        onSelect={this.onSelectSubOrg}
                        reset={false}
                        placeholder="Select office"
                        disableUnderline
                        containerStyles={{ width: 250 }}
                      />
                    )}

                    {isSuperUser && (
                      <StyledAsyncSearchInput
                        onLoadPromiseOptions={this.handleLoadOptionsAsync.bind(
                          this
                        )}
                        onSelect={this.onSelect}
                        defaultOption={this.state.defaultOption}
                        placeholder="Select organisation..."
                        isMulti={false}
                        defaultOptions={[]}
                        filterOption={this.handleFilterOption}
                      />
                    )}

                    <HelpIcon
                      style={{ fontSize: 24, cursor: "pointer" }}
                      fontSize="large"
                      nativeColor={brandSecondaryColors.grey4}
                      onClick={() => {
                        this.setState({ helpDialogOpen: true });
                      }}
                    />
                  </NavBar>
                </NavBarGrid>
              )}
              <PageGrid item={true}>{children}</PageGrid>
            </ContentAreaContainer>
          </Grid>
        </Container>
        {!isLinkOutMode && (
          <MobileMenu
            variant="temporary"
            anchor="right"
            open={this.state.mobileMenuOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {this.state.mobileMenuOpen && (
              <>
                {showOrganisationSelector && (
                  <AsyncAutoCompleteInput
                    ref={this.selectOfficeRef}
                    options={subOrganisations}
                    isMulti={false}
                    defaultValue={defaultValue}
                    onSelect={this.onSelectSubOrg}
                    reset={false}
                    placeholder="Select office"
                    disableUnderline
                    containerStyles={{
                      width: 250,
                      marginTop: 10,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  />
                )}
                {isSuperUser && (
                  <StyledAsyncSearchInput
                    onLoadPromiseOptions={this.handleLoadOptionsAsync.bind(
                      this
                    )}
                    onSelect={this.onSelect}
                    defaultOption={this.state.defaultOption}
                    placeholder="Select organisation..."
                    isMulti={false}
                    defaultOptions={[]}
                    filterOption={this.handleFilterOption}
                  />
                )}
              </>
            )}

            <List>
              <NavLink to="/campaigns">
                <MenuListItem button={true} onClick={this.handleDrawerToggle}>
                  <ListItemIcon>
                    <CampaignIcon nativeColor={brandSecondaryColors.grey4} />
                  </ListItemIcon>
                  <ListItemText>Campaigns</ListItemText>
                </MenuListItem>
              </NavLink>
              <NavLink to="/dashboard">
                <MenuListItem button={true} onClick={this.handleDrawerToggle}>
                  <ListItemIcon>
                    <AnalyticsIcon nativeColor={brandSecondaryColors.grey4} />
                  </ListItemIcon>
                  <ListItemText>Analytics</ListItemText>
                </MenuListItem>
              </NavLink>
              <NavLink to="/billing-and-wallet">
                <MenuListItem button={true} onClick={this.handleDrawerToggle}>
                  <ListItemIcon>
                    <BillingIcon nativeColor={brandSecondaryColors.grey4} />
                  </ListItemIcon>
                  <ListItemText>Billing and Wallet</ListItemText>
                </MenuListItem>
              </NavLink>
              <NavLink to="/content">
                <MenuListItem button={true} onClick={this.handleDrawerToggle}>
                  <ListItemIcon>
                    <NavContentIcon nativeColor={brandSecondaryColors.grey4} />
                  </ListItemIcon>
                  <ListItemText>Content</ListItemText>
                </MenuListItem>
              </NavLink>
              {!isTenant && (!requireVerification || verified) && (
                <>
                  <NavLink to="/chats">
                    <MenuListItem
                      button={true}
                      onClick={this.handleDrawerToggle}
                    >
                      <ListItemIcon>
                        <CommunicationIcon
                          nativeColor={brandSecondaryColors.grey4}
                        />
                      </ListItemIcon>
                      <ListItemText>Enquiries</ListItemText>
                    </MenuListItem>
                  </NavLink>
                  {/* <NavLink to="/tenants">
                  {/* <NavLink to="/tenants">
                    <MenuListItem button={true} onClick={this.handleDrawerToggle}>
                      <ListItemIcon>
                        <TenantsIcon nativeColor={brandSecondaryColors.grey4} />
                      </ListItemIcon>
                      <ListItemText>Tenants</ListItemText>
                    </MenuListItem>
                  </NavLink> */}
                </>
              )}
              {referralPartnerEnabled && (
                <NavLink to="/partners">
                  <MenuListItem button={true} onClick={this.handleDrawerToggle}>
                    <ListItemIcon>
                      <PartnersIcon nativeColor={brandSecondaryColors.grey4} />
                    </ListItemIcon>
                    <ListItemText>Partners</ListItemText>
                  </MenuListItem>
                </NavLink>
              )}
              {hasAdminRights && (
                <NavLink to="/packages">
                  <MenuListItem button={true} onClick={this.handleDrawerToggle}>
                    <ListItemIcon>
                      <SubscriptionPlansIcon
                        nativeColor={brandSecondaryColors.grey4}
                      />
                    </ListItemIcon>
                    <ListItemText>Campaign Plans</ListItemText>
                  </MenuListItem>
                </NavLink>
              )}
              <NavLink to="/settings">
                <MenuListItem button={true} onClick={this.handleDrawerToggle}>
                  <ListItemIcon>
                    <NavSettingsIcon nativeColor={brandSecondaryColors.grey4} />
                  </ListItemIcon>
                  <ListItemText>Settings</ListItemText>
                </MenuListItem>
              </NavLink>
              <NavLink to="/account">
                <MenuListItem button={true} onClick={this.handleDrawerToggle}>
                  <ListItemIcon>
                    <ProfileIcon nativeColor={brandSecondaryColors.grey4} />
                  </ListItemIcon>
                  <ListItemText>Profile</ListItemText>
                </MenuListItem>
              </NavLink>
              <StyledLink target="_blank" href="https://help.flowliving.com">
                <MenuListItem button={true}>
                  <ListItemIcon>
                    <HelpIcon
                      style={{ fontSize: 24 }}
                      fontSize="large"
                      nativeColor={brandSecondaryColors.grey4}
                    />
                  </ListItemIcon>
                  <ListItemText>Help</ListItemText>
                </MenuListItem>
              </StyledLink>
              <MenuListItem button={true} onClick={this.handleLogout}>
                <ListItemIcon>
                  <LogoutIcon
                    nativeColor={brandSecondaryColors.grey4}
                    fontSize="large"
                  />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuListItem>
            </List>
          </MobileMenu>
        )}
      </>
    );
  }
  private handleDialogClose = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    this.setState({ dialogOpen: false });
  };

  private handleShowDialog = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    this.setState({ dialogOpen: true });
  };

  private handleLogout = () => {
    if (this.state.mobileMenuOpen) {
      this.handleDrawerToggle();
    }
    this.props.logout();
  };

  private handleDrawerToggle = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    });
  };
}

const mapStateToProps = ({
  account,
  router,
  auth,
  navigation,
  tenants,
  organisation,
}: ApplicationState): PropsFromState => ({
  account: account.account,
  authenticated: auth.authenticated,
  isTenant:
    !!auth.user &&
    !!auth.user.tags.find((tag: any) => {
      return tag.name === "tenant";
    }),
  location: router.location,
  subscriptionTiers: account.subscriptionTiers,
  tenants: tenants.tenants,
  title: navigation.routeProps.title,
  user: auth.user,
  verified: !!auth.user && auth.user.status === "Verified",
  organisation: organisation.organisation!,
  memberOrganisations: organisation.memberOrganisations,
  isOrgOwnerOrAdmin: isOwnerOrAdmin(auth.user, organisation.organisation!),
  subOrganisation: organisation.subOrganisation,
  mode: navigation.mode,
  organisationSearchResults: organisation.organisationSearchResults,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(
    {
      fetchAccountSubscriptions,
      selectOrganisation,
      logout,
      logEvent,
      fetchMemberRoles,
      selectSubOrganisation,
      setPrivilege,
    },
    dispatch
  ),
  ...bindPromiseCreators(
    {
      fetchOrganisationByNamePromise,
      fetchSubOrganisationByIdPromise,
      fetchInvitedMembersPromise,
      patchOrganisationStatePromise,
      fetchOrganisationByIdPromise,
    },
    dispatch
  ),
});

export default connect<
  PropsFromState,
  PropsFromDispatch,
  OwnProps,
  ApplicationState
>(
  mapStateToProps,
  mapDispatchToProps
)(NavigationContainer);
