import { AnyAction } from 'redux';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import callApi from '../../utils/callApi';
import { errorHandler } from '../../utils/errorHandler';
import { connectLandlord, fetchReferralInfo } from './routines';

function* handleFetchReferralInfo(action: AnyAction) {
  try {
    const res = yield call(
      callApi,
      "get",
      `/referral?code=${action.payload.referralCode}`
    );

    yield put(fetchReferralInfo.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(fetchReferralInfo.failure(errorHandler(err.response)));
    } else {
      yield put(fetchReferralInfo.failure("An uknown error occured"));
    }
  } finally {
    yield put(fetchReferralInfo.fulfill());
  }
}

function* handleConnectLandlord(action: AnyAction) {
  try {
    yield put(connectLandlord.request())

    const res = yield call(
      callApi,
      "put",
      `/leaseAgreement/connectLandlord/${action.payload}`
    );

    yield put(connectLandlord.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(connectLandlord.failure(errorHandler(err.response)));
    } else {
      yield put(connectLandlord.failure("An uknown error occured"));
    }
  } finally {
    yield put(connectLandlord.fulfill());
  }
}

function* fetchReferralInfoWatcher() {
  yield takeEvery(fetchReferralInfo.TRIGGER, handleFetchReferralInfo);
}

function* connectLandlordWatcher() {
  yield takeEvery(connectLandlord.TRIGGER, handleConnectLandlord);
}

export function* referralSaga() {
  yield all([fork(fetchReferralInfoWatcher),fork(connectLandlordWatcher)]);
}
