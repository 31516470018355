import MuiTypography, { TypographyProps } from '@material-ui/core/Typography';

import styled from '../../utils/styledComponents';

type AllProps = TypographyProps;

const NavBarHeading = styled(MuiTypography as React.FunctionComponent<
  AllProps
>).attrs({
  variant: "h5"
})`
  && {
    flex-grow: 0.1;
    text-transform: capitalize;
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    font-size: ${({ theme }) => theme.fontSizes.navbarHeading};
    font-family: ${({ theme }) => theme.fonts.MS300};
  }
`;

export default NavBarHeading;
