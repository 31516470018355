import { omit } from "lodash";
import { transparentize } from "polished";
import React from "react";

import MuiFab, { FabProps } from "@material-ui/core/Fab";

import styled from "../../utils/styledComponents";

interface OwnProps {
  primary?: boolean;
  secondary?: boolean;
  small?: boolean;
}

type AllProps = OwnProps & FabProps;

const Fab = styled((props: AllProps) => (
  <MuiFab {...omit(props, ["primary", "secondary", "small"])} />
))`
  && {
    font-size: 16px;

    color: ${({ primary, secondary, theme }) => {
      let color = theme.colors.grey4;

      if (primary || secondary) {
        color = theme.colors.white;
      }

      return color;
    }};

    background: ${({ primary, secondary, theme }) => {
      let color = theme.colors.white;

      if (primary) {
        color = theme.colors.orange;
      } else if (secondary) {
        color = theme.colors.lightblue;
      }

      return color;
    }};

    border: none;

    /* &:hover {
      background: ${({ theme, primary, secondary }) => {
      let color = "rgba(255,255,255,0)";

      if (primary) {
        color = theme.colors.orange;
      } else if (secondary) {
        color = theme.colors.lightblue;
      }

      return transparentize("0.25", color);
    }};
    } */

    ${({ small }) =>
      small
        ? `
    height: 24px;
    min-height: 24px;
    width: 24px;
    font-size: 12px;
    `
        : ""}
  }
`;

export default Fab;
