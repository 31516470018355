import { Location } from 'history';

export interface NavigationState {
  readonly routeProps: {
    title?: string;
    secure: boolean;
  };
  mode: Modes;
}

export enum Modes {
  Default = 'default',
  LinkOut = 'linkOut'
}

export interface RouterState {
  background?: Location;
}
