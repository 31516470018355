import { createRoutine, promisifyRoutine } from "redux-saga-routines";
import { LinkOutLoginPayload, UserSocial } from "./types";

export const AUTH_LOGIN_EVENT = "@@auth/LOGIN";
export const AUTH_REGISTER_EVENT = "@@auth/REGISTER";

export const authenticate = createRoutine("@@auth/AUTHENTICATE");
export const authenticatePromise = promisifyRoutine(authenticate);
export const forgotPassword = createRoutine("@@auth/FORGOT_PASSWORD");
export const forgotPasswordPromise = promisifyRoutine(forgotPassword);
export const login = createRoutine(AUTH_LOGIN_EVENT);
export const loginPromise = promisifyRoutine(login);
export const loginWithGoogle = createRoutine("@@auth/LOGIN_WITH_GOOGLE");
export const loginWithGooglePromise = promisifyRoutine(loginWithGoogle);
export const loginWithFacebook = createRoutine("@@auth/LOGIN_WITH_FACEBOOK");
export const loginWithFacebookPromise = promisifyRoutine(loginWithFacebook);
export const register = createRoutine(AUTH_REGISTER_EVENT);
export const registerPromise = promisifyRoutine(register);
export const logout = createRoutine("@@auth/LOGOUT");
export const resetPassword = createRoutine("@@auth/RESET_PASSWORD");
export const resetPasswordPromise = promisifyRoutine(resetPassword);
export const validateToken = createRoutine("@@auth/VALIDATE_TOKEN");
export const updateUser = createRoutine("@@auth/UPDATE_USER");
export const updateUserPromise = promisifyRoutine(updateUser);
export const uploadProfileImage = createRoutine("@@auth/UPLOAD_PROFILE_IMAGE");
export const uploadProfileImagePromise = promisifyRoutine(uploadProfileImage);
export const requestOTP = createRoutine("@@auth/REQUEST_OTP");
export const requestOTPPromise = promisifyRoutine(requestOTP);
export const verifyOTP = createRoutine("@@auth/VERIFY_OTP");
export const verifyOTPPromise = promisifyRoutine(verifyOTP);

export const setPrivilege = createRoutine("@@auth/SETPRIVILEGE");
export const verifyUserByEmail = createRoutine("@@auth/VERIFY_USER_BY_EMAIL");
export const verifyUserByEmailPromise = promisifyRoutine(verifyUserByEmail);

export const patchUserFbSocial = createRoutine("@@auth/PATCH_USER_FB_SOCIAL");
export const patchUserFbSocialPromise: (
  payload: Partial<UserSocial>
) => Promise<any> = promisifyRoutine(patchUserFbSocial);

export const linkOutLogin = createRoutine("@@auth/LINK_OUT_LOGIN");
export const linkOutLoginPromise: (
  payload: LinkOutLoginPayload
) => Promise<any> = promisifyRoutine(linkOutLogin);

export const signInWithProvider = createRoutine(
  "@@auth/SIGN _IN_WITH_PROVIDER"
);
export const signInWithProviderPromise = promisifyRoutine(signInWithProvider);
export const checkLoginProvider = createRoutine("@@auth/CHECK_LOGIN_PROVIDER");
export const checkLoginProviderPromise = promisifyRoutine(checkLoginProvider);
