import { get, isEmpty, omit } from 'lodash';
import queryString from 'query-string';
import { AnyAction } from 'redux';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { ApplicationState } from '../';
import callApi from '../../utils/callApi';
import { errorHandler } from '../../utils/errorHandler';
import { enqueueSnackbar } from '../notifications';
import {
  createChat, getChats, getProfile, inviteTenant, setChatUnreadCount, updateChat
} from './routines';

function* handleCreateChat(action: AnyAction) {
  try {
    const res = yield call(callApi, "POST", "/chats", {
      data: action.payload,
    });
    yield put(createChat.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler(err.response),
          options: { variant: "error" },
        })
      );
      yield put(createChat.failure(errorHandler(err.response)));
    } else {
      yield put(createChat.failure("An unknown error occured"));
    }
  } finally {
    yield put(createChat.fulfill());
  }
}

function* handleGetChats(action: AnyAction) {
  try {
    const { payload } = action;
    const { isExport } = payload;
    const { subOrganisation, organisation } = yield select((state: ApplicationState) => state.organisation);
    const query = queryString.stringify(omit(payload, "organisation"));
    const subId = get(subOrganisation, '_id', '');
    const orgId = get(organisation, '_id', '');

    if (!orgId) return;

    // TODO: this logic needs some clean up
    const url = !isEmpty(subId) && subId !== orgId ? `/chats?${query}&organisation=${orgId}&subOrganisation=${subId}` : `/chats?${query}&organisation=${orgId}`;
    const res = yield call(callApi, "GET", url);
    const returnPayLoad = {
      ...res.data,
      ...{ isExport: isExport ? true : false },
    };
    yield put(getChats.success(returnPayLoad));
  } catch (err) {
    if (err.response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler(err.response),
          options: { variant: "error" },
        })
      );
      yield put(getChats.failure(errorHandler(err.response)));
    } else {
      yield put(getChats.failure("An unknown error occured"));
    }
  } finally {
    yield put(getChats.fulfill());
  }
}

function* handleUpdateChat(action: AnyAction) {
  try {
    const res = yield call(callApi, "PUT", `/chats/${action.payload.id}`, {
      data: action.payload,
    });

    yield put(updateChat.success(res.data));
    yield put(
      enqueueSnackbar({
        message: "Chat status was updated succesfully",
        options: { variant: "success" },
      })
    );
  } catch (err) {
    console.log("error: ", err);
    if (err.response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler(err.response),
          options: { variant: "error" },
        })
      );
      yield put(updateChat.failure(errorHandler(err.response)));
    } else {
      yield put(updateChat.failure("An unknown error occured"));
    }
  } finally {
    yield put(updateChat.fulfill());
  }
}

function* handleGetProfileById(action: AnyAction) {
  try {
    const res = yield call(callApi, "get", `/chats/${action.payload.chatId}`);

    yield put(getProfile.success(res.data));
  } catch (err) {
    console.log(errorHandler(err));
    if (err.response.status === 400) {
      yield put(getProfile.failure(err));
    } else if (err.response) {
      yield put(getProfile.failure(errorHandler(err)));
    } else {
      yield put(getProfile.failure("An unknown error occured."));
    }
  } finally {
    yield put(getProfile.fulfill());
  }
}

function* handleSetUreadChatCount(action: AnyAction) {
  try {
    yield put(setChatUnreadCount.success(action.payload));
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: errorHandler(err.message),
        options: { variant: "error" },
      })
    );
  } finally {
    yield put(setChatUnreadCount.fulfill());
  }
}

function* handleInvitation(action: AnyAction) {
  try {
    const res = yield call(
      callApi,
      "post",
      `/anonymous-user/send-invite/${action.payload.chatId}`
    );
    yield put(inviteTenant.success(res.data));
    enqueueSnackbar({
      message: "Your invitaiotn has been successfully sent",
      options: { variant: "success" },
    });
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: errorHandler(err.message),
        options: { variant: "error" },
      })
    );
  } finally {
    yield put(inviteTenant.fulfill());
  }
}

function* handleInviteWatcher() {
  yield takeEvery(inviteTenant.TRIGGER, handleInvitation);
}

function* createChatWatcher() {
  yield takeEvery(createChat.TRIGGER, handleCreateChat);
}

function* handleGetChatsWatcher() {
  yield takeEvery(getChats.TRIGGER, handleGetChats);
}

function* handleUpdateChatWatcher() {
  yield takeEvery(updateChat.TRIGGER, handleUpdateChat);
}

function* setChatUnreadCountWatcher() {
  yield takeEvery(setChatUnreadCount.TRIGGER, handleSetUreadChatCount);
}

function* getProfileById() {
  yield takeEvery(getProfile.TRIGGER, handleGetProfileById);
}

export function* chatSaga() {
  yield all([
    fork(createChatWatcher),
    fork(handleGetChatsWatcher),
    fork(handleUpdateChatWatcher),
    fork(setChatUnreadCountWatcher),
    fork(getProfileById),
    fork(handleInviteWatcher),
  ]);
}
