import { Reducer } from 'redux';

import { fetchReferralInfo } from './routines';
import { ReferralState } from './types';

const initialState: ReferralState = {
  errors: undefined,
  loading: false,
  user: undefined
};

const reducer: Reducer<ReferralState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger
    case fetchReferralInfo.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }
    // Success
    case fetchReferralInfo.SUCCESS: {
      return { ...state, user: action.payload };
    }
    // Failure
    case fetchReferralInfo.FAILURE: {
      return { ...state, errors: action.payload };
    }
    // Fulfill
    case fetchReferralInfo.FULFILL: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
};

export { reducer as referralReducer };
