import React from 'react';

import MuiSvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '../icons';

const GardenCottegeIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 40 40" fontSize="inherit">
    <svg width={40} height={40} {...props}>
      <g fill={nativeColor} fillRule="nonzero">
        <path d="M24.619 9.315a.665.665 0 1 0 0-1.33h-1.331a.665.665 0 1 0 0 1.33h1.33zM14.638 16.634v3.993c0 .367.298.665.666.665h9.315a.665.665 0 0 0 .665-.665v-3.993a5.33 5.33 0 0 0-5.323-5.322 5.33 5.33 0 0 0-5.323 5.322zm4.658 3.327h-3.327V17.3h3.327v2.661zm0-3.992h-3.267a3.998 3.998 0 0 1 3.267-3.267v3.267zm1.33-3.267a3.998 3.998 0 0 1 3.267 3.267h-3.266v-3.267zm0 4.598h3.327v2.661h-3.326V17.3zM39.037 38.592H1.547c-.493 0-.893.298-.893.665 0 .367.4.665.892.665h37.491c.494 0 .893-.298.893-.665 0-.367-.399-.665-.893-.665zM5.665 6.992h.666v6.5h1.33V6.327a.666.666 0 0 0-.665-.665h-.665V4.33h3.992v1.33h-.665a.666.666 0 0 0-.666.666v4.504h1.33V6.992h.666a.666.666 0 0 0 .666-.665V3.665A.666.666 0 0 0 10.988 3H5.665A.666.666 0 0 0 5 3.665v2.662c0 .367.298.665.665.665zM7.32 37.26h1.33a.666.666 0 0 0 0-1.33H7.32a.666.666 0 0 0 0 1.33zM13.973 11.977h-1.33a.666.666 0 0 0 0 1.33h1.33a.666.666 0 0 0 0-1.33zM27.28 33.269h3.992a.665.665 0 1 0 0-1.331H27.28a.665.665 0 1 0 0 1.33zM32.603 29.276a.665.665 0 1 0 0-1.33h-1.33a.665.665 0 1 0 0 1.33h1.33zM13.308 32.603a.666.666 0 0 0-.666-.665h-1.33a.666.666 0 0 0 0 1.33h1.33a.666.666 0 0 0 .666-.665zM7.985 16.634c0 .368.298.666.665.666h3.992a.666.666 0 0 0 0-1.33H8.65a.666.666 0 0 0-.665.664z" />
        <path d="M39.257 20.627a.665.665 0 0 0 .47-1.136l-3.991-3.992h-.002l-3.325-3.326L20.432.195a.665.665 0 0 0-.941 0L11.506 8.18.196 19.49a.665.665 0 1 0 .94.94l2.856-2.856v21.512h1.331v-5.818H8.65a.666.666 0 0 0 0-1.331H5.323v-6.654h.665a.666.666 0 0 0 0-1.33h-.665v-2.662h5.988a.666.666 0 0 0 0-1.33H5.323v-3.717l6.93-6.93h4.381a.666.666 0 0 0 0-1.33h-3.051l6.378-6.378 10.37 10.37h-1.72a.665.665 0 1 0 0 1.33h3.051l2.662 2.662h-3.051a.665.665 0 1 0 0 1.33h3.326v2.662h-.665a.665.665 0 1 0 0 1.33h.665v2.662h-3.326a.665.665 0 1 0 0 1.331h3.326v6.654h-.665a.665.665 0 1 0 0 1.33h.665v2.662h-4.657a.665.665 0 1 0 0 1.33h4.657v1.58h1.331V17.575l2.857 2.857c.13.13.3.195.47.195z" />
        <path d="M9.98 29.276h4.658v9.607c.887.245 1.33.245 1.33 0V25.284h7.985v5.323h-.665a.665.665 0 1 0 0 1.33h.665v6.946c0 .367 1.331.367 1.331 0v-9.607h.665a.665.665 0 1 0 0-1.33h-.665v-3.327a.665.665 0 0 0-.665-.665h-9.315a.666.666 0 0 0-.666.665v3.327H9.981a.666.666 0 0 0 0 1.33zM9.315 24.619c0 .367.298.665.666.665h2.661a.666.666 0 0 0 0-1.33H9.981a.666.666 0 0 0-.666.665zM27.28 25.284h1.331a.665.665 0 1 0 0-1.33h-1.33a.665.665 0 1 0 0 1.33zM27.946 20.627c0 .367.297.665.665.665h2.661a.665.665 0 1 0 0-1.33h-2.661a.665.665 0 0 0-.665.665zM28.61 15.969h-1.33a.665.665 0 1 0 0 1.33h1.33a.665.665 0 1 0 0-1.33z" />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default GardenCottegeIcon;
