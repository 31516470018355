import { AnyAction } from 'redux';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import callApi from '../../utils/callApi';
import { errorHandler } from '../../utils/errorHandler';
import {
    fetchClaimedRewards, fetchLeaseBreakdown, fetchTenantLeaderboard, fetchTenantLevels,
    fetchTransactionsBreakdown
} from './routines';

function* handlefetchTenantLevels(action: AnyAction) {
  try {
    yield put(fetchTenantLevels.request());
    const res = yield call(callApi, "get", `/reports/tenantLevels`);
    yield put(fetchTenantLevels.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(fetchTenantLevels.failure(errorHandler(err.response)));
    } else {
      yield put(fetchTenantLevels.failure("An uknown error occured"));
    }
  } finally {
    yield put(fetchTenantLevels.fulfill());
  }
}

function* handleFetchLeaseBreakdown(action: AnyAction) {
  try {
    yield put(fetchLeaseBreakdown.request());
    const res = yield call(callApi, "get", `/reports/tenantLeases`);
    yield put(fetchLeaseBreakdown.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(fetchLeaseBreakdown.failure(errorHandler(err.response)));
    } else {
      yield put(fetchLeaseBreakdown.failure("An uknown error occured"));
    }
  } finally {
    yield put(fetchLeaseBreakdown.fulfill());
  }
}

function* handleFetchClaimedRewards(action: AnyAction) {
  try {
    yield put(fetchClaimedRewards.request());
    const res = yield call(callApi, "get", `/reports/claimedRewards`);
    yield put(fetchClaimedRewards.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(fetchClaimedRewards.failure(errorHandler(err.response)));
    } else {
      yield put(fetchClaimedRewards.failure("An uknown error occured"));
    }
  } finally {
    yield put(fetchClaimedRewards.fulfill());
  }
}

function* handleFetchTenantLeaderboard(action: AnyAction) {
  try {
    yield put(fetchTenantLeaderboard.request());
    const res = yield call(callApi, "get", `/reports/tenantLeaderboard`);
    yield put(fetchTenantLeaderboard.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(fetchTenantLeaderboard.failure(errorHandler(err.response)));
    } else {
      yield put(fetchTenantLeaderboard.failure("An uknown error occured"));
    }
  } finally {
    yield put(fetchTenantLeaderboard.fulfill());
  }
}

function* handleFetchTransactionsBreakdown(action: AnyAction) {
  try {
    yield put(fetchTransactionsBreakdown.request());
    const res = yield call(callApi, "get", `/reports/transactions`);
    yield put(fetchTransactionsBreakdown.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(fetchTransactionsBreakdown.failure(errorHandler(err.response)));
    } else {
      yield put(fetchTransactionsBreakdown.failure("An uknown error occured"));
    }
  } finally {
    yield put(fetchTransactionsBreakdown.fulfill());
  }
}

function* fetchTenantLevelsWatcher() {
  yield takeEvery(fetchTenantLevels.TRIGGER, handlefetchTenantLevels);
}

function* fetchLeaseBreakdownWatcher() {
  yield takeEvery(fetchLeaseBreakdown.TRIGGER, handleFetchLeaseBreakdown);
}

function* fetchClaimedRewardsWatcher() {
  yield takeEvery(fetchLeaseBreakdown.TRIGGER, handleFetchClaimedRewards);
}

function* fetchTenantLeaderboardWatcher() {
  yield takeEvery(fetchTenantLeaderboard.TRIGGER, handleFetchTenantLeaderboard);
}

function* fetchTransactionsBreakdownWatcher() {
  yield takeEvery(
    fetchTransactionsBreakdown.TRIGGER,
    handleFetchTransactionsBreakdown
  );
}

export function* reportSaga() {
  yield all([
    fork(fetchTenantLevelsWatcher),
    fork(fetchLeaseBreakdownWatcher),
    fork(fetchClaimedRewardsWatcher),
    fork(fetchTenantLeaderboardWatcher),
    fork(fetchTransactionsBreakdownWatcher)
  ]);
}
