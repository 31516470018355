import { transparentize } from "polished";
import React from "react";

import { ButtonProps } from "@material-ui/core/Button";
import MuiCircularProgress, {
  CircularProgressProps
} from "@material-ui/core/CircularProgress";

import styled from "../../utils/styledComponents";
import { EmailIcon } from "../icons";
import Button from "./Button";

interface OwnProps {
  loading?: boolean;
}

type AllProps = ButtonProps & OwnProps;

const Icon = styled(EmailIcon)`
  font-size: 50px;
  margin-right: 10px;
  margin-top: 0px;
`;

const StyledButton = styled(Button)`
  && {
    color: #000 !important;
    border-color: ${({ theme }) => theme.colors.grey3} !important;
    &:hover {
      background: ${transparentize("0.25", "#fff")}!important;
      border-color: ${({ theme }) => theme.colors.grey3} !important;
      color: ${({ theme }) => theme.colors.black} !important;
    }
  }
`;

const StyledProgress = styled(MuiCircularProgress as React.FunctionComponent<
  CircularProgressProps
>)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  color: ${({ theme }) => theme.colors.white};
`;

const Wrapper = styled.div`
  position: relative;
  display: inline;
`;

const EmailButton: React.FunctionComponent<AllProps> = ({
  loading,
  ...props
}) => {
  return (
    <Wrapper>
      <StyledButton variant="contained" outline={true} {...props}>
        <Icon nativeColor="#fff" />
        {props.children}
      </StyledButton>
      {loading && <StyledProgress size={24} color="inherit" />}
    </Wrapper>
  );
};

export default EmailButton;
