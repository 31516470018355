import * as React from 'react';

import styled from '../../utils/styledComponents';

interface RootProps {
  className?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.pageBg};
  height: 100%;
`;

const Root: React.FunctionComponent<RootProps> = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);

export default Root;
