import { AnyAction } from 'redux';
import { all, fork, put, takeEvery } from 'redux-saga/effects';

import { enqueueSnackbar, removeSnackbar } from './routines';

function* handleEnqueueSnackbar(action: AnyAction) {
  yield put(
    enqueueSnackbar.success({
      key: new Date().getTime() + Math.random(),
      ...action.payload
    })
  );
}

function* handleRemoveSnackbar(action: AnyAction) {
  yield put(removeSnackbar.success(action.payload));

}

function* enqueueSnackbarWatcher() {
  yield takeEvery(enqueueSnackbar.TRIGGER, handleEnqueueSnackbar);
}

function* removeSnackbarWatcher() {
  yield takeEvery(removeSnackbar.TRIGGER, handleRemoveSnackbar);
}

export function* notificationSaga() {
  yield all([fork(enqueueSnackbarWatcher), fork(removeSnackbarWatcher)]);
}
