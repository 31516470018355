import React from "react";
import { ColouredIconProps } from ".";
import { theme } from "../../styles/theme";

const CloseCircleIcon: React.FunctionComponent<ColouredIconProps> = ({
  nativeColor = theme.colors.grey2,
}) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 24  24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={nativeColor}
      fill-rule="evenodd"
      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z"
      clip-rule="evenodd"
    />
  </svg>
);

export default CloseCircleIcon;
