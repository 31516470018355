import { omit } from "lodash";
import React from "react";
import MuiAvatar, { AvatarProps } from "@material-ui/core/Avatar";
import styled from "../../utils/styledComponents";
import { useTheme } from "@material-ui/core";

interface OwnProps {
  profileImage?: string;
  displayName: string;
  inFlow?: boolean;
  small?: boolean;
  large?: boolean;
}

interface StyledAvatarInterface {
  inFlow?: boolean;
  small?: boolean;
  large?: boolean;
}

type AllProps = OwnProps;

function getInitials(displayName: string): string {
  if (displayName) {
    const strings = displayName.split(" ");
    return `${strings[0][0]}${strings[1]![0]}`;
  }
  return "";
}

const Wrapper = styled.div<{ inFlow?: boolean; primaryColor: string }>`
  padding: 2px;
  border-radius: 50%;
  border: ${({ inFlow, primaryColor }) =>
    inFlow ? `3px solid ${primaryColor}` : "none"};
`;

const StyledAvatar = styled((props: AvatarProps & StyledAvatarInterface) => (
  <MuiAvatar {...omit(props, ["inFlow", "small", "large"])} />
))`
  && {
    ${({ small }) =>
      small
        ? `
height: 32px;
width: 32px;
font-size: 0.8rem;
`
        : ""}

    ${({ large }) =>
      large
        ? `
height: 70px;
width: 70px;
font-size: 1.8rem;
`
        : ""}
  }
`;

const Avatar: React.FunctionComponent<AllProps> = ({
  profileImage,
  displayName,
  inFlow,
  ...props
}) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  if (profileImage) {
    return (
      <Wrapper primaryColor={primaryColor} inFlow={inFlow}>
        <StyledAvatar src={profileImage} {...props} />
      </Wrapper>
    );
  }

  return (
    <Wrapper primaryColor={primaryColor} inFlow={inFlow}>
      <StyledAvatar {...props}>{getInitials(displayName)}</StyledAvatar>
    </Wrapper>
  );
};

export default Avatar;
