import MuiSvgIcon from "@material-ui/core/SvgIcon";
import React from "react";
import { IconProps } from "../icons";

const FilledTickIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon viewBox="0 0 16 16" {...props}>
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <title>{"Group 7 Copy 2"}</title>
      <g transform="translate(-586 -360)" fill="none" fillRule="evenodd">
        <rect fill="#FFF" x={331} y={104} width={837} height={538} rx={4} />
        <rect
          fill="#F7F7F7"
          transform="rotate(-180 536 391)"
          x={481}
          y={371}
          width={110}
          height={40}
          rx={10}
        />
        <rect
          stroke="#FF7D00"
          strokeWidth={2}
          transform="rotate(-180 536 391)"
          x={476}
          y={366}
          width={120}
          height={50}
          rx={14}
        />
        <g transform="translate(586 360)">
          <circle fill="#FF7D00" cx={8} cy={8} r={8} />
          <path
            d="M6.396 11.313l-3.25-3.25a.5.5 0 010-.708l.708-.707a.5.5 0 01.707 0l2.189 2.19 4.69-4.69a.5.5 0 01.706 0l.708.707a.5.5 0 010 .708l-5.75 5.75a.5.5 0 01-.708 0z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  </MuiSvgIcon>
);

export default FilledTickIcon;
