import React from "react";

import MuiSvgIcon from "@material-ui/core/SvgIcon";

import { IconProps } from "../icons";

const CatalogueIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 40 41">
    <svg
      width="40px"
      height="41px"
      viewBox="0 0 40 41"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{"catalogue"}</title>
      <g
        id="CMS-Settings-V2"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="catalogue" transform="translate(0.000000, 0.940976)">
          <path
            d="M14.4767857,28.9858537 L10.3303571,28.9858537 L10.3303571,15.6396886 L14.4767857,15.6396886 L14.4767857,28.9858537 Z M12.4013393,13.8191485 C11.0754464,13.8191485 10,12.7214699 10,11.3962239 C10,10.0706478 11.0751162,8.996056 12.4013393,8.996056 C13.7275623,8.996056 14.8026786,10.0706478 14.8026786,11.3962239 C14.8026786,12.7214699 13.7267857,13.8191485 12.4013393,13.8191485 Z M29.9955357,28.9858537 L25.8580357,28.9858537 L25.8580357,22.4890244 C25.8580357,20.9406729 25.8267857,18.9550348 23.7022321,18.9550348 C21.5464286,18.9550348 21.2160714,20.6372496 21.2160714,22.3774717 L21.2160714,28.9858537 L17.0741071,28.9858537 L17.0741071,15.6396886 L21.0508929,15.6396886 L21.0508929,17.4602287 L21.1089286,17.4602287 C21.6625,16.4116333 23.0147321,15.3050305 25.0321429,15.3050305 C29.2285714,15.3050305 30,18.0670753 30,21.6546102 L30,28.9858537 L29.9955357,28.9858537 Z"
            id="Shape-Copy-3"
            fill="#FFFFFF"
            fill-rule="nonzero"
          ></path>
          <rect
            id="Rectangle-Copy-21"
            fill="#F0F0F0"
            x="0"
            y="0"
            width="40"
            height="39.9804878"
            rx="19.9902439"
          ></rect>
          <g
            id="book-open-copy"
            transform="translate(10.000000, 13.059024)"
            fill="#868788"
            fill-rule="nonzero"
          >
            <path
              d="M18.8274099,0.0019394956 C16.9245991,0.113010381 13.1425891,0.51729412 10.8078263,1.98728694 C10.6467124,2.08871501 10.5553914,2.26907127 10.5553914,2.45764178 L10.5553914,15.4529354 C10.5553914,15.8654334 10.9939407,16.1261464 11.3637388,15.9347188 C13.7658638,14.6911534 17.2398823,14.3518694 18.9576205,14.2590127 C19.544089,14.2272271 19.9999998,13.7436581 19.9999998,13.164018 L19.9999998,1.09836271 C20.0003469,0.4658658 19.4670043,-0.03520318 18.8274099,0.0019394956 Z M9.19217332,1.98728694 C6.85775783,0.51729412 3.07574779,0.113367523 1.17293701,0.0019394956 C0.533342584,-0.03520318 0,0.4658658 0,1.09836271 L0,13.1643752 C0,13.7443723 0.455910685,14.2279414 1.04237919,14.2593698 C2.76081177,14.3522265 6.2365665,14.6918677 8.6386915,15.9361473 C9.00744789,16.1272178 9.44460825,15.866862 9.44460825,15.4554354 L9.44460825,2.45121324 C9.44460825,2.26228559 9.35363445,2.08907215 9.19217332,1.98728694 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </MuiSvgIcon>
);

export default CatalogueIcon;
