import React from 'react';

import MuiSvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '../icons';

const ProfileIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 46 43">
    <svg width={46} height={43} {...props}>
      <path
        d="M1.917 41.333a18.58 18.58 0 0 1 1.833-7.438c1.293-2.585 6.692-4.376 13.47-6.886 1.833-.68 1.532-5.465.72-6.358-1.907-2.095-3.52-4.554-3.52-10.484C14.42 4.18 18.325 1 23 1c4.675 0 8.58 3.18 8.58 9.167 0 5.93-1.615 8.389-3.52 10.484-.812.893-1.113 5.684.72 6.359 6.784 2.51 12.177 4.3 13.47 6.885a18.58 18.58 0 0 1 1.833 7.438H1.917z"
        stroke={nativeColor}
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </MuiSvgIcon>
);

export default ProfileIcon;
