import { get, pick } from 'lodash';
import { AnyAction } from 'redux';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { ApplicationState } from '../';
import callApi from '../../utils/callApi';
import { errorHandler } from '../../utils/errorHandler';
import { enqueueSnackbar } from '../notifications';
import { addUnit, deleteUnit, getUnitsByPropertyId, updateUnit } from './routines';
import { Unit } from './types';

// Get current state
function* getCurrentOrg() {
  const { organisation } = yield select((state: ApplicationState) => state);
  return get(organisation, "organisation._id", undefined);
}

// Get current role
function* getCurrentUser() {
  const { auth } = yield select((state: ApplicationState) => state);
  const roles = get(auth, "user.roles", []);
  const role = roles.includes("flow-admin") ? "admin/" : "";

  const landlordUser = get(auth, "user._id", undefined);
  return { role, landlordUser };
}

function* handleAddUnit(action: AnyAction) {
  try {
    const { payload } = action;
    const unit: Unit = action.payload;
    yield put(addUnit.request());
    const { role } = yield call(getCurrentUser);
    const organisationId = yield call(getCurrentOrg);

    const data = {
      bathrooms: unit.bathrooms,
      bedrooms: unit.bedrooms,
      description: unit.description,
      parkings: unit.parkings,
      property: unit.property,
      rentalAmount: unit.rentalAmount,
      displayPrice: unit.displayPrice,
      size: unit.size,
      status: unit.status,
      title: unit.title,
    }

    if (payload.listingType === 'auction') {
      Object.assign(data, {
        ...pick(payload, [
          'auctionVenueAddress',
          'auctionVenueName',
          'auctionStartDate',
          'auctionEndDate',
          'auctionStartTime',
          'auctionEndTime'])
      })
    }
    const res = yield call(
      callApi,
      "post",
      `/v3/${role}units?organisation=${organisationId}`,
      { data }
    );

    yield put(addUnit.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(addUnit.failure(errorHandler(err.response)));
    } else {
      yield put(addUnit.failure("An unknown error occured."));
    }
  } finally {
    yield put(addUnit.fulfill());
  }
}

function* handleUpdateUnit(action: AnyAction) {
  try {
    const { payload } = action;
    const unit: Unit = payload;
    const organisationId = yield call(getCurrentOrg);
    const { role } = yield call(getCurrentUser);
    yield put(updateUnit.request());

    const data = {
      _id: unit._id,
      bathrooms: unit.bathrooms,
      bedrooms: unit.bedrooms,
      description: unit.description,
      parkings: unit.parkings,
      property: unit.property,
      rentalAmount: unit.rentalAmount,
      displayPrice: unit.displayPrice,
      size: unit.size,
      status: unit.status,
      title: unit.title,
    };

    if (payload.listingType === 'auction') {
      Object.assign(data, {
        ...pick(payload, [
          'auctionVenueAddress',
          'auctionVenueName',
          'auctionStartDate',
          'auctionEndDate',
          'auctionStartTime',
          'auctionEndTime'])
      })
    }

    const res = yield call(
      callApi,
      "put",
      `/v3/${role}units/${unit._id}?organisation=${organisationId}`,
      { data }
    );

    yield put(updateUnit.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(updateUnit.failure(errorHandler(err.response)));
    } else {
      yield put(updateUnit.failure("An unknown error occured."));
    }
  } finally {
    yield put(updateUnit.fulfill());
  }
}

function* handleDeleteUnit(action: AnyAction) {
  try {
    const { unitId } = action.payload;
    yield put(deleteUnit.request());
    const { role } = yield call(getCurrentUser);
    const organisationId = yield call(getCurrentOrg);

    const res = yield call(
      callApi,
      "delete",
      `/v3/${role}units/${unitId}?organisation=${organisationId}`
    );

    yield put(deleteUnit.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(deleteUnit.failure(errorHandler(err.response)));
    } else {
      yield put(deleteUnit.failure("An unknown error occured."));
    }
  } finally {
    yield put(deleteUnit.fulfill());
  }
}

function* handleGetUnitsByPropertyId(action: AnyAction) {
  try {
    const { propertyId } = action.payload;
    yield put(getUnitsByPropertyId.request());
    const { role } = yield call(getCurrentUser);
    const organisationId = yield call(getCurrentOrg);

    const res = yield call(
      callApi,
      "get",
      `/v3/${role}units/${propertyId}/propertyId?organisation=${organisationId}`
    );

    yield put(getUnitsByPropertyId.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(getUnitsByPropertyId.failure(errorHandler(err.response)));
    } else {
      yield put(getUnitsByPropertyId.failure("An unknown error occured."));
    }
  } finally {
    yield put(getUnitsByPropertyId.fulfill());
  }
}

function* getUnitsByPropertyIdWatcher() {
  yield takeEvery(getUnitsByPropertyId.TRIGGER, handleGetUnitsByPropertyId);
}

function* addUnitWatcher() {
  yield takeEvery(addUnit.TRIGGER, handleAddUnit);
}

function* deleteUnitWatcher() {
  yield takeEvery(deleteUnit.TRIGGER, handleDeleteUnit);
}

function* updateUnitWatcher() {
  yield takeEvery(updateUnit.TRIGGER, handleUpdateUnit);
}

// Error handlers
function* handleUnitError(action: AnyAction) {
  yield put(
    enqueueSnackbar({
      message: action.payload,
      options: {
        variant: "error",
      },
    })
  );
}

function* unitErrorWatcher() {
  yield takeEvery([addUnit.FAILURE], handleUnitError);
}

export function* unitsSaga() {
  yield all([
    fork(unitErrorWatcher),
    fork(addUnitWatcher),
    fork(updateUnitWatcher),
    fork(deleteUnitWatcher),
    fork(getUnitsByPropertyIdWatcher)
  ]);
}
