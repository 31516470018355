import { SelectedImage } from "../../components/form-controls/property-image-selector.dialog";
import { LocaleWithCountry } from "../defaults";
import { AdSet, AdTypesEnum, CampaignGoalTypes, Targeting } from "../socials";

export interface AdViews {
  high: number;
  low: number;
  type: string;
}
export interface Features {
  [key: string]: string;
}
export interface Usage {
  [key: string]: number;
}

export interface Channel {
  _id: string;
  adTemplateId: string;
  adType: AdTypesEnum;
  budgetSplit: number;
  channel: string;
  propertyIds: any[];
  targetingTemplateId: string;
  templateVariables: any;
  listingLogic: string;
  isGeneric?: boolean;
}

export interface FlowPlan {
  usage?: Usage;
  features?: Features;
  price: number;
  annualPrice: number;
  locale: LocaleWithCountry;
  annualDiscount: number;
  name: string;
  description: string;
  type: FlowPlanTypes;
  status: string;
  isPopular: boolean;
  productVersion: number;
  trialPeriod?: number;
  subscriptions?: SubscriptionObject[];
  subscriptionCounters?: SubscriptionCounters;
  adViews?: AdViews[];
  _id?: string;
  planTitle: string;
  channels?: Channel[];
  isRecurring?: boolean;
  duration?: number;
  flowCampaignType?: CampaignGoalTypes;
  imageUrl?: string;
  reference: string;
  allowBudgetOverride?: boolean;
}

export interface SubscriptionCounters {
  active: number;
  cancelled: number;
  failed: number;
  paused: number;
  completed: number;
}
export interface BillingPeriod {
  startDate: string;
  endDate: string;
}
export interface SubscriptionObject {
  _id: string;
  agent: object;
  masterCampaign?: MasterCampaignObject;
  status: string;
  channels?: Channel[];
  properties?: object[];
  organisation?: object;
  subscriptionOrigin: string;
  updated: string;
}
export interface MasterCampaignObject {
  name: string;
  _id: string;
  campaignOrigin: string;
  status: string;
  startDate: string;
  endDate: string;
  campaignType: string;
}

export interface Subscription {
  _id: string;
  user: string;
  campaignId?: string;
  product: FlowPlan;
  billingTemplateClientId: string;
  organisationId: string;
  status: string;
  billingFrequency: string;
  campaignName?: string;
  billingPeriod?: BillingPeriod;
  metadata?: Record<string, any>;
  masterCampaignId?: string;
}

export interface SubscriptionState {
  readonly listloading: boolean;
  readonly flowPlanloading: boolean;
  checkoutLoading: boolean;
  readonly adViewsLoading?: boolean;
  subscriptionsLoading?: boolean;
  flowPlans: FlowPlan[];
  totalRecords: number;
  selectedFlowPlan?: FlowPlan;
  adViews?: AdViews;
  subscription?: Subscription;
  readonly userSubscriptions?: Subscription[];
  errors?: any;
  readonly changingSubscription: boolean;
  proRata?: number;
  calculatingProRata: boolean;
  billingFrequency?: string;
  masterCampaignCheckout?: MasterCampaignCheckout;
}

export interface BillingFrequency {
  readonly ANNUALLY: string;
  readonly MONTHLY: string;
}

export interface CampaignPayload {
  organisationId: string;
  owner: string;
  campaignLocalId: string;
  adAccountId: string;
  campaignObjective: string;
  flowCampaignType: string;
  clientName: string;
  branch: string;
  setupType: string;
  interest: string;
  adType: string;
  budget: number;
  commission: number;
  campaignGoalType: string;
  facebookPageId: string;
  startDate: string;
  endDate: string;
  adsets: AdSet[];
  screenStep: string;
  instagramPageId?: string;
}

export interface CampaignCheckoutPayload {
  isRecurring: boolean;
  campaignPayload: CampaignPayload;
  flowPlanId?: string;
  creditEntity?: "agent" | "organisation";
  billingFrequency?: "Annually" | "Monthly";
}

export interface OrganisationCheckoutPayload {
  organisationId: string;
  flowPlanId: string;
  billingFrequency: string;
}

export enum CheckoutType {
  Campaign = "campaign",
  Organisation = "organisation",
}

export interface CheckoutPayload {
  type: CheckoutType;
  data: CampaignCheckoutPayload | OrganisationCheckoutPayload;
}
export interface PagePostingPayload {
  facebookPageId: string;
  campaignLocalId?: string;
  creativeImageUrl: string;
  message: string;
}

export interface FlowPlanUsageAndFeatures {
  [key: string]: string;
}

export interface CalculateProRataPayload {
  subscriptionId: string;
  flowPlanId: string;
  billingFrequency: string;
}

export interface GetAdViewsPayload {
  organisationId: string;
  budget?: string | number;
  campaignType: CampaignGoalTypes;
}

export interface FetchFlowPlansPayload {
  readonly type: FlowPlanTypes;
  readonly countryCode: string;
  readonly organisation?: string;
  readonly showOnCheckout?: boolean;
  readonly source?: string;
  readonly includeSubscriptionUsageReport?: boolean;
  readonly ref?: string;
}

export interface FetchFlowPlansResponse {
  readonly flowPlans: FlowPlan[];
}

export interface CreateSubscriptionPayload {
  organisationId: string;
  userId: string;
  subscriptionEntity: SubscriptionEntities;
  flowPlanId: string;
  billingFrequency: Frequencies;
  subscriptionOrigin: SubscriptionOrigins;
  propertyIds: string[];
  images?: SelectedImage[];
  adTemplateId?: string;
  templateVariables?: Record<string, any>;
  externalRef?: string | null;
}

export interface MasterCampaignCheckoutPayload {
  subscriptionId: string;
  targetingOverride?: Targeting;
  budgetAndDurationOverride?: {
    budget?: number;
    duration?: number;
    startDate?: string;
    endDate?: string;
  },
  creditEntity?: "agent" | "organisation";
}

export interface MasterCampaignCheckout {
  checkout_url: string;
  subscriptionId: string;
  isPaid: boolean;
  billingTemplateClientId: string;
  checkoutId: string;
}

export interface ListingSelectionTypes {
  NONE: string;
  ONE: string;
  MANY: string;
}

// #region enums

export enum FlowPlanTypes {
  User = "User",
  Organisation = "Organisation",
  MasterCampaign = "Master Campaign",
}

export enum Frequencies {
  Annually = "Annually",
  Monthly = "Monthly",
}

export enum SubscriptionStatuses {
  Active = "Active",
  Inactive = "Inactive", // when there was an upgrade or downgrade
  Pending = "Pending", //initial status
  Cancelled = "Cancelled", // manual cancellation from the client
  Paused = "Paused", // automated cancellation from the API
  Failed = "Failed",
}

export enum ListingLogicTypes {
  SingleManualSelect = "single_manual_select",
  MultipleManualSelect = "multiple_manual_select",
  SoleMandates = "sole_mandates",
  OpenMandates = "open_mandates",
  JointMandates = "joint_mandates",
  Latest5 = "latest_5",
  Latest10 = "latest_10",
  Latest15 = "latest_15",
}

export enum SubscriptionEntities {
  Agency = "Agency",
  Agent = "Agent",
  Listing = "Listing",
}

export enum SubscriptionOrigins {
  SmartFeed = "Smartfeed",
  AdBuilder = "Adbuilder",
}

// #endregion
