import * as React from "react";

const ErrorIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={60} height={60} viewBox="0 0 50 50" {...props}>
      <g fill="#e65757" fillRule="evenodd">
        <circle fill="#e65757" cx={25} cy={25} r={25} />
        <path
          fill="#e65757"
          stroke="#fff"
          strokeWidth={2}
          strokeLinecap="round"
          strokeMiterlimit={10}
          d="m16 34 9-9 9-9M16 16l9 9 9 9"
        />
      </g>
    </svg>
  );
};

export default ErrorIcon;
