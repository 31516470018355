import { isEmpty, omit } from "lodash";
import React from "react";

import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import styled from "../../utils/styledComponents";
import { DialogTitle, Grid } from "@material-ui/core";
import HeadingBlock from "../ui/HeadingBlock";

const StyledDialog = styled((props: OwnProps) => (
  <Dialog {...omit(props, "rounded")} />
))`
  .MuiPaper-root {
    margin: 0px;
    width: 100%;
    ${({ rounded }) =>
      rounded
        ? `border-radius: 20px;
      box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
    font-family: MuseoSans-300;`
        : null}
  }
  .MuiDialog-paperWidthSm {
    max-width: 800px;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    padding: 15px 20px !important;
  }
`;

const CloseButton = styled((props: IconButtonProps) => (
  <IconButton {...props} />
))`
  && {
    position: absolute;
    top: 0.1em;
    right: 0.2em;
  }
` as any;

interface OwnProps extends DialogProps {
  contentComponent?: React.ComponentElement<any, any> | null;
  title?: string;
  description?: string;
  rounded?: boolean;
  onClose?: () => void;
}

const ActionDialog: React.FunctionComponent<OwnProps> = ({
  contentComponent,
  onClose,
  title,
  description,
  ...props
}) => {
  return (
    <StyledDialog {...props}>
      <StyledDialogTitle>
        <Grid container={true}>
          {!isEmpty(title) && (
            <Grid item={true} md={11} lg={11} sm={11} xs={11} xl={11}>
              <HeadingBlock
                title={title!}
                description={description}
                styles={{
                  leftBlock: {
                    title: { fontSize: 20 },
                    padding: "0px",
                  },
                }}
              />
            </Grid>
          )}
          {onClose && (
            <Grid item={true} md={1} lg={1} sm={1} xs={1} xl={1}>
              <CloseButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </CloseButton>
            </Grid>
          )}
        </Grid>
      </StyledDialogTitle>
      {contentComponent ? contentComponent : <div />}
    </StyledDialog>
  );
};

export default ActionDialog;
