import { get } from "lodash";
import React from "react";
import { IsMobile } from "../../utils/responsive";
import styled from "../../utils/styledComponents";
import TitleWithProps from "../typography/TitleWithProps";
import DescriptionOnlyWidget from "./DescriptionOnlyWidget";

// eslint-disable-next-line
const ProfileSection = styled.div<{
  isMobile: boolean;
  padding: string;
  mobilePadding: string;
}>`
  && {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${({ isMobile, padding, mobilePadding }) =>
      isMobile ? mobilePadding : padding};
  }
`;

// eslint-disable-next-line
const ProfileInfo = styled.div<{
  isMini: boolean;
  isMobile: boolean;
  forceNoWrap: boolean;
  forceWrap: boolean;
}>`
  && {
    text-overflow: ellipsis;
    white-space: ${({ isMini, isMobile, forceNoWrap, forceWrap }) => {
      if (forceWrap) {
        return "wrap";
      }
      if (forceNoWrap) {
        return "nowrap";
      }
      return isMini && !isMobile ? "nowrap" : "wrap";
    }}
    display: flex;
    flex-direction: column;
    padding-right: 7px;
    width: 100%;
  }
`;

const Section = styled.div`
  && {
    margin-right: 3px;
    display: flex;
    align-items: center;
  }
`;

const TitleWithPropsContainer = styled.div`
  margin-right: 10px;
`;
interface OwnProps {
  title: any;
  description: any;
  forceNoWrap?: boolean;
  forceWrap?: boolean;
  titleColor?: string;
  styles?: any; //object
}

const InlineTitleDescriptionWidget: React.FunctionComponent<OwnProps> = (
  props
) => {
  const { title, description, forceNoWrap, forceWrap } = props;
  const padding = get(props, "styles.padding", "5px 10px");
  const mobilePadding = get(props, "styles.mobilePadding", "0px");
  const descriptionFontSize = get(props, "styles.description.fontSize", 12);
  const titleFontSize = get(props, "styles.title.fontSize", 0);
  const titleFontWeight = get(props, "styles.title.fontWeight", 500);
  const titleColor = get(props, "titleColor", "default");
  const isMobile = IsMobile();
  return (
    <ProfileSection
      isMobile={isMobile}
      padding={padding}
      mobilePadding={mobilePadding}
    >
      <Section>
        <TitleWithPropsContainer>
          <TitleWithProps
            fontSize={titleFontSize}
            color={titleColor}
            fontWeight={titleFontWeight}
            title={title}
          />
        </TitleWithPropsContainer>
        <ProfileInfo
          isMini={false}
          isMobile={isMobile}
          forceNoWrap={forceNoWrap || false}
          forceWrap={forceWrap || false}
        >
          <DescriptionOnlyWidget
            text={description}
            fontSize={descriptionFontSize}
          />
        </ProfileInfo>
      </Section>
    </ProfileSection>
  );
};

export default InlineTitleDescriptionWidget;
