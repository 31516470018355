import { History, Location } from "history";
import { get, isArray, isEmpty, isNumber } from "lodash";
import queryString from "query-string";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Dispatch } from "redux";
import { bindPromiseCreators } from "redux-saga-routines";
import {
  DashboardKpiCounts,
  FlowBrandOrFuel,
  FlowCampaignTypesEnum,
} from "../../store/socials";

import { ApplicationState } from "../../store";
import { AuthenticatedUser } from "../../store/auth";
import {
  Organisation,
  patchOrganisationPromise,
} from "../../store/organisation";
import {
  addToDashboardKpiCountsPromise,
  cancelCheckoutByTransactionIdPromise,
  fetchFilteredDataPromise,
  getCampaignByIdPromise,
  getMasterCampaignByIdPromise,
  upsertPublishedCampaignPromise,
} from "../../store/socials/routines";
import {
  FlowPlanTypes,
  getSubscriptionByIdPromise,
  getSubscriptionByPurchaseIdPromise,
  Subscription,
} from "../../store/subscriptions";
import styled from "../../utils/styledComponents";
import ActionDialog from "../dialogs/ActionDialog";
import CircularProgressLoader, {
  CircularProgressLoaderProps,
} from "./CircularProgressLoader";
import { Modes } from '../../store/navigation';
import { addUserMasterCampaignSubscriptionToListPromise } from '../../store/account';

interface PropsFromState {
  user?: AuthenticatedUser;
  location: Location;
  campaignMode?: string;
  organisation: Organisation;
  campaigns?: Record<string, FlowBrandOrFuel[]>;
  dashboardKPILoading: boolean;
}

interface PropsFromDispatch {
  fetchFilteredDataPromise: typeof fetchFilteredDataPromise;
  getSubscriptionByPurchaseId: typeof getSubscriptionByPurchaseIdPromise;
  patchOrganisation: typeof patchOrganisationPromise;
  getCampaignById: typeof getCampaignByIdPromise;
  getMasterCampaignById: typeof getMasterCampaignByIdPromise;
  upsertPublishedCampaign: typeof upsertPublishedCampaignPromise;
  addToDashboardKpiCounts: typeof addToDashboardKpiCountsPromise;
  getSubscriptionById: typeof getSubscriptionByIdPromise;
  cancelCheckoutByTransactionId: typeof cancelCheckoutByTransactionIdPromise;
  addUserMasterCampaignSubscriptionToList: typeof addUserMasterCampaignSubscriptionToListPromise;
}

interface ConnectedProps {
  history: History;
}

type AllProps = PropsFromState & PropsFromDispatch & ConnectedProps;

interface State {
  dialogOpen?: boolean;
  action?: string;
  isExtend?: boolean;
  type?: string;
  error?: string;
  isRecurring?: boolean;
  objective?: string;
  isOrgSubscription: boolean;
  hasPolledSubscription: boolean;
  progressLoader: CircularProgressLoaderProps;
  campaignId?: string;
  purchaseId?: string;
  campaign?: FlowBrandOrFuel;
  subscriptionId?: string;
  transactionId?: string;
  mode: string;
  masterCampaignId?: string;
}

interface PollSubscriptionPayload {
  purchaseId?: string;
  subscriptionId?: string;
}

interface PollCampaignPayload {
  campaignId?: string;
  masterCampaignId?: string;
}

const DialogBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  align-items: center;
`;

enum PostCheckoutStates {
  Successful = "successful",
  Cancelled = "cancelled",
  Failed = "failed",
}

enum SubscriptionChangeObjectives {
  Initial = "initial",
  Upgrade = "upgrade",
  Downgrade = "downgrade",
  Activate = "activate"
}

const progressLoaderInitialState: CircularProgressLoaderProps = {
  isLoading: false,
  messageText: "",
  errorMessage: "",
  buttonText: "",
  actionText: "",
  successText: "",
  failedText: "",
  onFinish: () => {},
};

class DeeplinkHandlerV2 extends React.PureComponent<AllProps, State> {
  public readonly state: State = {
    action: undefined,
    isExtend: false,
    dialogOpen: undefined,
    type: undefined,
    error: undefined,
    isRecurring: false,
    objective: undefined,
    isOrgSubscription: false,
    hasPolledSubscription: false,
    progressLoader: progressLoaderInitialState,
    mode: Modes.Default,
  };

  public readonly objectiveInfinitives = {
    Upgrade: "Upgrading",
    Downgrade: "Downgrading",
  };

  public static getDerivedStateFromProps(
    nextProps: AllProps,
    prevState: State
  ) {
    const { user, location } = nextProps;
    const query = queryString.parse(location.search);

    // if logged in and pathname is /campaigns or /account/billing
    if (
      user &&
      (location.pathname === "/campaigns" ||
        location.pathname === "/account/billing")
    ) {
      const status = get(query, "status");
      const isExtend = get(query, "isExtend", "false") === "true";
      const localCampaignId = get(query, "id");
      const purchaseId = get(query, "purchase_id");
      const objective = get(query, "objective");
      const subscriptionId = get(query, "subscription");
      const transactionId = get(query, "transactionId");
      const masterCampaignId = get(query, "masterCampaignId", "");

      const isOrgSubscription =
        (!localCampaignId && !masterCampaignId) &&
        (!!purchaseId || !!subscriptionId) &&
        location.pathname === "/account/billing";

      // successful
      if (
        status === PostCheckoutStates.Successful &&
        (localCampaignId || masterCampaignId || purchaseId || subscriptionId)
      ) {
        localStorage.setItem("gtmPublishStatus", PostCheckoutStates.Successful);
        return {
          ...prevState,
          action: PostCheckoutStates.Successful,
          isExtend,
          dialogOpen: get(prevState, "dialogOpen", true),
          isRecurring: (!isEmpty(purchaseId) || !isEmpty(subscriptionId)) || !isEmpty(objective),
          objective,
          isOrgSubscription,
          campaignId: localCampaignId,
          purchaseId,
          subscriptionId,
          transactionId,
          mode: get(query, "mode") || Modes.Default,
          masterCampaignId,
        };
      }

      // cancelled
      if (
        status === PostCheckoutStates.Cancelled &&
        (localCampaignId || masterCampaignId || purchaseId || subscriptionId)
      ) {
        localStorage.setItem("gtmPublishStatus", PostCheckoutStates.Cancelled);
        return {
          ...prevState,
          action: PostCheckoutStates.Cancelled,
          isExtend,
          dialogOpen: get(prevState, "dialogOpen", true),
          isRecurring: (!isEmpty(purchaseId) || !isEmpty(subscriptionId)) || !isEmpty(objective),
          objective,
          isOrgSubscription,
          campaignId: localCampaignId,
          purchaseId,
          subscriptionId,
          transactionId,
          masterCampaignId,
        };
      }

      // failed
      if (
        status === PostCheckoutStates.Failed &&
        (localCampaignId || masterCampaignId || purchaseId || subscriptionId)
      ) {
        localStorage.setItem("gtmPublishStatus", PostCheckoutStates.Failed);

        return {
          ...prevState,
          action: PostCheckoutStates.Failed,
          isExtend,
          dialogOpen: get(prevState, "dialogOpen", true),
          isRecurring: (!isEmpty(purchaseId) || !isEmpty(subscriptionId)) || !isEmpty(objective),
          objective,
          isOrgSubscription,
          campaignId: localCampaignId,
          purchaseId,
          subscriptionId,
          transactionId,
          masterCampaignId,
        };
      }
    }

    return { ...prevState };
  }

  public async componentDidUpdate(prevProps: AllProps, prevState: State) {
    if (
      !this.props.dashboardKPILoading &&
      prevProps.dashboardKPILoading !== this.props.dashboardKPILoading
    ) {
      if (
        !this.state.isOrgSubscription &&
        this.state.hasPolledSubscription &&
        this.state.progressLoader.isLoading
      ) {
        if (this.state.campaign && this.state.campaign.isDraft === false) {
          const objective = get(
            this.state,
            "objective",
            SubscriptionChangeObjectives.Initial
          );
          const messageText =
            this.getProgressLoaderSuccessMessageTextByObjective(objective);
          this.setState({
            progressLoader: {
              ...this.state.progressLoader,
              isLoading: false,
              messageText,
            },
          });
        }
      }
    }

    if (
      prevState.action !== this.state.action &&
      this.props.user &&
      (this.state.campaignId || this.state.masterCampaignId || this.state.purchaseId || this.state.subscriptionId)
    ) {
      try {
        this.setProgressLoaderProps();

        if (this.state.action === PostCheckoutStates.Successful) {
          if (this.state.isOrgSubscription) {
            // organisation plan checkout

            if (!this.state.hasPolledSubscription) {
              // only poll the api if checkout was successful
              const subscription = await this.pollSubscription({
                purchaseId: this.state.purchaseId,
                subscriptionId: this.state.subscriptionId
              });
              const isActivated = this.isSubscriptionActivated(subscription);
              if (isActivated) {
                const isMasterCampaignSubscription = get(subscription, 'product.type') === FlowPlanTypes.MasterCampaign;
                if (isMasterCampaignSubscription) this.props.addUserMasterCampaignSubscriptionToList(subscription!);
                else {
                  setTimeout(async () => {
                    await this.props.patchOrganisation({ subscription });
                  }, 500);
                }
              }

              const objective = get(
                this.state,
                "objective",
                SubscriptionChangeObjectives.Initial
              );

              let successText = this.state.progressLoader.successText;
              let messageText =
                this.getProgressLoaderSuccessMessageTextByObjective(objective);
              if (!isActivated) successText = "Pending";
              if (!isActivated)
                messageText =
                  "Subscription pending activation, please refresh page or check back later";

              this.setState({
                hasPolledSubscription: true,
                progressLoader: {
                  ...this.state.progressLoader,
                  isLoading: false,
                  successText,
                  messageText,
                },
              });
            }
          } else {
            // campaign plan checkout
            let campaignId = this.state.campaignId;
            let masterCampaignId = this.state.masterCampaignId;

            // is this a recurring campaign?
            if (this.state.isRecurring) {
              let subscription;
              if (this.state.purchaseId) subscription = await this.getSubscriptionByPurchaseId(this.state.purchaseId);
              else if (this.state.subscriptionId) subscription = await this.getSubscriptionById(this.state.subscriptionId);

              if (subscription) {
                if (subscription.campaignId) campaignId = subscription.campaignId;
                if (subscription.masterCampaignId) masterCampaignId = subscription.masterCampaignId;
              }
            }

            if ((campaignId || masterCampaignId) && !this.state.hasPolledSubscription) {
              // poll campaign
              const campaign = await this.pollCampaignById({ campaignId, masterCampaignId });

              const isPublished = !!campaign && campaign.isDraft === false;
              const isProcessing = !!campaign && !!campaign.isProcessing;
              const error = campaign && campaign.error;

              let isLoading = false;
              let successText = this.state.progressLoader.successText;
              let messageText = this.state.progressLoader.messageText;
              let failedText = "";
              let errorMessage = "";

              if (isPublished && !error && this.props.dashboardKPILoading)
                isLoading = true;
              else if (
                isPublished &&
                !error &&
                !this.props.dashboardKPILoading
              ) {
                const objective = get(
                  this.state,
                  "objective",
                  SubscriptionChangeObjectives.Initial
                );
                messageText =
                  this.getProgressLoaderSuccessMessageTextByObjective(
                    objective
                  );
              } else if (error) {
                failedText = "Error";
                errorMessage = error;
              } else if (isProcessing) {
                successText = "Processing";
                messageText =
                  "Campaign taking a bit longer to process, please refresh page or check back later";
              }

              this.setState({
                hasPolledSubscription: true,
                progressLoader: {
                  ...this.state.progressLoader,
                  isLoading,
                  successText,
                  messageText,
                  failedText,
                  errorMessage,
                },
                campaign: isPublished ? campaign! : undefined,
              });
            } else {
              this.setState({
                progressLoader: {
                  ...this.state.progressLoader,
                  isLoading: false,
                  failedText: "Unknown error",
                  errorMessage: "Something went wrong, please try again",
                },
              });
            }
          }
        }
        else if (this.state.action === PostCheckoutStates.Cancelled) {
          if (this.state.transactionId) {
            await this.props.cancelCheckoutByTransactionId(this.state.transactionId);
          }
        }
      } catch (error) {
        this.setState({
          error: get(
            error,
            "message",
            "Something went wrong, please try again"
          ),
        });
      }
    }
  }

  public render() {
    return (
      <>
        <ActionDialog
          contentComponent={this.progressLoaderDialogComponent}
          onClose={this.handleDialogClose}
          open={this.state.dialogOpen || false}
          fullWidth={true}
          disableBackdropClick
          disableEscapeKeyDown
        />
      </>
    );
  }

  private handleDialogClose = async () => {
    if (this.state.campaign) {
      await this.props.upsertPublishedCampaign(this.state.campaign);

      const kpiCounts = this.deriveKpiCountsFromCampaign(this.state.campaign);
      await this.props.addToDashboardKpiCounts(kpiCounts);
    }

    this.setState({
      action: undefined,
      dialogOpen: false,
      campaign: undefined,
    });

    this.props.history.replace(this.props.location.pathname);
  };

  private getSubscriptionById = async (
    subscriptionId: string
  ): Promise<Subscription | undefined> => {
    try {
      return await this.props.getSubscriptionById(subscriptionId);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  private getSubscriptionByPurchaseId = async (
    purchaseId: string
  ): Promise<Subscription | undefined> => {
    try {
      return await this.props.getSubscriptionByPurchaseId(purchaseId);
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  private async getCampaignById(
    campaignId: string
  ): Promise<FlowBrandOrFuel | null> {
    let campaign = null;
    try {
      campaign = await this.props.getCampaignById(campaignId);
    } catch (error) {
      console.error(error);
    }
    return campaign;
  }

  private async getMasterCampaignById(
    masterCampaignId: string
  ): Promise<FlowBrandOrFuel | null> {
    let masterCampaign = null;
    try {
      masterCampaign = await this.props.getMasterCampaignById(masterCampaignId);
    } catch (error) {
      console.error(error);
    }
    return masterCampaign;
  }

  private deriveKpiCountsFromCampaign(
    campaign: FlowBrandOrFuel
  ): DashboardKpiCounts {
    const isFlowBrand =
      campaign.flowCampaignType === FlowCampaignTypesEnum.FlowBrand;

    let listingsProfileViews,
      uniquePeopleReached,
      buyerLeads = 0,
      sellerLeads = 0,
      recruitLeads = 0,
      campaignsCount = 0;

    uniquePeopleReached = isNumber(campaign.reach) ? +campaign.reach : 0;
    listingsProfileViews = isNumber(campaign.profileViews)
      ? +campaign.profileViews
      : 0;

    if (!this.props.campaigns) campaignsCount = 1;
    else {
      let key = null;
      if (isFlowBrand && isArray(this.props.campaigns.flowbrand))
        key = "flowbrand";
      else if (isArray(this.props.campaigns.flowfuel)) key = "flowfuel";

      if (key) {
        const idx = this.props.campaigns[key].findIndex(
          (_campaign) =>
            _campaign.localCampaignId === campaign.localCampaignId ||
            _campaign.id === campaign.localCampaignId
        );
        if (idx === -1) campaignsCount = 1;
      }
    }

    switch (campaign.flowCampaignType) {
      case FlowCampaignTypesEnum.FlowFuelBuyers:
        buyerLeads = isNumber(campaign.leadsCount) ? +campaign.leadsCount : 0;
        break;
      case FlowCampaignTypesEnum.FlowFuelSellers:
      case FlowCampaignTypesEnum.FlowBrand:
        sellerLeads = isNumber(campaign.leadsCount) ? +campaign.leadsCount : 0;
        break;
      case FlowCampaignTypesEnum.FlowFuelRecruit:
        recruitLeads = isNumber(campaign.leadsCount) ? +campaign.leadsCount : 0;
        break;
      default:
        break;
    }

    return {
      buyerLeads,
      sellerLeads,
      recruitLeads,
      campaignsCount,
      uniquePeopleReached,
      listingsProfileViews,
    };
  }

  private isSubscriptionActivated(subscription?: Subscription): boolean {
    return get(subscription, "status") === "Active";
  }

  private pollSubscription({ purchaseId, subscriptionId }: PollSubscriptionPayload): Promise<Subscription | undefined> {
    const id = purchaseId || subscriptionId;

    if (!id) return Promise.resolve(undefined);

    let pollMethod: (id: string) => Promise<Subscription | undefined>;
    if (purchaseId) pollMethod = this.getSubscriptionByPurchaseId;
    else if (subscriptionId) pollMethod = this.getSubscriptionById;

    return new Promise(async (resolve) => {
      let subscription = await pollMethod(id);

      if (this.isSubscriptionActivated(subscription)) resolve(subscription);
      else {
        const timeout = 60000; // 1m
        const delay = 3000; // 3s

        const pollInterval = setInterval(async () => {
          subscription = await pollMethod(id);
          if (this.isSubscriptionActivated(subscription)) {
            resolve(subscription);
            clearInterval(pollInterval);
          }
        }, delay);

        setTimeout(() => {
          clearInterval(pollInterval);
          resolve(subscription);
        }, timeout);
      }
    });
  }

  private pollCampaignById({ campaignId, masterCampaignId }: PollCampaignPayload): Promise<FlowBrandOrFuel | null> {
    const id = campaignId || masterCampaignId;

    if (!id) return Promise.resolve(null);

    let pollMethod: (id: string) => Promise<FlowBrandOrFuel | null>;
    if (campaignId) pollMethod = this.getCampaignById;
    else if (masterCampaignId) pollMethod = this.getMasterCampaignById.bind(this);

    return new Promise(async (resolve) => {
      let campaign = await pollMethod(id);
      let isPublished = !!campaign && campaign.isDraft === false;
      let hasError = !!campaign && !!campaign.error;
      let isProcessing = !!campaign && !!campaign.isProcessing;

      if (!isProcessing && (isPublished || hasError)) resolve(campaign);
      else {
        let timeout = 60000; // 1m
        const delay = 3000; // 3s

        const pollInterval = setInterval(async () => {
          campaign = await pollMethod(id);

          isPublished = !!campaign && campaign.isDraft === false;
          hasError = !!campaign && !!campaign.error;
          isProcessing = !!campaign && !!campaign.isProcessing;

          // resolve if is published or has error
          if (!isProcessing && (isPublished || hasError)) {
            resolve(campaign);
            clearInterval(pollInterval);
          } else if (isProcessing && timeout < 120000) timeout = 120000; // increase time if we're busy publishing to FB
        }, delay);

        setTimeout(() => {
          clearInterval(pollInterval);
          resolve(campaign);
        }, timeout);
      }
    });
  }

  private getProgressLoaderActionTextByObjective(objective: string) {
    const defaultActionText = this.state.isOrgSubscription
      ? "Subscribing"
      : "Creating";

    switch (objective.toLowerCase()) {
      case SubscriptionChangeObjectives.Upgrade:
        return "Upgrading";
      case SubscriptionChangeObjectives.Downgrade:
        return "Downgrading";
      case SubscriptionChangeObjectives.Activate:
        return "Activating";
      default:
        return this.state.isExtend ? "Extending" : defaultActionText;
    }
  }

  private getProgressLoaderSuccessTextByObjective(objective: string) {
    const defaultSuccessText = this.state.isOrgSubscription
      ? "Subscribed"
      : "Created";

    switch (objective.toLowerCase()) {
      case SubscriptionChangeObjectives.Upgrade:
        return "Upgraded";
      case SubscriptionChangeObjectives.Downgrade:
        return "Downgraded";
      case SubscriptionChangeObjectives.Activate:
        return "Activated";
      default:
        return this.state.isExtend ? "Extended" : defaultSuccessText;
    }
  }

  private getProgressLoaderLoadingMessageTextByObjective(objective: string) {
    if (this.state.isOrgSubscription) {
      switch (objective.toLowerCase()) {
        case SubscriptionChangeObjectives.Upgrade:
          return "Upgrading subscription...";
        case SubscriptionChangeObjectives.Downgrade:
          return "Downgrading subscription...";
        case SubscriptionChangeObjectives.Activate:
          return "Activating subscription...";
        default:
          return "Subscribing to package...";
      }
    } else {
      const entity = (this.state.isRecurring && !this.state.masterCampaignId)
        ? (objective.toLowerCase() === SubscriptionChangeObjectives.Activate ? "subscription" : "ad and subscription")
        : "Flow campaign";
      const successTextByObjective =
        this.getProgressLoaderSuccessTextByObjective(objective);

      return `Your ${entity} is being ${successTextByObjective.toLowerCase()}`;
    }
  }

  private getProgressLoaderSuccessMessageTextByObjective(objective: string) {
    if (this.state.isOrgSubscription) {
      switch (objective.toLowerCase()) {
        case SubscriptionChangeObjectives.Upgrade:
          return "Upgraded subscription successfully";
        case SubscriptionChangeObjectives.Downgrade:
          return "Downgraded subscription successfully";
        case SubscriptionChangeObjectives.Activate:
          return "Activated subscription successfully";
        default:
          return "Subscription successfully created and activated";
      }
    } else {
      const entity = (this.state.isRecurring && !this.state.masterCampaignId) ? "subscription" : "campaign";
      const successTextByObjective =
        this.getProgressLoaderSuccessTextByObjective(objective);
      const postText =
        (this.state.isRecurring && !this.state.masterCampaignId) &&
        objective.toLowerCase() === SubscriptionChangeObjectives.Initial
          ? ", and your ad is live"
          : this.state.masterCampaignId ? " and published" : "";

      return `Congrats! Your Flow ${entity} has been successfully ${successTextByObjective.toLowerCase()}${postText}`;
    }
  }

  private setProgressLoaderProps() {
    const updates = { ...this.state.progressLoader };

    let onFinish: () => any = () => this.handleDialogClose();

    const objective = get(
      this.state,
      "objective",
      SubscriptionChangeObjectives.Initial
    );

    if (this.state.action === PostCheckoutStates.Successful) {
      updates.buttonText = "Done";
      updates.actionText =
        this.getProgressLoaderActionTextByObjective(objective);
      updates.messageText =
        this.getProgressLoaderLoadingMessageTextByObjective(objective);
      updates.successText =
        this.getProgressLoaderSuccessTextByObjective(objective);
      updates.isLoading = true;
    } else if (this.state.action === PostCheckoutStates.Failed) {
      const paymentFailedMessage =
        this.state.isOrgSubscription || this.state.isExtend || objective.toLowerCase() === SubscriptionChangeObjectives.Activate
          ? "Payment failed, please try again"
          : "Payment failed, to try again please press finish button from the drafted campaign";

      updates.failedText = "Payment failed";
      updates.errorMessage = paymentFailedMessage;
      onFinish = () => this.props.history.goBack();
    } else if (this.state.action === PostCheckoutStates.Cancelled) {
      const paymentCancelledMessage =
        this.state.isOrgSubscription || this.state.isExtend || objective.toLowerCase() === SubscriptionChangeObjectives.Activate
          ? "The payment was cancelled, please try again"
          : "The payment was cancelled, to try again please press finish button from the drafted campaign";

      updates.failedText = "Payment cancelled";
      updates.errorMessage = paymentCancelledMessage;
    }

    updates.onFinish = onFinish;

    this.setState({ progressLoader: updates });
  }

  public get progressLoaderDialogComponent() {
    return (
      <DialogBody>
        <CircularProgressLoader
          buttonText={this.state.progressLoader.buttonText}
          messageText={this.state.progressLoader.messageText}
          isLoading={this.state.progressLoader.isLoading}
          errorMessage={this.state.progressLoader.errorMessage}
          onFinish={this.state.progressLoader.onFinish}
          actionText={this.state.progressLoader.actionText}
          successText={this.state.progressLoader.successText}
          failedText={this.state.progressLoader.failedText}
          hideDoneButton={this.state.mode === Modes.LinkOut}
        />
      </DialogBody>
    );
  }
}

const mapStateToProps = ({
  auth,
  router,
  deeplink,
  organisation,
  social,
}: ApplicationState) => ({
  deeplink: deeplink.queryString,
  location: router.location,
  user: auth.user,
  organisation: get(
    organisation,
    "subOrganisation",
    get(organisation, "organisation")
  ),
  campaigns: social.campaigns,
  dashboardKPILoading: social.dashboardKPILoading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindPromiseCreators(
    {
      fetchFilteredDataPromise,
      getSubscriptionByPurchaseId: getSubscriptionByPurchaseIdPromise,
      patchOrganisation: patchOrganisationPromise,
      getCampaignById: getCampaignByIdPromise,
      getMasterCampaignById: getMasterCampaignByIdPromise,
      upsertPublishedCampaign: upsertPublishedCampaignPromise,
      addToDashboardKpiCounts: addToDashboardKpiCountsPromise,
      getSubscriptionById: getSubscriptionByIdPromise,
      cancelCheckoutByTransactionId: cancelCheckoutByTransactionIdPromise,
      addUserMasterCampaignSubscriptionToList: addUserMasterCampaignSubscriptionToListPromise,
    },
    dispatch
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeeplinkHandlerV2) as any
);
