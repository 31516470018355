import React from "react";

import MuiSvgIcon from "@material-ui/core/SvgIcon";

import { IconProps } from ".";

const ExclamationIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon>
    <svg {...props}>
      <g fillRule="nonzero" fill="#4c4c4e">
        <path
          fill-rule="evenodd"
          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V8Zm-1 7a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2H12Z"
          clip-rule="evenodd"
        />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default ExclamationIcon;
