import React from 'react';

import MuiSvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '../icons';

const LogoutIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 18 24" fontSize="inherit">
    <svg width={18} height={24} {...props}>
      <g
        transform="translate(-3)"
        stroke={nativeColor}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12.5 2.5H20a.5.5 0 0 1 .5.5v18a.5.5 0 0 1-.5.5h-7.5M3.5 21.223a.5.5 0 0 0 .392.488l8 1.777A.5.5 0 0 0 12.5 23V1a.5.5 0 0 0-.608-.488l-8 1.778a.5.5 0 0 0-.392.488v18.445z" />
        <circle cx={9} cy={12} r={1.5} />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default LogoutIcon;
