import MuiSvgIcon from "@material-ui/core/SvgIcon";
import React from "react";
import { IconProps } from "../icons";

const EmailIcon: React.FunctionComponent<IconProps> = ({
    nativeColor,
    ...props
  }) => (
  <MuiSvgIcon viewBox="0 0 20 13" {...props}>
    <svg width={20} height={13} {...props}>
      <g fill="#4C4C4E" fillRule="nonzero">
        <path d="M9.993 8.021L.902.038h18.182zM6.371 6.291L0 11.886V.696z" />
        <path d="M7.197 7.017l2.796 2.455 2.795-2.455 6.301 5.533H.896zM13.614 6.292L19.985.696v11.19z" />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default EmailIcon;
