import { push } from 'connected-react-router';
import * as _ from 'lodash';
import { AnyAction } from 'redux';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { ApplicationState } from '../';
import callApi from '../../utils/callApi';
import { errorHandler } from '../../utils/errorHandler';
import { logEvent } from '../analytics';
import { enqueueSnackbar } from '../notifications';
import { fetchTenants, selectTenant, uploadTenants } from './routines';
import { Tenant } from './types';

export const getTenants = ({ tenants }: ApplicationState) => tenants.tenants;

function* handleSelectTenant(action: AnyAction) {
  try {
    yield put(selectTenant.request());
    //  Check if tenant exist in store
    if (action.payload && action.payload.tenantId) {
      const tenants = yield select(getTenants);

      const selectedTenant = tenants.find(
        (tenant: Tenant) => tenant.leaseAgreement.id === action.payload.tenantId
      );

      if (selectedTenant) {
        yield put(selectTenant.success(selectedTenant));
      } else {
        yield put(selectTenant.failure());
        yield put(
          enqueueSnackbar({
            message: "Tenant not found",
            options: {
              variant: "error",
            },
          })
        );
      }
    } else {
      // Clear the selected Tenant
      yield put(selectTenant.failure());
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(selectTenant.fulfill());
  }
}

function* handleFetchTenants(action: AnyAction) {
  yield put(fetchTenants.request());
  try {
    const res = yield call(callApi, "get", `/tenants?state=current`);

    // Format tenant data
    const tenants = res.data
      .map((leaseAgreement: any) => {
        const user = leaseAgreement.tenantUser
          ? leaseAgreement.tenantUser
          : leaseAgreement.tenantDetails;
        const property = leaseAgreement.property
          ? leaseAgreement.property
          : leaseAgreement.propertyDetails;
        const lease = _.omit(leaseAgreement, ["tenantUser", "property"]);

        return { user, property, leaseAgreement: lease };
      })
      .filter((t: any) => t.user && t.property && t.leaseAgreement);

    yield put(fetchTenants.success(tenants));
  } catch (err) {
    if (err.response) {
      yield put(fetchTenants.failure(errorHandler(err.response)));
    } else {
      yield put(fetchTenants.failure("An uknown error occured"));
    }
  } finally {
    yield put(fetchTenants.fulfill());
  }
}

function* handleUploadTenants(action: AnyAction) {
  yield put(uploadTenants.request());
  try {
    const { file, mapping } = action.payload;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("mapping", JSON.stringify(mapping));

    const config = {
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const res = yield call(callApi, "post", `/tenants/upload`, config);
    yield put(uploadTenants.success(res.data));
    yield put(
      logEvent({
        event: "landlord_complete_bulk_invite",
      })
    );
    yield put(
      enqueueSnackbar({
        message: "Upload completed",
        options: {
          variant: "success",
        },
      })
    );
  } catch (err) {
    if (err.response) {
      yield put(uploadTenants.failure(errorHandler(err.response)));
      yield put(
        enqueueSnackbar({
          message: errorHandler(err.response),
          options: {
            variant: "error",
          },
        })
      );
      yield put(push(`/tenants/upload`));
    } else {
      yield put(uploadTenants.failure("An uknown error occured"));
    }
  } finally {
    yield put(uploadTenants.fulfill());
  }
}

function* selectTenantWatcher() {
  yield takeEvery(selectTenant.TRIGGER, handleSelectTenant);
}

function* fetchTenantsWatcher() {
  yield takeEvery(fetchTenants.TRIGGER, handleFetchTenants);
}

function* uploadTenantsWatcher() {
  yield takeEvery(uploadTenants.TRIGGER, handleUploadTenants);
}

export function* tenantsSaga() {
  yield all([
    fork(selectTenantWatcher),
    fork(fetchTenantsWatcher),
    fork(uploadTenantsWatcher),
  ]);
}
