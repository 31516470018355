import React from "react";
import Orange_Oval from "../../assets/images/illustrations/Orange_Oval.png";
import styled, { mediaDown, mediaUp } from "../../utils/styledComponents";
import AppStoreButton from "../buttons/AppStoreButton";
import Button from "../buttons/Button";
import GooglePlayStoreButton from "../buttons/GooglePlayStoreButton";

const NavBar = styled.header`
  background-color: #ffffff;
  position: static;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: space-between;
  height: 80px;
  border-bottom: solid 2px ${({ theme }) => theme.colors.grey1};
`;

const FlowLogo = styled.div`
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: center;
  vertical-align: middle;
`;

const LogoLabel = styled.span`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
`;

const OrangeOval = styled.img`
  margin-top: 5px;
  padding: 8px;
  border-radius: 15px;
  height: 50px;
  width: 50px;
  text-align: center;

  border: solid thin ${({ theme }) => theme.colors.grey1};
`;

const AppStoreContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  display: inline-block;
  ${mediaDown.sm`display: none`};
`;

const DowloadButton = styled.div`
  ${mediaUp.sm`display: none`};
  margin-top: auto;
  margin-bottom: auto;
`;

const DerivedGooglePlayStoreButton = styled(GooglePlayStoreButton)`
  && {
    margin-left: 10px;
  }
`;

const StyledButton = styled(Button)`
  && {
    border-radius: 25px;
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    height: 60px;
  }
`;

interface DispactchProps {
  showDialog: (event: React.SyntheticEvent) => void;
}

export const redirectToStore = (os: "android" | "ios"): void => {
  const url =
    os === "ios"
      ? "https://apps.apple.com/us/app/flow-rewards-for-renting/id1435766179"
      : "https://play.google.com/store/apps/details?id=com.flowliving&hl=en";

  const win = window.open(url, "_blank");
  if (win != null) {
    win.focus();
  }
};

const DownloadBanner: React.FunctionComponent<DispactchProps> = ({
  showDialog
}) => {
  const onClick = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    const platform = require("platform");

    switch (platform.os.family) {
      case "Android":
      case "iOS":
        redirectToStore(platform.os.family.toString().toLowerCase());
        break;
      default:
        showDialog(event);
        break;
    }
  };
  return (
    <NavBar>
      <FlowLogo>
        <OrangeOval src={Orange_Oval} alt="Flow Living" />
        <LogoLabel>
          Flow Living <br />
          Free Download
        </LogoLabel>
      </FlowLogo>
      <AppStoreContainer>
        <AppStoreButton />
        <DerivedGooglePlayStoreButton />
      </AppStoreContainer>
      <DowloadButton>
        <StyledButton onClick={onClick} variant="contained">
          Download
        </StyledButton>
      </DowloadButton>
    </NavBar>
  );
};

export default DownloadBanner;
