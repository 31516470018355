import { createBrowserHistory } from "history";
import * as React from "react";
import * as ReactDOM from "react-dom";
import configureStore from "./configureStore";
import Main from "./main";
import registerServiceWorker from "./registerServiceWorker";
import GlobalStyles from "./styles";
import { initializeGTM } from "./utils/GoogleTagManager";

const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME,
});

const store = configureStore(history);

ReactDOM.render(
  <React.Fragment>
    <GlobalStyles />
    <Main store={store} history={history} />
  </React.Fragment>,
  document.getElementById("root")
);

registerServiceWorker();

initializeGTM();
