import { LocationChangeAction, LOCATION_CHANGE, replace } from 'connected-react-router';
import { cloneDeep, get, isString } from 'lodash';
import { AnyAction } from 'redux';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { getQueryStringFromLocation } from '../../utils/url.helper';
import { ApplicationState } from '..';
import { Organisation, OrganisationState } from '../organisation';

import { routeChange, setMode } from './routines';
import { Action } from 'redux-actions';

function getOrganisationFromState(organisationState: OrganisationState): Organisation | undefined {
  return organisationState.subOrganisation || organisationState.organisation;
}

function* handleRouteChange(action: AnyAction) {
  yield put(routeChange.success(action.payload));
}

function* handleSetMode(action: Action<string>) {
  yield put(setMode.success(action.payload));
}

function* handleRouterLocationChange(action: LocationChangeAction) {
  // check for query string
  let queryString = getQueryStringFromLocation(action.payload.location);

  // add organisation query param if not present
  try {
    const queryParams = new URLSearchParams(queryString);
    let queryParamsChanged = false;

    // do we have the organisation query param or is this a revio payment redirect?
    if (!queryParams.has('organisation') && !queryParams.has('purchase_id')) {
      // get org state
      const orgState: OrganisationState = yield select((state: ApplicationState) => state.organisation);

      // get organisation id
      const organisation = getOrganisationFromState(orgState);
      const organisationId = get(organisation, '_id');

      // set organisation query param if organisationId is defined
      if (organisationId && isString(organisationId)) {
        queryParams.set('organisation', organisationId);
        queryParamsChanged = true;
      }
    }

    const currentMode: string = yield select((state: ApplicationState) => state.navigation.mode);

    if (!queryParams.has('mode')) {
      queryParams.set('mode', currentMode);
      queryParamsChanged = true;
    }
    else if (queryParams.get('mode')!.toLowerCase() !== currentMode.toLowerCase()) {
      yield put(setMode(queryParams.get('mode')));
    }

    if (queryParamsChanged) {
      const location = cloneDeep(action.payload.location);
      Object.assign(location, { search: queryParams.toString() });
      yield put(replace(location));
    }
  } catch (err) {
    console.warn('Failed to parse query string:', queryString, err);
  }
}

function* routeChangeWatcher() {
  yield takeEvery(routeChange.TRIGGER, handleRouteChange);
}

function* setModeWatcher() {
  yield takeEvery(setMode.TRIGGER, handleSetMode);
}

function* routerLocationChangeWatcher() {
  yield takeEvery(LOCATION_CHANGE, handleRouterLocationChange);
}

export function* navigationSaga() {
  yield all([
    fork(routeChangeWatcher),
    fork(setModeWatcher),
    fork(routerLocationChangeWatcher)
  ]);
}
