import React from "react";

import MuiSvgIcon from "@material-ui/core/SvgIcon";

import { IconProps } from ".";

const PreviewIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon>
    <svg>
      <g fillRule="nonzero" stroke="#4c4c4e" strokeWidth={1.5} fill="none">
        <path d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z" />
        <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default PreviewIcon;
