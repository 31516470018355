import { Reducer } from 'redux';

import { setDeeplink, unsetDeeplink } from './routines';
import { DeeplinkState } from './types';

const initialState: DeeplinkState = {
  dialogOpen: false,
  errors: undefined,
  loading: false,
  queryString: {}
};

const reducer: Reducer<DeeplinkState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger
    case setDeeplink.TRIGGER:
    case unsetDeeplink.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }
    // Success
    case setDeeplink.SUCCESS: {
      return { ...state, queryString: action.payload };
    }
    case unsetDeeplink.SUCCESS: {
      return { ...state, queryString: {} };
    }
    // Failure
    case setDeeplink.FAILURE:
    case unsetDeeplink.FAILURE: {
      return { ...state, errors: action.payload };
    }
    // Fulfill
    case setDeeplink.FULFILL:
    case unsetDeeplink.FULFILL: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
};

export { reducer as deeplinkReducer };
