import { isEmpty, get } from 'lodash';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { ApplicationState } from '..';
import callApi from '../../utils/callApi';
import { errorHandler } from '../../utils/errorHandler';
import { enqueueSnackbar } from '../notifications';
import {
  fetchActiveLocales,
  fetchActiveEnums
} from './routines';

function* handleFetchActiveLocales(): any {
  try {
    const res = yield call(
      callApi,
      "get",
      "/locales"
    );

    yield put(
      fetchActiveLocales.success({
        locales: res.data
      })
    );
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler(response),
          options: { variant: "error" },
        })
      );
      yield put(fetchActiveLocales.failure(errorHandler(response)));
    } else {
      yield put(fetchActiveLocales.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchActiveLocales.fulfill());
  }
}
function* fetchActiveLocalesWatcher(): any {
  yield takeEvery(fetchActiveLocales.TRIGGER, handleFetchActiveLocales);
}

function* handleFetchActiveEnums(): any {
  try {
    let enums = yield select((state: ApplicationState) => state.defaults.enums);
    if (isEmpty(get(enums, "minimumStripeCheckoutAmount"))) {
      //checking with an enum that is only for logged in users to make sure we have all the enums
      const res = yield call(
        callApi,
        "get",
        "/enums"
      );

      enums = res.data;
    }

    yield put(
      fetchActiveEnums.success({ enums })
    );
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler(response),
          options: { variant: "error" },
        })
      );
      yield put(fetchActiveEnums.failure(errorHandler(response)));
    } else {
      yield put(fetchActiveEnums.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchActiveEnums.fulfill());
  }
}
function* fetchActiveEnumsWatcher() {
  yield takeEvery(fetchActiveEnums.TRIGGER, handleFetchActiveEnums);
}

export function* defaultSaga() {
  yield all([
    fork(fetchActiveLocalesWatcher),
    fork(fetchActiveEnumsWatcher),
  ]);
}
