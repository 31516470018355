import React from 'react';

import MuiSvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '../icons';

const House: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 50 36" fontSize="inherit">
    <svg width={50} height={36} {...props}>
      <g fill={nativeColor} fillRule="nonzero">
        <path d="M40.574 14.022a.667.667 0 0 0 .712.078.687.687 0 0 0 .38-.617V9.822a.69.69 0 0 0-.257-.54L30.421.48a2.081 2.081 0 0 0-2.617 0l-4.136 3.314V2.5a.68.68 0 0 0-.675-.687h-2.88a.68.68 0 0 0-.675.687v4.68l-2.622 2.102a.69.69 0 0 0-.257.539v3.66c0 .264.147.503.38.618.233.115.51.085.712-.078l.348-.278v6.688l-6.296-5.044a2.081 2.081 0 0 0-2.618 0L.257 22.461a.69.69 0 0 0-.257.54v3.66c0 .263.148.503.38.617a.664.664 0 0 0 .713-.078l.347-.278v7.106H.675a.68.68 0 0 0-.675.686c0 .38.302.687.675.687h40.317a.68.68 0 0 0 .675-.687.68.68 0 0 0-.675-.686h-.765v-1.872h-1.35V26.66l1.44.26-.09-13.178.347.279zM20.788 3.187h1.53v1.687L20.788 6.1V3.187zm-2.88 6.968L28.64 1.557a.753.753 0 0 1 .947 0l10.73 8.598v1.913L29.53 3.426a.666.666 0 0 0-.835 0l-10.786 8.642v-1.913zm-7.987 6.311a.753.753 0 0 1 .947 0l7.13 5.714v1.913l-7.186-5.758a.666.666 0 0 0-.835 0L1.35 25.246v-1.913l8.57-6.867zM2.79 25.84l7.604-6.092L18 25.84v8.188h-2.97v-8.1a.68.68 0 0 0-.675-.686h-7.92a.68.68 0 0 0-.674.687v8.099H2.79V25.84zm4.32 3.704h6.569v1.555h-6.57v-1.555zm6.569-1.373h-6.57v-1.556h6.57v1.556zm-6.57 4.301h6.57v1.556h-6.57v-1.556zm31.768 1.556H19.348V12.662l9.765-7.823 9.764 7.823v21.366z" />
        <path d="M30.913 20.758h4.32a.68.68 0 0 0 .674-.686v-5.857a.68.68 0 0 0-.675-.687h-4.32a.68.68 0 0 0-.674.687v5.857c0 .379.302.686.675.686zm.674-1.373v-2.288h2.97v2.288h-2.97zm2.97-4.484v.824h-2.97V14.9h2.97zM27.313 13.528h-4.32a.68.68 0 0 0-.675.687v5.857c0 .379.302.686.675.686h4.32a.68.68 0 0 0 .675-.686v-5.857a.68.68 0 0 0-.675-.687zm-.675 1.373v.824h-2.97V14.9h2.97zm-2.97 4.484v-2.288h2.97v2.288h-2.97z" />
        <g>
          <path d="M49.489 29.155l-.78-.557-.594-1.741a.772.772 0 0 0-.668-.534l-8.233-.574-2.435-1.817a5.019 5.019 0 0 0-4.241-.864l-1.798.453a19.97 19.97 0 0 0-5.49 2.31.715.715 0 0 0-.073-.004h-2.052l-.694-.737a.733.733 0 0 0-1.08 0 .849.849 0 0 0 0 1.148l.917.975a.742.742 0 0 0 .54.238h.147c-.447.363-.88.745-1.297 1.147a.836.836 0 0 0-.252.602v.671l-.493 1.059a.854.854 0 0 0-.08.36c0 1.008.772 1.828 1.72 1.828h2.77c.337 1.323 1.476 2.299 2.826 2.299s2.488-.976 2.825-2.3h9.44c.337 1.324 1.476 2.3 2.826 2.3s2.488-.976 2.825-2.3h1.897c.767 0 1.448-.547 1.656-1.332l.338-1.28a1.262 1.262 0 0 0-.467-1.35zm-1.343 2.19a.194.194 0 0 1-.183.149h-1.898c-.337-1.323-1.476-2.3-2.825-2.3-1.35 0-2.489.977-2.826 2.3h-9.44c-.337-1.323-1.476-2.3-2.825-2.3-1.35 0-2.489.977-2.826 2.3h-2.77a.188.188 0 0 1-.157-.087l.46-.985a.854.854 0 0 0 .079-.36v-.497c2.331-2.163 5.145-3.704 8.158-4.463l1.798-.454a3.562 3.562 0 0 1 3.01.614l2.61 1.947c.115.086.25.136.39.146l7.947.554.508 1.492a.807.807 0 0 0 .293.398l.763.544-.266 1.002zm-3.508.96c0 .82-.627 1.487-1.398 1.487-.772 0-1.4-.667-1.4-1.486 0-.82.628-1.487 1.4-1.487.77 0 1.398.667 1.398 1.487zm-15.09 0c0 .82-.628 1.487-1.4 1.487-.77 0-1.398-.667-1.398-1.486 0-.82.627-1.487 1.399-1.487.77 0 1.398.667 1.398 1.487z" />
        </g>
      </g>
    </svg>
    )
  </MuiSvgIcon>
);

export default House;
