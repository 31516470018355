import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import { SnackbarProvider } from "notistack";
import React from "react";
import { useClearCache } from "react-clear-cache";
import { Provider } from "react-redux";
import { Store } from "redux";
import { connect } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import chroma from "chroma-js";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import Root from "./components/layout/Root";
import DeeplinkHandlerV2 from "./components/ui/DeeplinkHandlerV2";
import IdleTimerHandler from "./components/ui/IdleTimerHandler";
import Notifier from "./components/ui/Notifier";
import NavigationContainer from "./containers/NavigationContainer";
import Routes from "./routes";
import { ApplicationState } from "./store";
import { theme } from "./styles/theme";
import { sizes, ThemeProvider } from "./utils/styledComponents";
import { get, isEmpty } from "lodash";
// @ts-ignore
import ProductFruits from "react-product-fruits";
import { Colors, ColorVariants, Organisation } from "./store/organisation";
import { brandPrimaryColors, brandSecondaryColors } from "./styles/defaults";
import { AuthenticatedUser } from "./store/auth";

// Any additional component props go here.
interface OwnProps {
  store: Store<ApplicationState>;
  history: History;
}
interface PropsFromState {
  user: AuthenticatedUser;
  organisation: Organisation;
}

interface ProductFruitsProps {
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  signUpAt: string;
  role: string;
  props?: {
    orgId: string;
    orgFeedType: string;
    subscriptionPlan?: string;
  };
}

// Create an intersection type of the component props and our Redux props.
type AllProps = OwnProps & PropsFromState;

const Main: React.FunctionComponent<AllProps> = (props) => {
  const { store, history } = props;
  const persistor = persistStore(store);
  const [userInfo, setUserInfo] = React.useState<ProductFruitsProps>();
  const primaryColor = brandPrimaryColors.orange;
  const secondaryColor = brandPrimaryColors.lightblue;
  const errorColor = brandSecondaryColors.red;
  const textColor = brandPrimaryColors.white;
  const [orgTheme, setTheme] = React.useState<ColorVariants>({
    primary: {
      main: primaryColor,
      light: chroma(primaryColor).luminance(5000).hex(),
      dark: chroma(primaryColor).brighten(1).hex(),
    },
    secondary: {
      main: secondaryColor,
      light: chroma(secondaryColor).darken(1).hex(),
      dark: chroma(secondaryColor).darken(2).hex(),
    },
    error: {
      main: errorColor,
      light: chroma(errorColor).darken(1).hex(),
      dark: errorColor,
    },
    textColor: {
      main: textColor,
      light: chroma(textColor).darken(1).hex(),
      dark: chroma(textColor).darken(2).hex(),
    },
  });

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  if (!isLatestVersion) {
    emptyCacheStorage(); // hard reload if the version is not the same as the current build
  }

  // TODO: Use theme with responsiveFontSizes() to make font sizes responsive
  const muiTheme = isEmpty(orgTheme)
    ? {}
    : createMuiTheme({
        transitions: {
          duration: {
            shortest: 100,
            shorter: 200,
            short: 300,
            standard: 400,
            complex: 500,
            enteringScreen: 600,
            leavingScreen: 700,
          },

          easing: {
            easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
            easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
            easeIn: "cubic-bezier(0.4, 0, 1, 1)",
            sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
          },
        },

        breakpoints: {
          values: sizes,
        },
        typography: {
          fontFamily: ["'MuseoSans-300'", "Helvetica", "sans-serif"].join(","),
          fontSize: 14,
          h1: {
            fontFamily: "'MuseoSans-900', Helvetica, sans-serif",
            fontSize: 30,
            fontWeight: 900,
            lineHeight: "32px",
          },
          h2: {
            fontFamily: "'MuseoSans-300', Helvetica, sans-serif",
            fontWeight: 300,
            fontSize: 24,
            lineHeight: "32px",
            color: orgTheme.secondary.main || "#4C4C4E",
          },
          h3: {
            fontSize: 20,
            fontFamily: "MuseoSans-700, Helvetica, sans-serif",
            lineHeight: "26px",
            color: orgTheme.primary.main,
          },
          h4: {
            fontSize: 16,
            fontWeight: 600,
            lineHeight: "24px",
          },
          h5: {
            fontSize: 14,
            fontWeight: 600,
            lineHeight: "22px",
          },
          h6: {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: "20px",
          },
          body1: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "22px",
          },
          body2: {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "20px",
          },
          button: {
            fontSize: 14,
            fontWeight: 600,
            lineHeight: "22px",
          },
          caption: {
            fontSize: 20,
            fontWeight: 700,
            fontFamily: "MuseoSans-700, Helvetica, sans-serif",
            lineHeight: 35,
            color: orgTheme.primary.main || "#4C4C4E",
          },
          overline: {
            fontSize: 10,
            fontWeight: 400,
            lineHeight: "18px",
          },
        },

        palette: {
          primary: {
            main: orgTheme.primary.main,
            light: orgTheme.primary.light,
            dark: orgTheme.primary.dark,
          },
          secondary: {
            main: orgTheme.secondary.main,
            light: orgTheme.secondary.light,
            dark: orgTheme.secondary.dark,
          },
          grey: {
            100: "#F0F0F0",
            200: "#B0B1B3",
            300: "#868788",
            400: "#4C4C4E",
          },
          error: {
            main: errorColor,
            light: orgTheme.error.light,
            dark: orgTheme.error.dark,
          },
        },

        overrides: {
          MuiButton: {
            root: {
              color: orgTheme.primary.main,
              borderRadius: 25,
              padding: "0px 20px",
              boxShadow: "none",
              border: "none",
              height: 60,
              width: "auto",
              // maxWidth: 315,
              textTransform: "capitalize",
              fontSize: 16,
              "&:hover": {
                backgroundColor: `${orgTheme.primary.light}`,
                // This could be a color from the organisation theme
                color: "#fff",
              },
            },

            sizeSmall: {
              height: "unset",
            },

            // Contained
            contained: {
              backgroundColor: `${orgTheme.primary.main}`,
              boxShadow: "none",
              "&:hover": {
                backgroundColor: `${orgTheme.primary.light}`,
              },

              "&:disabled": {
                color: "inherit",
                backgroundColor: "rgb(240, 240, 240)",
              },
            },
            containedPrimary: {
              color: orgTheme.primary.main,
              border: `1px solid ${orgTheme.primary.main}`,

              "&:hover": {
                backgroundColor: orgTheme.primary.light,
              },
            },
            containedSecondary: {
              color: orgTheme.secondary.main,

              "&:hover": {
                backgroundColor: orgTheme.primary.light,
              },
            },

            // Outlined
            outlined: {
              border: `1px solid ${orgTheme.primary.main}`,
              color: orgTheme.primary.main,
              padding: "0px 20px",
              fontFamily: "'MuseoSans-300', Helvetica, sans-serif",
              "&:hover": {
                backgroundColor: `${orgTheme.primary.main}`,
                // This could be a color from the organisation theme
                color: "#fff",
              },

              "&:disabled": {
                color: orgTheme.primary.main,

                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                },
              },
            },
            outlinedPrimary: {
              color: orgTheme.primary.main,
              border: `1px solid ${orgTheme.primary.main}`,
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },

              "&:disabled": {
                color: orgTheme.primary.main,

                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                },
              },
            },
            outlinedSecondary: {
              color: orgTheme.secondary.main,

              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            },

            // Text
            text: {
              color: orgTheme.primary.main,
              textTransform: "none",
              fontSize: 16,
              fontWeight: 500,
              fontFamily: "'MuseoSans-300', Helvetica, sans-serif",
              border: 0,
              "&:hover": {
                boxSizing: "border-box",
                color: orgTheme.primary.light,
                backgroundColor: "transparent",
              },
            },
            textPrimary: {
              color: orgTheme.primary.main,
              border: 0,
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },

              "&:disabled": {
                color: orgTheme.primary.main,
              },

              "&:disabled:hover": {
                backgroundColor: "rgba(0, 0, 0, 0)",
              },
            },
            textSecondary: {
              color: orgTheme.secondary.main,
              border: 0,
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },

              "&:disabled": {
                color: orgTheme.secondary.main,

                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0)",
                },
              },
            },
            //KG
            colorInherit: {
              color: "rgb(76, 76, 78)",
              backgroundColor: "rgb(240, 240, 240)",
              border: 0,
              "&:hover": {
                background: "rgba(176, 177, 179, 0.5)",
                color: "rgb(255, 255, 255)",
              },
              "&:disabled": {
                color: theme.colors.grey2,
              },
            },
          },
          MuiInputAdornment: {
            positionEnd: {
              color: theme.colors.grey4,
            },
          },

          MuiSwitch: {
            root: {
              width: 45,
              height: 24,
              padding: 0,
              margin: 8,
              "&$checked": {
                color: "#fff",
              }
            },
            thumb: {
              width: 20,
              height: 20,
              boxShadow: "none",
              backgroundColor: "#fff",
            },
            switchBase: {
              padding: 2,
              "&$checked": {
                transform: "translateX(20px)",
                "& + $track": {
                  backgroundColor: "#3E3F3A",
                  opacity: 1,
                  border: "none",
                },
                "&$focusVisible $thumb": {
                  backgroundColor: "#fff",
                  border: "6px solid #fff",
                },
              },

              "&$disabled": {
                "& + $track": {
                  backgroundColor: "rgb(76, 76, 78)",
                  opacity: 1,
                  border: "none",
                },
              },
            },
            track: {
              borderRadius: 26 / 2,
              border: "none",
              backgroundColor: "#8A8A8A",
              opacity: 1,
              transition: "background-color 0.2s ease-out",
            },

            colorSecondary: {
              "&$checked": {
                color: orgTheme.primary.main,
              },

              "&$disabled": {
                color: "#4C4C4E",
                // red track
                "& + $track": {
                  backgroundColor: "#000",
                  opacity: 0.12,
                  border: "none",
                },
              },

              "&$checked + $track": {
                backgroundColor: orgTheme.primary.main,
              },
            },
          },

          MuiIconButton: {
            root: {
              flex: "0 0 auto",
              color: "rgba(0, 0, 0, 0.54)",
              padding: 3,
              overflow: "visible",
              fontSize: "1.5rem",
              textAlign: "center",
              transition:
                "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              borderRadius: "50%",
            },
          },

          MuiChip: {
            root: {
              backgroundColor: "transparent",
              border: `${theme.colors.grey2} 2px solid`,
              color: "#000",
              "&:hover": {
                backgroundColor: `${theme.colors.grey2}`,
                border: `${theme.colors.grey2} 2px solid`,
                "& $deleteIcon": {
                  color: "#fff",
                },

                "& $avatar": {
                  color: "#fff",
                },

                "& $icon": {
                  color: "#fff",
                },
              },

              "&:disabled": {
                backgroundColor: theme.colors.grey2,
              },

              "&:disabled:hover": {
                backgroundColor: theme.colors.grey2,
              },

              "&:disabled $deleteIcon": {
                color: "#fff",
              },

              "&:disabled $avatar": {
                color: "#fff",
              },

              "&:disabled $icon": {
                color: "#fff",
              },
            },
          },

          MuiCheckbox: {
            colorPrimary: {
              "&$checked": {
                color: orgTheme.primary.main,
              },
            },
          },

          MuiInput: {
            underline: {
              "&:before": {
                borderBottom: `1px solid ${theme.colors.grey4}`,
              },
              "&:hover:not($disabled):not($focused):not($error):before": {
                borderBottom: `2px solid ${theme.colors.grey4}`,
              },
            },

            input: {
              color: theme.colors.grey4,
            },
          },
          MuiInputBase: {
            input: {
              margin: "0px 2px !important",
            },
          },
          MuiExpansionPanelSummary: {
            root: {
              "&$expanded": {
                minHeight: 56,
              },

              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",

                "&$expanded": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },

                "&$focused": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              },
            },
          },
          MuiExpansionPanelDetails: {
            root: {
              padding: "0 0 0 0",
            },
          },
          MuiExpansionPanel: {
            root: {
              "&:before": {
                display: "none",
              },
              "&$expanded": {
                margin: "0 0 0 0",
              },
            },
            rounded: {
              borderRadius: 0,
            },
          },

          MuiTypography: {
            root: {
              color: theme.colors.grey4,

              "&$colorPrimary": {
                color: theme.colors.grey4,

                "&$colorTextSecondary": {
                  color: theme.colors.grey4,
                },
              },
            },
          },

          MuiToolbar: {},

          MuiLinearProgress: {
            root: {
              backgroundColor: orgTheme.primary.light,
            },
            colorPrimary: {
              backgroundColor: orgTheme.primary.main,
            },
            barColorPrimary: {
              backgroundColor: orgTheme.primary.main,
            },
          },

          MuiLink: {
            root: {
              color: orgTheme.primary.main,
              "&:hover": {
                color: orgTheme.primary.dark,

                "& $deleteIcon": {
                  color: "#fff",

                  "&:hover": {
                    color: "#fff",
                  },

                  "&:focus": {
                    color: "#fff",
                  },

                  "&:active": {
                    color: "#fff",
                  },
                },
              },
            },
          },

          MuiFormLabel: {
            root: {
              fontSize: "1rem",
            },
          },
          MuiFormHelperText: {
            root: {
              color: errorColor,
              margin: 0,
              fontSize: "0.75rem",
            },
          },
          MuiTab: {
            root: {
              color: theme.colors.white,
              background: orgTheme.secondary.main || theme.colors.lightblue,
            },
          },
          MuiTabs: {
            root: {
              color: theme.colors.white,
              background: orgTheme.secondary.main || theme.colors.lightblue,
            },
          },

          MuiCircularProgress: {
            colorPrimary: {
              color: orgTheme.primary.main,

              "&$colorSecondary": {
                color: orgTheme.primary.main,
              },
            },
          },
        },
      });

  React.useEffect(() => {
    const _data = store.getState();
    const authenticatedUser = get(props, "user") || get(_data, "auth.user");
    const organisation = get(props, "organisation");
    const member = get(organisation, "members", []).find(
      (_member: any) =>
        get(_member, "user._id") === get(authenticatedUser, "_id")
    );

    const organisationColors = get(organisation, "settings.colors");
    const isOrganisationWhiteLabelEnabled =
      get(organisationColors, "whiteLabelPlatform.active") || false;

    let colors = {} as Omit<Colors, "whiteLabel" | "whiteLabelPlatform">;

    if (isOrganisationWhiteLabelEnabled) {
      colors = {
        primaryColor: get(
          organisationColors,
          "primaryColor",
          theme.colors.orange
        ),
        secondaryColor: get(
          organisationColors,
          "secondaryColor",
          theme.colors.lightblue
        ),
        buttonTextColor: get(organisationColors, "buttonTextColor", "#fff"),
      };
    } else {
      colors = {
        primaryColor,
        secondaryColor,
        buttonTextColor: textColor,
      };
    }

    const shadeObj: ColorVariants = {
      primary: {
        main: colors.primaryColor,
        light: chroma(colors.primaryColor).darken(1).hex(),
        dark: chroma(colors.primaryColor).darken(2).hex(),
      },
      secondary: {
        main: colors.secondaryColor,
        light: chroma(colors.secondaryColor).darken(1).hex(),
        dark: chroma(colors.secondaryColor).darken(2).hex(),
      },
      error: {
        main: errorColor,
        light: chroma(errorColor).darken(1).hex(),
        dark: errorColor,
      },
      textColor: {
        main: colors.buttonTextColor,
        light: chroma(colors.buttonTextColor).darken(1).hex(),
        dark: chroma(colors.buttonTextColor).darken(2).hex(),
      },
    };
    setTheme(shadeObj);

    if (
      !isEmpty(authenticatedUser) &&
      !isEmpty(organisation) &&
      isEmpty(userInfo)
    ) {
      setUserInfo({
        username: get(authenticatedUser, "_id"),
        email: get(authenticatedUser, "email"),
        firstname: get(authenticatedUser, "firstName"),
        lastname: get(authenticatedUser, "lastName"),
        role: get(member, "role") || "user",
        props: {
          orgId: get(organisation, "_id"),
          orgFeedType: get(organisation, "settings.syndicator.type", ""),
          subscriptionPlan: get(
            organisation,
            "subscription.product.name",
            undefined
          ),
        },
        signUpAt: get(authenticatedUser, "created"),
      });
    }
  }, [
    userInfo,
    props,
    store,
    setUserInfo,
    errorColor,
    primaryColor,
    secondaryColor,
    textColor,
  ]);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <ConnectedRouter history={history}>
          <MuiThemeProvider theme={muiTheme}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={3}>
                <Root>
                  <NavigationContainer history={history}>
                    {/* <DeeplinkHandler /> */}
                    <DeeplinkHandlerV2 />
                    <Notifier />
                    {!isEmpty(userInfo) && (
                      <ProductFruits
                        projectCode={process.env.REACT_APP_PRODUCT_FRUITS}
                        language="en"
                        {...userInfo}
                      />
                    )}
                    <Routes />
                  </NavigationContainer>
                  <IdleTimerHandler />
                </Root>
              </SnackbarProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

const mapStateToProps = ({
  auth,
  organisation,
}: ApplicationState): PropsFromState => {
  const selectedOrg = get(
    organisation,
    "subOrganisation",
    get(organisation, "organisation")
  );

  return {
    user: auth.user!,
    organisation: selectedOrg!,
  };
};
export default connect<PropsFromState, {}, OwnProps, ApplicationState>(
  mapStateToProps
)(Main);
