import React from "react";

import MuiSvgIcon from "@material-ui/core/SvgIcon";

import { IconProps } from "../icons";

const FlowFuelIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => {
  return (
    <MuiSvgIcon {...props}>
      <svg
        width={35}
        height={35}
        viewBox="0 0 60 60"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>{"Group 22"}</title>
        <g fill="none" fillRule="evenodd">
          <g fill="#7BB5D3">
            <circle cx={20} cy={20} r={16} />
            <circle opacity={0.2} cx={20} cy={20} r={20} />
          </g>
          <g transform="translate(8.5 9)" fillRule="nonzero">
            <circle fill="#D3E4ED" cx={11.25} cy={11.25} r={10.879} />
            <path
              d="M11.25.742c5.803 0 10.493 4.705 10.493 10.493 0 5.803-4.705 10.493-10.493 10.493-5.803 0-10.493-4.705-10.493-10.493S5.447.742 11.25.742m0-.742C5.046 0 0 5.046 0 11.25S5.046 22.5 11.25 22.5 22.5 17.454 22.5 11.25 17.454 0 11.25 0z"
              fill="#FFF"
            />
            <circle fill="#A2C6D9" cx={11.25} cy={11.25} r={8.623} />
            <circle fill="#D3E4ED" cx={11.25} cy={11.25} r={5.818} />
            <circle fill="#A2C6D9" cx={11.25} cy={11.25} r={2.998} />
            <g>
              <path
                d="M19.606.668c.044.846.089 1.662.133 2.523.045-.045.075-.06.09-.09.34-.326.667-.667 1.009-1.008.03-.03.044-.06.074-.075.074-.044.148-.044.208.015.06.06.074.134.015.208l-.09.09-1.083 1.083c.86.044 1.677.089 2.523.133-.03.03-.044.06-.06.074a666.528 666.528 0 01-3.25 3.236c-.044.044-.118.074-.178.06-.668-.03-1.335-.075-2.003-.105-.09 0-.164.015-.223.09l-4.779 4.778c-.044.045-.089.09-.133.12-.149.118-.372.118-.505-.016a.384.384 0 01-.03-.504c.03-.03.06-.06.074-.09l4.824-4.823c.06-.06.089-.119.074-.208a93.221 93.221 0 01-.104-2.033c0-.045.015-.119.06-.148a675.725 675.725 0 003.25-3.266.724.724 0 00.104-.044z"
                opacity={0.35}
                fill="#000"
              />
              <path
                d="M19.235.282c.044.846.089 1.662.133 2.523.045-.044.075-.06.09-.089.34-.326.667-.668 1.009-1.01.03-.029.044-.059.074-.073.074-.045.148-.045.208.014.059.06.074.134.015.208l-.09.09-1.083 1.083c.86.044 1.677.089 2.523.133-.03.03-.044.06-.06.074a666.528 666.528 0 01-3.25 3.236c-.044.045-.118.074-.178.06-.668-.03-1.335-.075-2.003-.105-.09 0-.164.015-.223.09l-4.779 4.779c-.044.044-.089.089-.134.118-.148.119-.37.119-.504-.015a.384.384 0 01-.03-.504c.03-.03.06-.06.074-.09l4.824-4.823c.06-.06.089-.119.074-.208a93.221 93.221 0 01-.104-2.033c0-.044.015-.119.06-.148A675.725 675.725 0 0019.13.327a.135.135 0 00.104-.045z"
                fill="#FFF"
              />
            </g>
          </g>
        </g>
      </svg>
    </MuiSvgIcon>
  );
};

export default FlowFuelIcon;
