import { Reducer } from "redux";

import { addGTMDataLayer } from "../../utils/GoogleTagManager";
import {
  createChat,
  getChats,
  getProfile,
  inviteTenant,
  setChatUnreadCount,
  updateChat,
} from "./routines";
import { Chat, ChatState } from "./types";

const initialState: ChatState = {
  chats: [],
  errors: undefined,
  listLoading: false,
  totalRecords: 0,
};

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger
    case createChat.TRIGGER: {
      addAnalytics(action.payload, "tenant_initiated_message");
      return { ...state, listLoading: true, errors: undefined };
    }

    case inviteTenant.TRIGGER: {
      return { ...state, errors: undefined };
    }

    case getChats.TRIGGER: {
      return { ...state, listLoading: true, errors: undefined };
    }

    case getProfile.TRIGGER: {
      return { ...state, errors: undefined };
    }

    case setChatUnreadCount.TRIGGER: {
      return { ...state, unreadCount: 0 };
    }

    // Success
    case createChat.SUCCESS: {
      addAnalytics(action.payload, "tenant_sent_message");
      return {
        ...state,
      };
    }

    case getChats.SUCCESS: {
      const { chats, count, isExport } = action.payload;
      if (isExport) {
        return { ...state };
      }
      return {
        ...state,
        chats,
        totalRecords: count,
        listLoading: false,
      };
    }

    case updateChat.SUCCESS: {
      const chats = [
        ...state.chats.filter((chat: any) => chat._id !== action.payload._id),
        action.payload,
      ];

      return {
        ...state,
        chats,
      };
    }

    case setChatUnreadCount.SUCCESS: {
      return {
        ...state,
        unreadCount: action.payload,
      };
    }

    case getProfile.SUCCESS: {
      return { ...state, profile: action.payload };
    }

    // Failure
    case createChat.FAILURE: {
      return { ...state, errors: action.payload };
    }
    case getChats.FAILURE: {
      return { ...state, errors: action.payload, listLoading: false };
    }

    // Fullfill
    case createChat.FULFILL: {
      return {
        ...state,
      };
    }

    case setChatUnreadCount.FULFILL: {
      return {
        ...state,
      };
    }

    case getChats.FULFILL: {
      return { ...state, listLoading: false };
    }

    default: {
      return state;
    }
  }
};

const addAnalytics = (chat: Chat, eventType: string) => {
  addGTMDataLayer({
    data: { chat },
    event: eventType,
    page: "chat-messages",
  });
};

export { reducer as chatReducer };
