import { Reducer } from 'redux';

import { addLease } from './routines';
import { LeaseState } from './types';

const initialState: LeaseState = {
  errors: undefined,
  leaseInfo: {},
  loading: false
};

const reducer: Reducer<LeaseState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger

    case addLease.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }
    // Success
    case addLease.SUCCESS: {
      return { ...state, leaseInfo: action.payload };
    }

    // Failure
    case addLease.FAILURE: {
      return { ...state, errors: action.payload };
    }

    // Fulfill
    case addLease.FULFILL: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
};

export { reducer as leaseReducer };
