import React from "react";

import MuiSvgIcon from "@material-ui/core/SvgIcon";

import { IconProps } from "../icons";

const FlowBrandIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => {
  return (
    <MuiSvgIcon {...props}>
      <svg
        width={35}
        height={35}
        viewBox="0 0 60 60"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <title>{"Group 26"}</title>
        <g fill="none" fillRule="evenodd">
          <g>
            <circle fill="#7BB5D3" cx={20} cy={20} r={16} />
            <circle fill="#7BB5D3" opacity={0.2} cx={20} cy={20} r={20} />
            <path
              d="M20 30.673a.285.285 0 01-.156-.043c-10.727-5.457-9.437-18.49-9.423-18.617a.333.333 0 01.255-.299l9.239-2.359c.028-.014.057-.014.085-.014a.18.18 0 01.085.014l9.24 2.36c.14.042.24.156.254.298.014.128 1.29 13.146-9.423 18.617a.285.285 0 01-.156.043z"
              fill="#BAD5E3"
              fillRule="nonzero"
            />
            <path
              d="M20 9.696l9.24 2.36S30.627 24.903 20 30.318c-10.628-5.415-9.24-18.262-9.24-18.262L20 9.696M20 9c-.057 0-.113.014-.17.028l-9.24 2.345a.695.695 0 00-.523.597c-.015.142-.355 3.383.651 7.348 1.332 5.287 4.436 9.309 8.97 11.61.1.058.213.072.312.072.1 0 .213-.028.312-.071 4.534-2.317 7.638-6.324 8.97-11.611 1.006-3.965.666-7.206.651-7.348a.695.695 0 00-.524-.597L20.17 9.014C20.113 9 20.057 9 20 9z"
              fill="#FFF"
              fillRule="nonzero"
            />
            <path
              d="M20 30.318V9.696l-9.24 2.36S9.373 24.903 20 30.318z"
              fill="#AECDDE"
              fillRule="nonzero"
            />
            <path
              d="M20 30.318V9.696l9.24 2.36S30.627 24.903 20 30.318z"
              fill="#CDE1EB"
              fillRule="nonzero"
            />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M22.89 12.795l-7.325 7.773 5.767-.966-3.613 6.609 6.73-8.456-5.398.895z"
            />
          </g>
        </g>
      </svg>
    </MuiSvgIcon>
  );
};

export default FlowBrandIcon;
