import {
  brandPrimaryColors,
  brandSecondaryColors,
  greyColorOptions,
} from "./defaults";

export interface FlowThemeInterface {
  colors: {
    white: string;
    green: string;
    lightOrange: string;
    lightblue: string;
    urbanblue: string;
    orange: string;
    grey1: string;
    grey2: string;
    grey3: string;
    grey4: string;
    grey: typeof greyColorOptions.grey;
    black: string;
    red: string;
    pageBg: string;
  };

  fonts: {
    MS300: string;
    MS500: string;
    MS700: string;
    MS900: string;
  };

  fontWeights: {
    MS300: number;
    MS500: number;
    MS700: number;
    MS900: number;
  };

  fontSizes: {
    buttonText: string;
    formSectionHeading: string;
    formSectionDescription: string;
    itemText: string;
    title: string;
    heading: string;
    navbarItem: string;
    navbarHeading: string;
    subtitle: string;
  };

  heights: {
    navbar: string;
  };

  widths: {
    sidebar: string;
    sidebarExpanded: string;
  };

  charts: {
    colors: {
      cartesionGrid: string;
    };
    fontSizes: {
      axis: string;
    };
  };
}

export const theme: FlowThemeInterface = {
  charts: {
    colors: {
      cartesionGrid: "#D8D8D8",
    },
    fontSizes: {
      axis: "10px",
    },
  },
  colors: {
    black: brandSecondaryColors.black,
    grey1: brandSecondaryColors.grey1,
    grey2: brandSecondaryColors.grey2,
    grey3: brandSecondaryColors.grey3,
    grey4: brandSecondaryColors.grey4,
    grey: greyColorOptions.grey,
    green: brandSecondaryColors.green,
    lightOrange: brandPrimaryColors.lightOrange,
    lightblue: brandPrimaryColors.lightblue,
    orange: brandPrimaryColors.orange,
    pageBg: "#f9f9f9",
    red: brandSecondaryColors.red,
    urbanblue: brandPrimaryColors.urbanblue,
    white: brandPrimaryColors.white,
  },
  fontSizes: {
    buttonText: "12px",
    formSectionDescription: "14px",
    formSectionHeading: "20px",
    heading: "16px",
    itemText: "10px",
    navbarHeading: "20px",
    navbarItem: "14px",
    subtitle: "24px",
    title: "30px",
  },
  fontWeights: {
    MS300: 300,
    MS500: 500,
    MS700: 700,
    MS900: 900,
  },
  fonts: {
    MS300: "'MuseoSans-300', Helvetica, sans-serif",
    MS500: "'MuseoSans-500', Helvetica, sans-serif",
    MS700: "'MuseoSans-700', Helvetica, sans-serif",
    MS900: "'MuseoSans-900', Helvetica, sans-serif",
  },
  heights: {
    navbar: "60px",
  },
  widths: {
    sidebar: "60px",
    sidebarExpanded: "215px",
  },
};
