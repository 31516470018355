import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../../store";
import IdleTimer from "react-idle-timer";
import { logout } from "../../store/auth";
import styled from "../../utils/styledComponents";
import IdleTimeOutDialog from "../dialogs/IdleTimeOutDialog";

interface OwnProps {}
interface PropsFromState {
  authenticated: boolean;
}

interface PropsFromDispatch {
  logout: typeof logout;
}

type AllProps = PropsFromDispatch & PropsFromState & OwnProps;

const Wrapper = styled.div``;

const IdleTimerHandler: React.FunctionComponent<AllProps> = (props) => {
  const [timeout] = useState<number>(1000 * 60 * 60); // 60 minutes
  const [showModal, setShowModal] = useState<boolean>(false);
  const { authenticated, logout } = props;
  const idleTimer: any = useRef(null);
  const sessionTimeOutRef: any = useRef(null);
  const closeModal = () => {
    setShowModal(false);
  };
  const onContinueSession = () => {
    closeModal();
    clearTimeout(sessionTimeOutRef.current);
  };
  const handleLogout = () => {
    closeModal();
    clearTimeout(sessionTimeOutRef.current);
    logout();
  };

  const onIdle = () => {
    setShowModal(true);
    sessionTimeOutRef.current = setTimeout(handleLogout, 1000 * 60); // 60 seconds
  };

  return authenticated ? (
    <Wrapper>
      <IdleTimer
        ref={idleTimer}
        crossTab={{
          emitOnAllTabs: true,
        }}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={timeout}
      />
      <IdleTimeOutDialog
        onContinue={onContinueSession}
        onLogout={handleLogout}
        isOpen={showModal}
      />
    </Wrapper>
  ) : null;
};

const mapStateToProps = ({ auth }: ApplicationState) => ({
  authenticated: auth.authenticated,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({ logout }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdleTimerHandler) as any;
// usage reference
// https://blog.bitsrc.io/how-to-implement-idle-timeout-in-react-830d21c32942
// https://www.youtube.com/watch?v=_wgCPufTAYI
