// import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import callApi from '../../utils/callApi';
import { errorHandler } from '../../utils/errorHandler';
// import { logEvent } from '../analytics';
import { enqueueSnackbar } from '../notifications';
import { addLease } from './routines';

function* handleAddLease(action: AnyAction) {
  try {
    yield put(addLease.request());
    const res = yield call(callApi, "post", `/property/selfSign`, {
      data: action.payload
    });

    yield put(addLease.success(res.data));
    yield put(
      enqueueSnackbar({
        message: "Lease has been added",
        options: {
          variant: "success"
        }
      })
    );
  } catch (err) {
    if (err.response) {
      yield put(addLease.failure(errorHandler(err.response)));
    } else {
      yield put(addLease.failure("An uknown error occured"));
    }
    yield put(
      enqueueSnackbar({
        message: errorHandler(err.response),
        options: {
          variant: "error"
        }
      })
    );
  } finally {
    yield put(addLease.fulfill());
  }
}

function* addLeaseWatcher() {
  yield takeEvery(addLease.TRIGGER, handleAddLease);
}

export function* leaseSaga() {
  yield all([fork(addLeaseWatcher)]);
}
