import { get, isEmpty } from "lodash";
import { Property } from "../store/properties";

import { Currency } from "../store/defaults";
import { LocaleSettingsProps } from "../store/organisation";
import { DEFAULT_LOCALE_SETTINGS } from "./defaultLocaleSettings";
import { getUnitPrice } from "./units.helper";
import { isNumeric } from "./general";

const phone = require("phone");
const StringMask = require("string-mask");
export const getDistanceUnit = (localeSettings: any, fullword = false) => {
  const measurementSystem = get(localeSettings, "measurementSystem", "metric");
  return measurementSystem === "metric"
    ? fullword
      ? "kilometer"
      : "km"
    : fullword
      ? "mile"
      : "mi";
};

export const getMaxRadius = (localeSettings: any) => {
  const measurementSystem = get(localeSettings, "measurementSystem", "metric");
  return measurementSystem === "metric" ? 20 : 15;
};
export const getSquaredMeasurement = (
  localeSettings: LocaleSettingsProps,
  fullword = false
) => {
  const measurementSystem = get(localeSettings, "measurementSystem", "metric");
  return measurementSystem === "metric"
    ? fullword
      ? "square meters (sqm)"
      : "m²"
    : fullword
      ? "square feet (sqft)"
      : "sqft";
};
const formatIndiaLocValue = (price: number) => {
  let money = price;
  let displaySign = "";
  const minValue = 100000;
  const maxValue = 10000000;
  if (price >= minValue && price < maxValue) {
    money = price / minValue;
    displaySign = " L";
  } else if (price >= maxValue) {
    money = price / maxValue;
    displaySign = " Cr";
  }
  const returnValue = {
    value: money,
    displaySign,
  };
  return returnValue;
};
export const formatAmount = (
  amount: number,
  localeSettings: LocaleSettingsProps,
  isForSale = true,
  isResidential = true
): string => {
  const currencyCode = localeSettings.currency.code;
  const countryCode = localeSettings.country.code;
  if (amount === 0) {
    return "POA";
  }

  const isIndianRupeeLocale = countryCode === "in" && currencyCode === "INR";
  let displaySettings;
  if (isIndianRupeeLocale) {
    displaySettings = formatIndiaLocValue(amount);
    amount = get(displaySettings, "value", amount);
  }

  const locale = `${localeSettings.language.code
    }-${localeSettings.country.code.toUpperCase()}`; // "en-US"

  const formatter = new Intl.NumberFormat(locale, {
    currency: currencyCode,
    minimumFractionDigits: 0,
    style: "currency",
  });
  const formattedMoney = formatter
    .format(amount)
    .replace(currencyCode, localeSettings.currency.symbol);

  const measurement = `per ${getSquaredMeasurement(localeSettings)}`;
  const displayedMoney = isIndianRupeeLocale
    ? `${formattedMoney}${get(displaySettings, "displaySign", "")}`
    : formattedMoney;
  return isForSale
    ? displayedMoney
    : `${displayedMoney} ${isResidential ? "pm" : measurement}`;
};

/**
 * derive commercial rental tag
 * @param amount
 * @param grossMonthlyRental
 * @param listingSector
 * @param listingType
 * @param locale
 * @returns tag
 */
const deriveCommercialRentalTag = (
  amount: number,
  grossMonthlyRental: number,
  listingSector: string,
  listingType: string,
  locale: LocaleSettingsProps
): string => {
  const isCommercial = listingSector === "commercial";
  const isRental = listingType === "rent";

  let tag = isRental ? "pm" : "";
  if (isCommercial && isRental) {
    tag = `per ${getSquaredMeasurement(locale)}`;

    if (amount === grossMonthlyRental) {
      tag = "pm";
    }
  }
  return tag;
};

/**
 * Format amount by locale
 * @param localeSettings
 * @param property
 */
export const formatPropertyAmountByLocale = (
  property: Property,
  localeSettings: LocaleSettingsProps
): string => {
  const units = property.units || [];
  const minUnit = units.find(
    (unit) => unit.rentalAmount === property.minRentalAmount
  );

  const displayPrice = get(minUnit, "displayPrice");
  if (displayPrice) {
    return displayPrice;
  }

  let amount = Number(getUnitPrice(minUnit) || property.minRentalAmount || 0);
  const isOnAuction = get(property, "listingType", "sale") === "auction";
  const currencyCode = localeSettings.currency.code;

  if (
    amount === 0 ||
    (isOnAuction && currencyCode === "AUD") ||
    !isNumeric(amount)
  ) {
    return "POA";
  }

  const countryCode = localeSettings.country.code;
  const listingSector = get(property, "listingSector", "residential");
  const listingType = get(property, "listingType", "sale");
  const isForSale = get(property, "listingType") !== "rent";
  const grossMonthlyRental = Number(
    get(property, "syndicator.doc.gross_monthly_rental", 0)
  );

  const isIndianRupeeLocale = countryCode === "in" && currencyCode === "INR";
  let displaySettings;
  if (isIndianRupeeLocale) {
    displaySettings = formatIndiaLocValue(amount);
    amount = get(displaySettings, "value", amount);
  }

  const formatter = new Intl.NumberFormat(countryCode, {
    currency: currencyCode,
    minimumFractionDigits: hasDecimalPlaces(amount) ? 2 : 0,
    style: "currency",
  });

  const formattedMoney = formatter
    .format(amount)
    .replace(currencyCode, localeSettings.currency.symbol);

  const displayedMoney = isIndianRupeeLocale
    ? `${formattedMoney}${get(displaySettings, "displaySign", "")}`
    : formattedMoney;

  return isForSale
    ? displayedMoney
    : `${displayedMoney} ${deriveCommercialRentalTag(
      amount,
      grossMonthlyRental,
      listingSector,
      listingType,
      localeSettings
    )}`;
};

export const formatAmountValueOnly = (
  amount: number,
  localeSettings: LocaleSettingsProps,
  customZeroValue?: string
): string => {
  if (customZeroValue && amount === 0) {
    return customZeroValue;
  }

  const countryCode = localeSettings.country.code;
  const formatter = new Intl.NumberFormat(countryCode, {
    currency: localeSettings.currency.code,
    minimumFractionDigits: hasDecimalPlaces(amount) ? 2 : 0,
    style: "currency",
  });
  const money = formatter
    .format(amount)
    .replace(localeSettings.currency.code, localeSettings.currency.symbol);

  return money;
};

export const formatBillingAmountValueOnly = (
  amount: number,
  billingCurrency: Currency
): string => {
  try {
    const billingCurrencyCode = get(
      billingCurrency,
      "code",
      DEFAULT_LOCALE_SETTINGS.currency.code
    );

    const billingCurrencySymbol = get(
      billingCurrency,
      "symbol",
      DEFAULT_LOCALE_SETTINGS.currency.symbol
    );

    const countryCode = billingCurrencyCode.substring(0, 2);

    const formatter = new Intl.NumberFormat(countryCode, {
      currency: get(
        billingCurrency,
        "code",
        DEFAULT_LOCALE_SETTINGS.currency.code
      ),
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: "currency",
    });
    const money = formatter
      .format(amount)
      .replace(billingCurrencyCode, billingCurrencySymbol);

    return money;
  } catch (error) {
    return `${amount}`;
  }
};

export const formatNumber = (
  value: number,
  localeSettings: LocaleSettingsProps
): string => {
  if (value === 0) {
    return value.toString();
  }
  const countryCode = localeSettings.country.code;
  const formatter = new Intl.NumberFormat(countryCode, {
    currency: localeSettings.currency.code,
    minimumFractionDigits: 0,
  });
  const formattedValue = formatter.format(value).trim();

  return formattedValue;
};

export const calculatePercentage = (value: number, total: number): number => {
  return total >= value && total > 0 && value > 0 ? (value / total) * 100 : 0;
};

/**
 * @param {string} contactNumber
 * @param {string} countryCode
 * @returns
 */
export const formatPhone = (
  contactNumber: string,
  countryCode = "za",
  localeSettings?: LocaleSettingsProps
): string => {
  const formattedPhone = phone(contactNumber, countryCode.toUpperCase());
  if (!isEmpty(localeSettings) && isEmpty(formattedPhone[0]))
    return contactNumber;
  return !isEmpty(localeSettings)
    ? maskPhone(formattedPhone[0], localeSettings!)
    : formattedPhone[0];
};

export const maskPhone = (
  contactNumber: string,
  localeSettings: LocaleSettingsProps
): string => {
  const maskWithoutCode = get(
    localeSettings,
    "country.phone.mask",
    ".. ... ...."
  );
  const dialCode = get(localeSettings, "country.phone.dialCode", "+27");
  const finalMask = `${dialCode[0].padEnd(
    dialCode.length,
    "0"
  )} ${maskWithoutCode.replaceAll(".", "0")}`;
  const formatter = new StringMask(finalMask, { reverse: true });
  return formatter.apply(contactNumber);
};

/**
 * Check if a number has decimal places
 * @param number
 * @returns boolean
 */
const hasDecimalPlaces = (number: number): boolean => {
  return number % 1 !== 0;
};
