import React from "react";

import MuiSvgIcon from "@material-ui/core/SvgIcon";

import { IconProps } from ".";

const DeleteIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon>
    <svg>
      <g fillRule="nonzero" stroke="#4c4c4e" strokeWidth={1.5} fill="none">
        <path d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default DeleteIcon;
