import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { Action, AnyAction, combineReducers, Dispatch } from "redux";
import { routinePromiseWatcherSaga } from "redux-saga-routines";
import { all, fork } from "redux-saga/effects";

import { accountReducer, accountSaga, AccountState } from "./account";
import { behaviourReducer, behaviourSaga, BehaviourState } from "./activities";
import { analyticsSaga } from "./analytics";
import { authReducer, authSaga, AuthState } from "./auth";
import { chatReducer, chatSaga, ChatState } from "./chat";
import { deeplinkReducer, deeplinkSaga, DeeplinkState } from "./deeplink";
import {
  flowExtrasReducer,
  flowExtrasSaga,
  FlowExtrasState,
} from "./flowExtras";
import { inviteSaga, invitesReducer, InvitesState } from "./invites";
import { leaseReducer, leaseSaga, LeaseState } from "./leases";
import {
  navigationReducer,
  navigationSaga,
  NavigationState,
} from "./navigation";
import {
  notificationReducer,
  notificationSaga,
  NotificationState,
} from "./notifications";
import {
  organisationReducer,
  organisationSaga,
  OrganisationState,
} from "./organisation";
import {
  propertiesReducer,
  propertiesSaga,
  PropertiesState,
} from "./properties";
import { referralReducer, referralSaga, ReferralState } from "./referral";
import { reportReducer, reportSaga, ReportState } from "./reports";
import { campaignsSaga, CampaignsState, campasignsReducer } from "./rewards";
import { tenantsReducer, tenantsSaga, TenantsState } from "./tenants";
import { ticketsReducer, ticketsSaga, TicketsState } from "./tickets";
import { unitsReducer, unitsSaga, UnitsState } from "./units";
import { defaultSaga, DafaultState, defaultReducer } from "./defaults";
import { socialsSaga, SocialState, socialReducer } from "./socials";
import { subscriptionReducer, subscriptionsSaga, SubscriptionState } from "./subscriptions";
import { BillingState, billingReducer, billingSaga } from "./billing";
import { setAutoFreeze } from "immer";

// TODO: this is bad practice, but the state is already mutable that enabling this may cause further issues
setAutoFreeze(false);

// The top-level state object
export interface ApplicationState {
  auth: AuthState;
  activities: BehaviourState;
  flowExtras: FlowExtrasState;
  organisation: OrganisationState;
  chats: ChatState;
  defaults: DafaultState;
  invites: InvitesState;
  navigation: NavigationState;
  notifications: NotificationState;
  properties: PropertiesState;
  referral: ReferralState;
  reward: CampaignsState;
  router: RouterState;
  tenants: TenantsState;
  tickets: TicketsState;
  account: AccountState;
  reports: ReportState;
  deeplink: DeeplinkState;
  leases: LeaseState;
  units: UnitsState;
  social: SocialState;
  subscription: SubscriptionState;
  billing: BillingState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const rootReducer = (history: History) =>
  combineReducers<ApplicationState>({
    account: accountReducer,
    activities: behaviourReducer,
    auth: authReducer,
    chats: chatReducer,
    defaults: defaultReducer,
    deeplink: deeplinkReducer,
    flowExtras: flowExtrasReducer,
    invites: invitesReducer,
    leases: leaseReducer,
    navigation: navigationReducer,
    notifications: notificationReducer,
    organisation: organisationReducer,
    properties: propertiesReducer,
    referral: referralReducer,
    reports: reportReducer,
    reward: campasignsReducer,
    router: connectRouter(history),
    tenants: tenantsReducer,
    tickets: ticketsReducer,
    units: unitsReducer,
    social: socialReducer,
    subscription: subscriptionReducer,
    billing: billingReducer
  });

// Here we use `redux-saga` to trigger actions asynchronously. `redux-saga` uses something called a
// "generator function", which you can read about here:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
export function* rootSaga() {
  yield all([
    fork(analyticsSaga),
    fork(authSaga),
    fork(behaviourSaga),
    fork(chatSaga),
    fork(defaultSaga),
    fork(deeplinkSaga),
    fork(navigationSaga),
    fork(notificationSaga),
    fork(propertiesSaga),
    fork(referralSaga),
    fork(campaignsSaga),
    fork(tenantsSaga),
    fork(ticketsSaga),
    fork(inviteSaga),
    fork(accountSaga),
    fork(reportSaga),
    fork(routinePromiseWatcherSaga),
    fork(leaseSaga),
    fork(unitsSaga),
    fork(flowExtrasSaga),
    fork(organisationSaga),
    fork(socialsSaga),
    fork(subscriptionsSaga),
    fork(billingSaga)
  ]);
}
