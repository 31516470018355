import { Reducer } from 'redux';

import { enqueueSnackbar, removeSnackbar } from './routines';
import { NotificationState } from './types';

const initialState: NotificationState = {
  notifications: []
};

const reducer: Reducer<NotificationState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger

    // Success
    case enqueueSnackbar.SUCCESS: {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.payload
          }
        ]
      };
    }
    case removeSnackbar.SUCCESS: {
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification: any) => notification.key !== action.payload
        )
      };
    }

    // Failure

    // Fullfill

    default: {
      return state;
    }
  }
};

export { reducer as notificationReducer };
