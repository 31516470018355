// import { push } from 'connected-react-router';
import { isEmpty } from "lodash";
import { AnyAction } from "redux";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { ApplicationState } from "../";
import callApi from "../../utils/callApi";
import { errorHandler } from "../../utils/errorHandler";
import { alertErrors } from "../../utils/general";
import { logEvent } from "../analytics";
import { enqueueSnackbar } from "../notifications";
import {
  createAccount,
  createBilling,
  createSubscriptionCheckoutId,
  createSubscriptionCheckoutWithExistingCard,
  deleteCard,
  fetchAccount,
  fetchAccountSubscriptions,
  fetchBilling,
  fetchSubscriptionCheckoutResult,
  fetchLoggedInUserCampaignsSubscriptions,
  fetchLoggedInUserPaymentFeeds,
  updateAccount,
  updateAccountBillingDetails,
  updateBilling,
  updateCard,
  updateMessage,
  addUserMasterCampaignSubscriptionToList,
} from "./routines";
import { Action } from 'redux-actions';
import { Subscription } from '../subscriptions';

export const getAccount = ({ account }: ApplicationState) => account.account;

function* handleCreateSubscriptionCheckoutWithExistingCard(
  action: AnyAction
): any {
  const { accountId, checkoutId, cardId } = action.payload;
  try {
    yield put(createSubscriptionCheckoutWithExistingCard.request());
    const checkoutWithExistingCardResponse = yield call(
      callApi,
      "get",
      `/accounts/${accountId}/checkouts/${checkoutId}/cards/${cardId}`
    );
    const res = checkoutWithExistingCardResponse.data;
    switch (res.status) {
      case "SUCCESS": {
        yield put(
          createSubscriptionCheckoutWithExistingCard.success(res.account)
        );
        yield put(
          enqueueSnackbar({
            message: "Payment successful",
            options: {
              variant: "success",
            },
          })
        );
        yield put(
          updateMessage.success({
            text: "Hooray! You’re on your way to making your tenants better and retaining them for longer. Next step: invite all of your tenants 😎",
            type: "success",
          })
        );
        return res.account;
      }
      default: {
        yield put(
          enqueueSnackbar({
            message: "Payment failed",
            options: {
              variant: "error",
            },
          })
        );
        yield put(
          updateMessage.success({
            text: "There was an error processing your transaction",
            type: "failure",
          })
        );
        break;
      }
    }
  } catch (err) {
    if (err) {
      yield put(
        createSubscriptionCheckoutWithExistingCard.failure(errorHandler(err))
      );
    }
  } finally {
    yield put(createSubscriptionCheckoutWithExistingCard.fulfill());
  }
}

function* handleCreateSubscriptionCheckoutId(action: AnyAction): any {
  const { accountId } = action.payload;
  try {
    yield put(createSubscriptionCheckoutId.request());
    const checkoutIdResponse = yield call(
      callApi,
      "post",
      `/accounts/${accountId}/checkouts`,
      {
        data: action.payload,
      }
    );
    const res = checkoutIdResponse.data;
    yield put(createSubscriptionCheckoutId.success(res.account));
    return res.account;
  } catch (err) {
    if (err) {
      yield put(createSubscriptionCheckoutId.failure(errorHandler(err)));
    }
  } finally {
    yield put(createSubscriptionCheckoutId.fulfill());
  }
}

function* handleFetchSubscriptionCheckoutResult(action: AnyAction): any {
  const { checkoutId, accountId } = action.payload;
  try {
    yield put(fetchSubscriptionCheckoutResult.request());
    const checkoutResult = yield call(
      callApi,
      "get",
      `/accounts/${accountId}/checkouts/${checkoutId}`
    );
    const checkoutResultResponse = checkoutResult.data;
    yield put(fetchSubscriptionCheckoutResult.success(checkoutResultResponse));
    yield put(
      enqueueSnackbar({
        message: "Payment successful",
        options: {
          variant: "success",
        },
      })
    );
    yield put(
      logEvent({
        event: "landlord_purchased_subscription",
      })
    );
    yield put(
      logEvent({
        event: "landlord_added_card",
      })
    );
    yield put(
      logEvent({
        data: {
          bracketNo: checkoutResultResponse.bracketNo,
          checkoutId: checkoutResultResponse.checkouts[0].reference,
          paymentInterval:
            checkoutResultResponse.checkouts[0].product.paymentInterval,
          price: checkoutResultResponse.checkouts[0].product.amount,
          productDescription:
            checkoutResultResponse.checkouts[0].product.description,
          productName: checkoutResultResponse.checkouts[0].product.name,
        },
        event: "landlord_purchased_subscription",
      })
    );
    return checkoutResultResponse;
  } catch (err) {
    if (err) {
      yield put(fetchSubscriptionCheckoutResult.failure(errorHandler(err)));
    }
  } finally {
    yield put(fetchSubscriptionCheckoutResult.fulfill());
  }
}

function* handleDeleteCard(action: AnyAction): any {
  const { cardId, accountId } = action.payload;
  try {
    yield put(deleteCard.request());
    const response = yield call(
      callApi,
      "delete",
      `/accounts/${accountId}/cards/${cardId}`
    );
    const deleteCardResponse = response.data;
    yield put(deleteCard.success(deleteCardResponse));
    yield put(
      enqueueSnackbar({
        message: "Card deleted successfully",
        options: {
          variant: "success",
        },
      })
    );
    yield put(
      logEvent({
        event: "landlord_deleted_card",
      })
    );
    // return deleteCardResponse;
  } catch (error) {
    const err: any = Object.assign({}, error);
    if (err.response.status === 400) {
      yield put(deleteCard.failure(err.response.data.message));
    } else if (err.response) {
      yield put(deleteCard.failure(errorHandler(err.response)));
    } else {
      yield put(deleteCard.failure("An unknown error occured."));
    }
  } finally {
    yield put(deleteCard.fulfill());
  }
}

function* handleUpdateCard(action: AnyAction): any {
  const { cardId, accountId, data } = action.payload;
  try {
    yield put(updateCard.request());
    const response = yield call(
      callApi,
      "put",
      `/accounts/${accountId}/cards/${cardId}`,
      {
        data,
      }
    );
    const updateCardResponse = response.data;
    yield put(updateCard.success(updateCardResponse));
    yield put(
      enqueueSnackbar({
        message: "Card updated successfully",
        options: {
          variant: "success",
        },
      })
    );
    yield put(updateMessage.success(undefined));
    yield put(
      logEvent({
        event: "landlord_updated_card",
      })
    );
    // return updateCardResponse;
  } catch (err) {
    if (err) {
      const response = (err as any).response;
      if (response) {
        yield put(updateCard.failure(errorHandler(response)));
      } else {
        yield put(updateCard.failure("An unknown error occured."));
      }
    }
  } finally {
    yield put(updateCard.fulfill());
  }
}

function* handleFetchAccountSubscriptions(_action: AnyAction): any {
  try {
    yield put(fetchAccountSubscriptions.request());

    const accountSubscriptionsResponse = yield call(
      callApi,
      "get",
      "/accounts/context"
    );

    const subscriptions = accountSubscriptionsResponse.data;

    yield put(fetchAccountSubscriptions.success(subscriptions));
  } catch (err) {
    if (err) {
      yield put(fetchAccountSubscriptions.failure(errorHandler(err)));
    }
  } finally {
    yield put(fetchAccountSubscriptions.fulfill());
  }
}

function* handleUpdateAccount(action: AnyAction): any {
  const { accountId, data } = action.payload;
  try {
    yield put(updateAccount.request());

    const accountResponse = yield call(
      callApi,
      "put",
      `/accounts/${accountId}`,
      {
        data,
      }
    );

    const updatedAccount = accountResponse.data;

    yield put(
      enqueueSnackbar({
        message: "Account updated successfully ",
        options: { variant: "success" },
      })
    );
    yield put(updateMessage.success(undefined));
    yield put(updateAccount.success(updatedAccount));
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(updateAccount.failure(errorHandler(response)));
    } else {
      yield put(updateAccount.failure("An unknown error occured."));
    }
  } finally {
    yield put(updateAccount.fulfill());
  }
}

function* handleFetchAccount(action: AnyAction): any {
  try {
    const { id } = action.payload;

    yield put(fetchAccount.request());

    const res = yield call(callApi, "get", `/accounts/${id}`);

    yield put(fetchAccount.success(res.data));
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(fetchAccount.failure(errorHandler(response)));
    } else {
      yield put(fetchAccount.failure("An unknown error occured."));
    }
  } finally {
    yield put(fetchAccount.fulfill());
  }
}

/**
 * Billing v3
 *
 * @param {AnyAction} action
 */
function* handleFetchBilling(action: AnyAction): any {
  try {
    const { userId } = action.payload;

    yield put(fetchBilling.request());

    const res = yield call(callApi, "get", `/v3/billing-info/${userId}`);

    yield put(fetchBilling.success(res.data));
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(fetchBilling.failure(errorHandler(response)));
    } else {
      yield put(fetchBilling.failure("An unknown error occured."));
    }
  } finally {
    yield put(fetchBilling.fulfill());
  }
}

/**
 *
 * create billing
 * @param {AnyAction} action
 */
function* handleCreateBilling(action: AnyAction): any {
  try {
    const { data } = action.payload;

    yield put(createBilling.request());

    const res = yield call(callApi, "post", `/v3/billing-info`, {
      data,
    });

    yield put(
      enqueueSnackbar({
        message: "Address added successfully",
        options: {
          variant: "success",
        },
      })
    );

    yield put(createBilling.success(res.data));
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(createBilling.failure(errorHandler(response)));
    } else {
      yield put(createBilling.failure("An unknown error occured."));
    }
  } finally {
    yield put(createBilling.fulfill());
  }
}

/**
 * Update Billing
 *
 * @param {AnyAction} action
 */
function* handleUpdateBilling(action: AnyAction): any {
  try {
    const { data, userId } = action.payload;
    yield put(updateBilling.request());

    const res = yield call(
      callApi,
      "put",
      `/v3/billing-info/${userId}/update`,
      {
        data,
      }
    );

    yield put(updateBilling.success(res.data));
    yield put(
      enqueueSnackbar({
        message: "Address updated successfully",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(updateBilling.failure(errorHandler(response)));
    } else {
      yield put(updateBilling.failure("An unknown error occured."));
    }
  } finally {
    yield put(updateBilling.fulfill());
  }
}

/**
 * Update Billing
 *
 * @param {AnyAction} action
 */
function* handleUpdateAccountBillingDetails(action: AnyAction): any {
  try {
    const { data, userId } = action.payload;
    yield put(updateBilling.request());

    const res = yield call(
      callApi,
      "put",
      `/v3/billing-info/${userId}/account-details`,
      {
        data,
      }
    );

    yield put(updateAccountBillingDetails.success(res.data));
    yield put(
      enqueueSnackbar({
        message: "Account updated successfully",
        options: {
          variant: "success",
        },
      })
    );
    return res.data;
  } catch (err) {
    const response = (err as any).response;
    if (response) {
      yield put(updateAccountBillingDetails.failure(errorHandler(response)));
    } else {
      yield put(
        updateAccountBillingDetails.failure("An unknown error occured.")
      );
    }
  } finally {
    yield put(updateAccountBillingDetails.fulfill());
  }
}

function* handleFetchLoggedInUserCampaignsSubscriptions(_: any): any {
  try {
    yield put(fetchLoggedInUserCampaignsSubscriptions.request());

    const response = yield call(
      callApi,
      "get",
      `/users/campaigns/subscriptions`
    );

    yield put(fetchLoggedInUserCampaignsSubscriptions.success(response.data));
  } catch (error) {
    const response = (error as any).response;
    if (response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler(response),
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(fetchLoggedInUserCampaignsSubscriptions.fulfill());
  }
}
/**
 * Update Billing
 *
 * @param {AnyAction} action
 */
function* handleFetchLoggedInUserPaymentFeeds(action: AnyAction): any {
  try {
    const { cursor } = action.payload;
    yield put(fetchLoggedInUserPaymentFeeds.request());

    const url = `/payments/feeds/`; //look for the next cursor on the state
    const response = yield call(
      callApi,
      "get",
      !isEmpty(cursor) ? `${url}?cursor=${cursor}` : url
    );

    yield put(fetchLoggedInUserPaymentFeeds.success(response.data));
  } catch (error) {
    const response = (error as any).response;
    if (response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler(response),
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(fetchLoggedInUserPaymentFeeds.fulfill());
  }
}

function* handleAddUserMasterCampaignSubscriptionToList(action: Action<Subscription>): any {
  try {
    yield put(addUserMasterCampaignSubscriptionToList.success(action.payload));
  } catch (error) {
    yield put(addUserMasterCampaignSubscriptionToList.failure(errorHandler(error)));
  } finally {
    yield put(addUserMasterCampaignSubscriptionToList.fulfill());
  }
}

// Error handlers
function* handleAccountError(action: AnyAction): any {
  yield all(alertErrors(action.payload));
}

function* accountErrorWatcher(): any {
  yield takeEvery(
    [
      createAccount.FAILURE,
      fetchAccount.FAILURE,
      updateAccount.FAILURE,
      updateCard.FAILURE,
      deleteCard.FAILURE,
      fetchAccountSubscriptions.FAILURE,
      createSubscriptionCheckoutId.FAILURE,
      createSubscriptionCheckoutWithExistingCard.FAILURE,
      fetchBilling.FAILURE,
      createBilling.FAILURE,
      fetchLoggedInUserCampaignsSubscriptions.FAILURE,
      updateAccountBillingDetails.FAILURE,
      fetchLoggedInUserPaymentFeeds.FAILURE,
      updateBilling.FAILURE,
    ],
    handleAccountError
  );
}

function* updateAccountRequestWatcher(): any {
  yield takeEvery(updateAccount.TRIGGER, handleUpdateAccount);
}

function* updateCardRequestWatcher(): any {
  yield takeEvery(updateCard.TRIGGER, handleUpdateCard);
}

function* fetchAccountWatcher(): any {
  yield takeEvery(fetchAccount.TRIGGER, handleFetchAccount);
}

function* fetchAccountSubscriptionsWatcher(): any {
  yield takeEvery(
    fetchAccountSubscriptions.TRIGGER,
    handleFetchAccountSubscriptions
  );
}

function* createSubscriptionCheckoutIdWatcher(): any {
  yield takeEvery(
    createSubscriptionCheckoutId.TRIGGER,
    handleCreateSubscriptionCheckoutId
  );
}

function* createSubscriptionCheckoutWithExistingCardWatcher(): any {
  yield takeEvery(
    createSubscriptionCheckoutWithExistingCard.TRIGGER,
    handleCreateSubscriptionCheckoutWithExistingCard
  );
}

function* fetchSubscriptionCheckoutResultWatcher(): any {
  yield takeEvery(
    fetchSubscriptionCheckoutResult.TRIGGER,
    handleFetchSubscriptionCheckoutResult
  );
}

function* deleteCardWatcher(): any {
  yield takeEvery(deleteCard.TRIGGER, handleDeleteCard);
}

function* fetchBillingWatcher(): any {
  yield takeEvery(fetchBilling.TRIGGER, handleFetchBilling);
}

function* createBillingWatcher(): any {
  yield takeEvery(createBilling.TRIGGER, handleCreateBilling);
}

function* updateBillingWatcher(): any {
  yield takeEvery(updateBilling.TRIGGER, handleUpdateBilling);
}

function* updateAccountBillingDetailsWatcher(): any {
  yield takeEvery(
    updateAccountBillingDetails.TRIGGER,
    handleUpdateAccountBillingDetails
  );
}

function* handleFetchLoggedInUserCampaignsSubscriptionsWatcher(): any {
  yield takeEvery(
    fetchLoggedInUserCampaignsSubscriptions.TRIGGER,
    handleFetchLoggedInUserCampaignsSubscriptions
  );
}

function* handleFetchLoggedInUserPaymentFeedsWatcher(): any {
  yield takeEvery(
    fetchLoggedInUserPaymentFeeds.TRIGGER,
    handleFetchLoggedInUserPaymentFeeds
  );
}

function* addUserMasterCampaignSubscriptionToListWatcher(): any {
  yield takeEvery(
    addUserMasterCampaignSubscriptionToList.TRIGGER,
    handleAddUserMasterCampaignSubscriptionToList
  );
}

export function* accountSaga(): any {
  yield all([
    fork(fetchAccountWatcher),
    fork(accountErrorWatcher),
    fork(updateAccountRequestWatcher),
    fork(fetchAccountSubscriptionsWatcher),
    fork(createSubscriptionCheckoutIdWatcher),
    fork(fetchSubscriptionCheckoutResultWatcher),
    fork(deleteCardWatcher),
    fork(updateCardRequestWatcher),
    fork(createSubscriptionCheckoutWithExistingCardWatcher),
    fork(fetchBillingWatcher),
    fork(createBillingWatcher),
    fork(updateBillingWatcher),
    fork(updateAccountBillingDetailsWatcher),
    fork(handleFetchLoggedInUserCampaignsSubscriptionsWatcher),
    fork(handleFetchLoggedInUserPaymentFeedsWatcher),
    fork(addUserMasterCampaignSubscriptionToListWatcher),
  ]);
}
