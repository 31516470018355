import { get, isEmpty } from "lodash";
import React from "react";
import { IsMobile } from "../../utils/responsive";
import styled from "../../utils/styledComponents";
import {
  AdAccountIcon,
  CatalogueIcon,
  FacebookAudienceIcon,
  FacebookPixelIcon,
  FacebookSocialIcon,
  FlowBrandIcon,
  FlowFuelIcon,
  InstagramIcon,
} from "../icons";
import TitleWithProps from "../typography/TitleWithProps";
import { AvatarProps } from "@material-ui/core/Avatar";
import { Avatar } from "@material-ui/core";

// eslint-disable-next-line
const ProfileSection = styled.div<{
  isMobile: boolean;
  padding: string;
  mobilePadding: string;
}>`
  && {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${({ isMobile, padding, mobilePadding }) =>
      isMobile ? mobilePadding : padding};
  }
`;
const MiniTitle = styled.span<{ fontSize: number; marginBottom: string; paddingBottom?: string }>`
  && {
    color: ${({ theme }) => theme.colors.grey3};
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    font-size: ${({ fontSize }) => fontSize}px;
    margin-bottom: ${({ marginBottom }) => marginBottom};
    ${({ paddingBottom }) => paddingBottom && `padding-bottom:${paddingBottom}`};
    line-height: 1.15;
  }
`;

const MiniDescription = styled.span<{ fontSize: number }>`
  && {
    color: ${({ theme }) => theme.colors.grey4};
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-weight: ${({ theme }) => theme.fontWeights.MS700};
    font-size: ${({ fontSize }) => fontSize}px;
  }
`;
// eslint-disable-next-line
const ProfileInfo = styled.div<{
  isMini: boolean;
  isMobile: boolean;
  forceNoWrap: boolean;
  forceWrap: boolean;
}>`
  && {
    text-overflow: ellipsis;
    white-space: ${({ isMini, isMobile, forceNoWrap, forceWrap }) => {
      if (forceWrap) {
        return "wrap";
      }
      if (forceNoWrap) {
        return "nowrap";
      }
      return isMini && !isMobile ? "nowrap" : "wrap";
    }};
    display: flex;
    flex-direction: column;
    padding-right: 7px;
    width: 100%;
  }
`;

const Description = styled.span<{ fontSize: number }>`
  && {
    color: ${({ theme }) => theme.colors.grey3};
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    margin-top: 2.5px;
    font-size: ${({ fontSize }) => fontSize}px;
    line-height: 1.3;
  }
`;

const Section = styled.div`
  && {
    margin-right: 3px;
    display: flex;
    align-items: center;
  }
`;

const StyledInstagramIcon = styled(InstagramIcon)`
  && {
    font-size: 60px;
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.grey3};
  }
`;

const StyledFacebookIcon = styled(FacebookSocialIcon)`
  && {
    font-size: 60px;
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.grey3};
  }
`;
const StyledFacebookPixelIcon = styled(FacebookPixelIcon)`
  && {
    font-size: 60px;
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.grey3};
  }
`;

const StyledFacebookAudiencesIcon = styled(FacebookAudienceIcon)`
  && {
    font-size: 60px;
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.grey3};
  }
`;

const StyledAdAccountIcon = styled(AdAccountIcon)`
  && {
    font-size: 60px;
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.grey3};
  }
`;

const StyledCatalogueIcon = styled(CatalogueIcon)`
  && {
    font-size: 60px;
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.grey3};
  }
`;

const StyledFlowFuelIcon = styled(FlowFuelIcon)`
  && {
    font-size: 60px;
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.grey3};
  }
`;
const StyledFlowBrandIcon = styled(FlowBrandIcon)`
  && {
    font-size: 60px;
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.grey3};
  }
`;

const LargeAvatar = styled(Avatar as React.FunctionComponent<AvatarProps>)`
  && {
    width: 52px;
    height: 52px;

    background: "#d8d8d8";
    margin-right: 10px;
    &:hover {
      cursor: pointer;
    }

    svg {
      font-size: 50px;
    }
  }
`;

interface OwnProps {
  title: any;
  description?: any;
  isMini?: boolean;
  icon?: string;
  forceNoWrap?: boolean;
  forceWrap?: boolean;
  titleColor?: string;
  styles?: any; //object
  facebookPageId?: string;
}

const TitleDescriptionWidget: React.FunctionComponent<OwnProps> = (props) => {
  const {
    title,
    description,
    icon,
    isMini,
    forceNoWrap,
    forceWrap,
    facebookPageId,
  } = props;
  const padding = get(props, "styles.padding", "5px 10px");
  const mobilePadding = get(props, "styles.mobilePadding", "0px");
  const descriptionFontSize = get(props, "styles.description.fontSize", 12);
  const titleFontSize = get(props, "styles.title.fontSize", isMini ? 12 : 0);
  const titleColor = get(props, "titleColor", "default");
  const marginBottom =  get(props, "styles.marginBottom", "5px");
  const paddingBottom = get(props, "styles.paddingBottom");

  let IconValue = undefined;
  if (!isEmpty(icon)) {
    switch (icon) {
      case "FlowFuel":
        IconValue = <StyledFlowFuelIcon />;
        break;
      case "FlowBrand":
        IconValue = <StyledFlowBrandIcon />;
        break;
      case "AdAccount":
        IconValue = <StyledAdAccountIcon />;
        break;
      case "Facebook":
        IconValue = <StyledFacebookIcon />;
        break;
      case "ProductCatalogue":
        IconValue = <StyledCatalogueIcon />;
        break;
      case "Instagram":
        IconValue = <StyledInstagramIcon />;
        break;
      case "FacebookPixels":
        IconValue = <StyledFacebookPixelIcon />;
        break;
      case "FacebookAudiences":
        IconValue = <StyledFacebookAudiencesIcon />;
        break;
      case "FacebookAvatar":
        IconValue = isEmpty(facebookPageId) ? (
          <StyledFlowBrandIcon />
        ) : (
          <LargeAvatar
            src={`https://graph.facebook.com/${facebookPageId}/picture`}
          />
        );
        break;
      default:
      // code block
    }
  }

  const isMobile = IsMobile();
  return (
    <ProfileSection
      isMobile={isMobile}
      padding={padding}
      mobilePadding={mobilePadding}
    >
      <Section>
        {!isEmpty(IconValue) && IconValue}
        {isMini ? (
          <ProfileInfo
            isMini={true}
            isMobile={isMobile}
            forceNoWrap={forceNoWrap || false}
            forceWrap={forceWrap || false}
          >
            <MiniTitle fontSize={titleFontSize} marginBottom={marginBottom} paddingBottom={paddingBottom}>{title}</MiniTitle>
            {!isEmpty(description) && (
              <MiniDescription fontSize={descriptionFontSize}>
                {description}
              </MiniDescription>
            )}
          </ProfileInfo>
        ) : (
          <ProfileInfo
            isMini={false}
            isMobile={isMobile}
            forceNoWrap={forceNoWrap || false}
            forceWrap={forceWrap || false}
          >
            <TitleWithProps
              fontSize={titleFontSize}
              color={titleColor}
              title={title}
            />
            {!isEmpty(description) && (
              <Description fontSize={descriptionFontSize}>
                {description}
              </Description>
            )}
          </ProfileInfo>
        )}
      </Section>
    </ProfileSection>
  );
};

export default TitleDescriptionWidget;
