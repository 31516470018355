import { Reducer } from 'redux';

import { createInvite, fetchInvites, validateInviteToken } from './routines';
import { InvitesState } from './types';

const initialState: InvitesState = {
  errors: undefined,
  invites: [],
  loading: false,
  userDetails: undefined,
  inviteType: undefined,
  invitedBy: undefined,
};

const reducer: Reducer<InvitesState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger
    case fetchInvites.TRIGGER:
    case createInvite.TRIGGER:
    case validateInviteToken.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }
    // Success
    case fetchInvites.SUCCESS: {
      return { ...state, invites: action.payload };
    }
    case createInvite.SUCCESS: {
      return { ...state };
    }
    case validateInviteToken.SUCCESS: {
      return {
        ...state,
        invitedBy: action.payload.invitedBy,
        userDetails: action.payload.userDetails,
        inviteType: action.payload.type,
      };
    }
    // Failure
    case createInvite.FAILURE: {
      return { ...state, errors: action.payload };
    }
    case validateInviteToken.FAILURE: {
      return { ...state, user: undefined, errors: action.payload };
    }
    case fetchInvites.FAILURE: {
      return { ...state, invites: [], errors: action.payload };
    }
    // Fulfill
    case fetchInvites.FULFILL:
    case createInvite.FULFILL:
    case validateInviteToken.FULFILL: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
};

export { reducer as invitesReducer };
