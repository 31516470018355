import { Reducer } from "redux";
import { fetchBehaviours } from "./routines";
import { BehaviourState } from "./types";

const initialState: BehaviourState = {
  behaviours: [],
  errors: undefined,
  listLoading: false
};

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger
    case fetchBehaviours.TRIGGER: {
      return { ...state, listLoading: true, errors: undefined };
    }

    // Success
    case fetchBehaviours.SUCCESS: {
      return {
        ...state,
        behaviours: action.payload
      };
    }

    // Failure
    case fetchBehaviours.FAILURE: {
      return { ...state, behaviours: [], errors: action.payload };
    }

    // Fullfill
    case fetchBehaviours.FULFILL: {
      return {
        ...state,
        listLoading: false
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as behaviourReducer };
