import * as React from 'react';

import MuiGrid, { GridProps } from '@material-ui/core/Grid';

import styled from '../../utils/styledComponents';

const Grid = styled(MuiGrid as React.FunctionComponent<GridProps>)`
  height: 100%;
`;

const Page: React.FunctionComponent<{}> = ({ children }) => (
  <Grid container={true} direction="row">
    {children}
  </Grid>
);

export default Page;
