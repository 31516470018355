import { SvgIconProps } from "@material-ui/core/SvgIcon";

export { default as PropertiesIcon } from "./Properties";
export { default as AwardIcon } from "./Award";
export { default as AngleLeftIcon } from "./AngleLeftIcon";
export { default as Brain } from "./Brain";
export { default as CheckCircleIcon } from "./CheckCircleIcon";
export { default as CommunicationIcon } from "./Communication";
export { default as TenantsIcon } from "./Tenants";
export { default as DashboardIcon } from "./Dashboard";
export { default as MenuIcon } from "./Menu";
export { default as ViewingsIcon } from "./Viewings";
export { default as TicketsIcon } from "./Tickets";
export { default as ApplicationsIcon } from "./Applications";
export { default as EditPencilIcon } from "./EditPencil";
export { default as DeleteIcon } from "./Delete";
export { default as EditIcon } from "./Edit";
export { default as PreviewIcon } from "./Preview";
export { default as ExclamationIcon } from "./Exclamation";
export { default as MaintenanceIcon } from "./Maintenance";
export { default as ProfileIcon } from "./Profile";
export { default as GardenCottageIcon } from "./GardenCottage";
export { default as HouseIcon } from "./House";
export { default as ApartmentIcon } from "./Apartment";
export { default as Annotation } from "./Annotation";
export { default as TownhouseIcon } from "./Townhouse";
export { default as LogoutIcon } from "./Logout";
export { default as InfoIcon } from "./Info";
export { default as PartnersIcon } from "./PartnersIcon";
export { default as FacebookIcon } from "./Facebook";
export { default as GoogleIcon } from "./Google";
export { default as StatusIcon } from "./Status";
export { default as CreditsIcon } from "./Credits";
export { default as ManagedTenantsIcon } from "./ManagedTenants";
export { default as SubscriptionIcon } from "./Subscription";
export { default as VISAIcon } from "./VISA";
export { default as MasterCardIcon } from "./MasterCard";
export { default as AmericanExpressIcon } from "./AmericanExpress";
export { default as FlowTenantsIcon } from "./FlowTenants";
export { default as EmailIcon } from "./Email";
export { default as TickIcon } from "./Tick";
export { default as NavPropertiesIcon } from "./NavPropertiesIcon";
export { default as NavContentIcon } from "./NavContentIcon";
export { default as NavSettingsIcon } from "./NavSettingsIcon";
export { default as ActiveTenantIcon } from "./ActiveTenant";
export { default as EnquiryIcon } from "./Enquiry";
export { default as LeaseSentIcon } from "./LeaseSent";
export { default as ArchiveIcon } from "./Archive";
export { default as LockIcon } from "./Lock";
export { default as SuccessTickIcon } from "./SuccessTickIcon";
export { default as ErrorIcon } from "./ErrorIcon";
export { default as FilledTickIcon } from "./FilledTickIcon";
export { default as UpgradeIcon } from "./UpgradeIcon";
export { default as CampaignIcon } from "./CampaignIcon";
export { default as FlowFuelIcon } from "./FlowFuelIcon";
export { default as FlowBrandIcon } from "./FlowBrandIcon";
export { default as CheckIcon } from "./CheckIcon";
export { default as HelpIcon } from "./HelpIcon";
export { default as InstagramIcon } from "./InstagramIcon";
export { default as FacebookSocialIcon } from "./FacebookSocialIcon";
export { default as FacebookAudienceIcon } from "./FacebookAudienceIcon";
export { default as FacebookPixelIcon } from "./FacebookPixelIcon";
export { default as AdAccountIcon } from "./social-media/AdAccountIcon";
export { default as CatalogueIcon } from "./CatalogueIcon";
export { default as AnalyticsIcon } from "./AnalyticsIcon";
export { default as SelectedIcon } from "./SelectedIcon";
export { default as SubscriptionPlansIcon } from "./SubscriptionPlansIcon";
export { default as PackageIcon } from "./PackageIcon";
export { default as BillingIcon } from "./BillingIcon";
export { default as UserHeadset } from "./UserHeadset";
export { default as CloseCircleIcon } from "./CloseCircleIcon";

export interface ColouredIconProps {
    nativeColor?: string
}

export type IconProps = SvgIconProps & ColouredIconProps;
