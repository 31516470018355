import * as React from "react";

import { CircularProgress, Grid } from "@material-ui/core";

import { Body, Page } from "../layout";

import { theme } from "../../styles/theme";

const LoadingPage: React.FunctionComponent<{ embeddedInDialog?: boolean }> = ({
  embeddedInDialog,
}) => {
  return (
    <Page>
      <Body
        embeddedInDialog={embeddedInDialog}
        justify="center"
        alignItems="center"
      >
        <Grid item={true}>
          <CircularProgress style={{ color: theme.colors.grey2 }} />
        </Grid>
      </Body>
    </Page>
  );
};

export default LoadingPage;
