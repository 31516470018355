import produce from "immer";
import { get } from "lodash";
import { Reducer } from "redux";
import { getOrCreatePropAtPath } from "../../utils/general";

import { verifyUserByEmail } from "./";
import {
  authenticate,
  forgotPassword,
  linkOutLogin,
  login,
  loginWithFacebook,
  loginWithGoogle,
  logout,
  patchUserFbSocial,
  register,
  requestOTP,
  resetPassword,
  setPrivilege,
  updateUser,
  uploadProfileImage,
  validateToken,
  verifyOTP,
  checkLoginProvider,
  signInWithProvider,
} from "./routines";
import { AuthState, UserSocial } from "./types";

// Type-safe initialState!
const initialState: AuthState = {
  authenticateLoading: true,
  authenticated: false,
  errors: undefined,
  imageUploadLoading: false,
  privilege: {
    isAdmin: false,
    isAllowed: false,
  },
  loading: false,
  user: undefined,
  userVerification: undefined,
  provider: {
    type: "",
    requestEmail: "",
  },
};

const reducer: Reducer<AuthState> = (state = initialState, action) => {
  switch (action.type) {
    // Triggers
    case forgotPassword.TRIGGER:
    case login.TRIGGER:
    case loginWithFacebook.TRIGGER:
    case loginWithGoogle.TRIGGER:
    case logout.TRIGGER:
    case register.TRIGGER:
    case resetPassword.TRIGGER:
    case updateUser.TRIGGER:
    case validateToken.TRIGGER:
    case verifyOTP.TRIGGER:
    case setPrivilege.TRIGGER:
    case verifyUserByEmail.TRIGGER:
    case requestOTP.TRIGGER:
    case linkOutLogin.TRIGGER:
    case checkLoginProvider.TRIGGER:
    case signInWithProvider.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }

    case authenticate.TRIGGER: {
      return { ...state, authenticateLoading: true, errors: undefined };
    }

    case uploadProfileImage.TRIGGER: {
      return { ...state, imageUploadLoading: true, errors: undefined };
    }

    // Success
    case authenticate.SUCCESS:
    case login.SUCCESS:
    case loginWithFacebook.SUCCESS:
    case loginWithGoogle.SUCCESS:
    case register.SUCCESS:
    case signInWithProvider.SUCCESS: {
      return {
        ...state,
        authenticated: true,
        user: {
          ...action.payload,
          isSuperUser: action.payload.roles.includes("flow-admin"),
        },
        gtmEvent: action.type,
      };
    }

    case linkOutLogin.SUCCESS: {
      const newDerivedState = produce<AuthState>(state, (draft: AuthState) => {
        const userDetails = get(action.payload, "userDetails");
        const userRoles = get(userDetails, "roles") || [];

        draft.authenticated = true;
        draft.user = {
          ...userDetails,
          isSuperUser: userRoles.includes("flow-admin"),
        };
        draft.gtmEvent = action.type;
      });

      return newDerivedState;
    }

    case setPrivilege.SUCCESS: {
      return {
        ...state,
        privilege: action.payload,
      };
    }

    case logout.SUCCESS: {
      return {
        ...state,
        authenticated: false,
        user: undefined,
        provider: {
          type: "",
          requestEmail: "",
        },
      };
    }

    case updateUser.SUCCESS: {
      const newDerivedState = produce<AuthState>(state, (draft: AuthState) => {
        if (draft.user!._id === get(action, "payload._id")) {
          draft.user = {
            ...draft.user,
            ...action.payload,
            isSuperUser: action.payload.roles.includes("flow-admin"),
          };
        }
      });

      return newDerivedState;
    }

    case verifyOTP.SUCCESS: {
      return {
        ...state,
        user: { ...state.user, status: "Verified" },
        errors: undefined,
      };
    }

    case requestOTP.SUCCESS: {
      return {
        ...state,
        errors: undefined,
      };
    }

    case uploadProfileImage.SUCCESS: {
      // check if the user is the same as the auth user
      if (state.user && action.payload._id === state.user._id) {
        return {
          ...state,
          user: {
            ...action.payload,
            isSuperUser: action.payload.roles.includes("flow-admin"),
          },
        };
      } else {
        return {
          ...state,
          errors: undefined,
        };
      }
    }

    case resetPassword.SUCCESS:
    case forgotPassword.SUCCESS:
    case validateToken.SUCCESS: {
      return {
        ...state,
      };
    }

    case verifyUserByEmail.SUCCESS: {
      return {
        ...state,
        userVerification: action.payload,
      };
    }

    case checkLoginProvider.SUCCESS: {
      return {
        ...state,
        loading: false,
        provider: {
          ...action.payload,
          requestEmail: action.payload.requestData.email,
        },
      };
    }

    case patchUserFbSocial.SUCCESS: {
      const newDerivedState = produce<AuthState>(state, (draft: AuthState) => {
        const userFbSocial: UserSocial = getOrCreatePropAtPath(
          draft,
          "user.social"
        );
        Object.assign(userFbSocial, action.payload);
      });

      return newDerivedState;
    }

    case authenticate.FAILURE: {
      return {
        ...state,
      };
    }

    // Failure
    case forgotPassword.FAILURE:
    case login.FAILURE:
    case loginWithFacebook.FAILURE:
    case loginWithGoogle.FAILURE:
    case logout.FAILURE:
    case register.FAILURE:
    case resetPassword.FAILURE:
    case validateToken.FAILURE:
    case verifyUserByEmail.FAILURE:
    case linkOutLogin.FAILURE:
    case checkLoginProvider.FAILURE:
    case signInWithProvider.FAILURE: {
      return {
        ...state,
        authenticated: false,
        errors: action.payload,
        user: undefined,
      };
    }

    case setPrivilege.FAILURE:
    case verifyOTP.FAILURE:
    case requestOTP.FAILURE:
    case uploadProfileImage.FAILURE:
    case updateUser.FAILURE: {
      return {
        ...state,
        errors: action.payload,
      };
    }

    // Fullfill
    case setPrivilege.FULFILL:
    case authenticate.FULFILL:
    case forgotPassword.FULFILL:
    case login.FULFILL:
    case loginWithFacebook.FULFILL:
    case loginWithGoogle.FULFILL:
    case logout.FULFILL:
    case register.FULFILL:
    case resetPassword.FULFILL:
    case updateUser.FULFILL:
    case uploadProfileImage.FULFILL:
    case validateToken.FULFILL:
    case verifyOTP.FULFILL:
    case requestOTP.FULFILL:
    case verifyUserByEmail.FULFILL:
    case linkOutLogin.FULFILL:
    case checkLoginProvider.FULFILL:
    case signInWithProvider.FULFILL: {
      return {
        ...state,
        authenticateLoading: false,
        imageUploadLoading: false,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as authReducer };
