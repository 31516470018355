import clsx from "clsx";
import { get, isEmpty, isFunction, uniqBy } from "lodash";
import React, { HTMLAttributes, ReactElement } from "react";
import AsyncSelect, { Async } from "react-select/async";

import Chip from "@material-ui/core/Chip";
import NoSsr from "@material-ui/core/NoSsr";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField, { BaseTextFieldProps } from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";

import { theme as FlowTheme } from "../../styles/theme";
import StateManager, {
  ControlProps,
  FocusEventHandler,
  MenuProps,
} from "react-select";

// TODO: change this to styled component
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: ({ containerStyles }: any) => ({
      flexGrow: 0.1,
      width: "100%",
      ...containerStyles,
    }),
    input: {
      display: "flex",
      padding: 0,
      height: (props: any) =>
        props.isMulti ? (props.isSmallMulti ? "2em" : "auto") : "2em",
      fontFamily: FlowTheme.fonts.MS500,
      fontWeight: FlowTheme.fontWeights.MS500,
      color: FlowTheme.colors.grey4,
    },
    valueContainer: {
      display: "flex",
      flexWrap: (props: any) =>
        props.isMulti ? (props.isSmallMulti ? "unset" : "wrap") : "inherit",
      overflowX: (props: any) => (props.isSmallMulti ? "scroll" : "hidden"),
      flex: 1,
      alignItems: "center",
      fontFamily: FlowTheme.fonts.MS500,
      fontWeight: FlowTheme.fontWeights.MS500,
      color: FlowTheme.colors.grey4,
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
      height: 20,
      fontFamily: FlowTheme.fonts.MS500,
      fontWeight: FlowTheme.fontWeights.MS500,
      color: "inherit",
    },
    labelSmall: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    noOptionsMessage: {
      textAlign: "left",
      padding: theme.spacing(1, 2),
      fontFamily: FlowTheme.fonts.MS500,
      fontWeight: FlowTheme.fontWeights.MS500,
      color: FlowTheme.colors.grey4,
    },
    singleValue: {
      fontSize: 16,
      width: "inherit",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      fontFamily: FlowTheme.fonts.MS500,
      fontWeight: FlowTheme.fontWeights.MS500,
      color: FlowTheme.colors.grey4,
    },
    paper: {
      position: "absolute",
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      minWidth: 250,
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.2)",
      backgroundColor: "white",
      fontFamily: FlowTheme.fonts.MS500,
      fontWeight: FlowTheme.fontWeights.MS500,
      color: FlowTheme.colors.grey4,
    },
    label: {
      color: "#B0B1B3",
    },
    indicatorsContainer: {
      cursor: "pointer",
      padding: 4,
    },
    customMenuItem: {
      fontFamily: FlowTheme.fonts.MS500,
      fontWeight: FlowTheme.fontWeights.MS500,
      color: FlowTheme.colors.grey4,
      textAlign: "left",
      cursor: "pointer",
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 20,
      "&:hover": {
        backgroundColor: "#E0E0E0",
      },
    },
  })
);

const NoOptionsMessage = (props: any) => {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

type InputComponentProps = Pick<BaseTextFieldProps, "inputRef"> &
  HTMLAttributes<HTMLDivElement>;
const inputComponent = ({ inputRef, ...props }: InputComponentProps) => {
  return <div ref={inputRef} {...props} />;
};
const Control = (props: ControlProps<any, boolean>) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps, disableUnderline },
    getStyles,
  } = props;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        disableUnderline,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          style: getStyles("input", props),
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
};
const Option = (props: any) => {
  // use simple div for performance.
  return (
    <div
      ref={props.innerRef}
      selected={props.isFocused}
      className={props.selectProps.classes.customMenuItem}
      {...props.innerProps}
    >
      {props.children}
    </div>
  );
};
const SingleValue = (props: any) => {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const ValueContainer = (props: any) => {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
};

const MultiValue = (props: any) => {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
      size="small"
      icon={get(props, "selectProps.innerProps.chipIcon") || undefined}
      classes={{ labelSmall: get(props, "selectProps.classes.labelSmall") }}
    />
  );
};
const Menu = ({
  children,
  innerProps,
  getStyles,
  ...props
}: MenuProps<any, boolean>) => {
  return (
    <div
      className={props.selectProps.classes.paper}
      {...innerProps}
      style={getStyles("menu", props)}
    >
      {children}
    </div>
  );
};
export interface OptionType {
  label: string;
  value: string;
}

export interface AsyncAutoCompleteInputProps {
  isMulti: boolean;
  label?: string;
  placeholder?: string;
  defaultValue?: any;
  containerStyles?: object;
  fullWidth?: boolean;
  disableUnderline?: boolean;
  options: OptionType[];
  onSelect: (option: OptionType[]) => void;
  reset: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  hideIndicatorsContainer?: boolean;
  hideMenu?: boolean;
  chipIcon?: ReactElement;
  disabled?: boolean;
  name?: string;
  onBlur?: FocusEventHandler;
  isSmallMulti?: boolean;
}

export type AsyncSelectInstance = Async<OptionType, boolean> & {
  select: StateManager;
};

const assignRef = <T,>(ref: React.Ref<T>, instance: T): void => {
  if (ref) {
    if (isFunction(ref)) {
      ref(instance);
    } else {
      (ref as any).current = instance;
    }
  }
};

const AsyncAutoCompleteInput = React.forwardRef<
  AsyncSelectInstance,
  AsyncAutoCompleteInputProps
>((props, ref) => {
  const {
    isMulti,
    disableUnderline,
    defaultValue,
    label,
    options,
    onSelect,
    placeholder,
    reset,
    isSearchable,
    hideIndicatorsContainer,
    hideMenu,
    chipIcon,
    disabled,
    name,
    onBlur,
    isSmallMulti,
  } = props;
  const isClearable = get(props, "isClearable", true);
  let { containerStyles } = props;
  const classes = useStyles({ isMulti, containerStyles, isSmallMulti });
  containerStyles = !isEmpty(containerStyles) ? containerStyles : {};
  const [multi, setMulti] = React.useState<OptionType[]>(defaultValue);
  const [isSet, setIsset] = React.useState<boolean>(false);
  const selectRef = React.useRef<AsyncSelectInstance>(null);

  const setRef = (asyncSelectInstance: AsyncSelectInstance): void => {
    assignRef(ref, asyncSelectInstance);
    assignRef(selectRef, asyncSelectInstance);
  };

  const optionsWithLabels = options.filter((option) => !isEmpty(option.label));
  const sortedOptions = uniqBy(
    optionsWithLabels,
    (x: OptionType) => x.value
  ).sort((a, b) => {
    const labelA = a.label.toUpperCase(); // ignore upper and lowercase
    const labelB = b.label.toUpperCase(); // ignore upper and lowercase
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    // names must be equal
    return 0;
  });
  const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    SingleValue,
    ValueContainer,
  };
  const handleChangeMulti = (value: any) => {
    setMulti(value);
    setIsset(true);
    onSelect(value);
    return value;
  };
  const onMenuOpen = () => {
    setIsset(true);
  };
  const onMenuClose = () => {
    if (isEmpty(multi)) {
      setIsset(false);
    }
  };
  const onLoad = () => {
    if (reset) {
      setMulti([]);
      onSelect([]);
      setIsset(false);
      if (selectRef.current) {
        selectRef.current.select.setState({ value: null });
      }
    }
  };
  const filterOptions = (inputValue: string) => {
    return sortedOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const onLoadPromiseOptions = (inputValue: string): Promise<any> =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterOptions(inputValue));
      }, 1000);
    });
  // listen for changes
  React.useEffect(onLoad, [reset]);
  const inputlId = `react-select-multiple-${label}`;
  return (
    <div className={classes.root}>
      <NoSsr>
        <AsyncSelect
          ref={setRef}
          classes={classes}
          inputId={inputlId}
          styles={{
            indicatorsContainer: (base) => ({
              ...base,
              cursor: "pointer",
              visibility: hideIndicatorsContainer ? "hidden" : "visible",
            }),
            input: (base) => ({
              ...base,
              cursor: hideIndicatorsContainer ? "default" : "text",
              visibility: "visible",
            }),
            menu: (base) => ({
              ...base,
              visibility: hideMenu ? "hidden" : "visible",
            }),
          }}
          TextFieldProps={{
            label,
            InputLabelProps: {
              htmlFor: inputlId,
              shrink: isSet || !isEmpty(multi),
              className: classes.label,
            },
          }}
          disableUnderline={disableUnderline}
          loadOptions={onLoadPromiseOptions}
          isClearable={isClearable}
          defaultValue={defaultValue}
          defaultOptions={true}
          cacheOptions={true}
          isSearchable={isSearchable !== false}
          placeholder={label ? "" : placeholder}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          options={sortedOptions}
          components={components}
          color="inherit"
          hideSelectedOptions={true}
          onChange={handleChangeMulti}
          isMulti={isMulti}
          innerProps={{ chipIcon }}
          isDisabled={disabled}
          name={name}
          onBlur={onBlur}
        />
      </NoSsr>
    </div>
  );
});

export default React.memo(AsyncAutoCompleteInput);
