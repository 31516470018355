/*global google*/
import { get, isEmpty, omit } from "lodash";

import { GeoCoordinates } from "../components/widgets/MapWidget";
import { Currency, LocaleWithCountry } from "../store/defaults";
import { LocaleSettingsProps } from "../store/organisation";
import {
  BudgetAndDays,
  CampaignSteps,
  CustomLocations,
  LabelValue,
  SocialState,

} from "../store/socials";
import { DEFAULT_LOCALE_SETTINGS } from "./defaultLocaleSettings";
import { formatBillingAmountValueOnly } from "./numberFormatter";
import { mapAndReplacePostSetupPrefillPlaceholders } from "./campaign.helper";

export const QUERY_NUMBER_OPERATORS: LabelValue[] = [
  { label: "", value: "eq" },
  { label: "", value: "neq" },
  { label: "", value: "lt" },
  { label: "", value: "lte" },
  { label: "", value: "gt" },
  { label: "", value: "gte" },
];

export const QUERY_STRING_OPERATORS: LabelValue[] = [
  { label: "", value: "i_contains" },
  { label: "Does not contain", value: "i_not_contains" },
  { label: "", value: "i_is_any" },
  { label: "Is not any", value: "i_is_not_any" },
  { label: "", value: "eq" },
  { label: "Does not equals to", value: "neq" },
  { label: "Starts with", value: "i_starts_with" },
];

export const LIST_OBJECT_KEYS = Object.freeze({
  country: {
    title: "Countries",
    dataType: "string",
  },

  // home_listing_id: {
  //   title: "Specific Properties",
  //   dataType: "string",
  // },

  property_type: {
    title: "Property types",
    dataType: "string",
  },

  neighborhood: {
    title: "Neighborhoods",
    dataType: "string",
  },

  city: {
    title: "Cities",
    dataType: "string",
  },

  region: {
    title: "Regions",
    dataType: "string",
  },

  agent_name: {
    title: "Agents",
    dataType: "string",
  },

  mandate: {
    title: "Mandate Types",
    dataType: "string",
  },

  auction: {
    title: "Auction dates",
    dataType: "string",
  },
});
export const getCoordinatesFromAddress = (address: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          address,
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const latitude = results[0].geometry.location.lat();
            const longitude = results[0].geometry.location.lng();
            const coordinates: GeoCoordinates = {
              lat: latitude,
              lng: longitude,
            };
            resolve(coordinates);
          } else {
            reject(`Something went wrong ${status}`);
          }
        }
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const VALUE_OBJECT_KEYS = Object.freeze({
  listing_type: {
    title: "Listing type",
    dataType: "string",
  },

  agent_company: {
    title: "Agent company",
    dataType: "string",
  },

  property_type: {
    title: "Property type",
    dataType: "string",
  },

  price: {
    title: "Price",
    dataType: "number",
  }, // it can be a range

  region: {
    title: "Region",
    dataType: "string",
  },

  city: {
    title: "City",
    dataType: "string",
  },

  // currency: {
  //   title: "Currency",
  //   dataType: "string",
  // },

  num_beds: {
    title: "Beds",
    dataType: "number",
  },

  num_baths: {
    title: "Baths",
    dataType: "number",
  },

  zoning: {
    title: "Sector",
    dataType: "string",
  },

  days_on_market: {
    title: "Days on Market",
    dataType: "string",
  },
});

export const concatLocationDetails = (loc: CustomLocations) => {
  return `${get(loc, "suburb", "")} ${loc.radius}${loc.distance_unit === "kilometer" ? "km" : "miles"
    }`;
};

export const getSelectedSocialObjects = (social: any) => ({
  selectedFacebook: get(social, "selectedFlowCampaign.facebookPageId", ""),
  mainDescription: get(social, "selectedFlowCampaign.adCreative.message", ""),
  headline: get(social, "selectedFlowCampaign.adCreative.name", ""),
  description: get(social, "selectedFlowCampaign.adCreative.description", ""),
  callToAction: get(social, "selectedFlowCampaign.adCreative.calltoAction", ""),
  bragSituation: get(
    social,
    "selectedFlowCampaign.templateRender.bragSituation",
    ""
  ),
  templateType: get(social, "selectedFlowCampaign.selectedTemplate.type", ""),
  campaignType: get(social, "selectedFlowCampaign.flowCampaignType", ""),
  campaignLocalId: get(social, "selectedFlowCampaign.campaignLocalId", ""),
  agent: get(
    social,
    "selectedFlowCampaign.templateRender.agentData.defaultAgent",
    ""
  ),
  testimonialText: get(
    social,
    "selectedFlowCampaign.templateRender.testimonialText1",
    ""
  ),
  testimonialClient: get(
    social,
    "selectedFlowCampaign.templateRender.clientName",
    ""
  ),
  budget: get(social, "selectedFlowCampaign.budget", 0),
  customLocations: get(
    social,
    "selectedFlowCampaign.targeting.geo_locations.custom_locations",
    []
  ),
  selectedProperty: get(
    social,
    "selectedFlowCampaign.templateRender.agentData.selectedProperty",
    undefined
  ),
  adCreative: get(social, "selectedFlowCampaign.adCreative", undefined),
  targeting: get(social, "selectedFlowCampaign.targeting", undefined),
});

export const createPayload = (
  screenStep: string,
  social: SocialState,
  selectedCampaignState: any
) => {
  if (!selectedCampaignState) {
    return undefined;
  }

  const payload = omit(selectedCampaignState, [
    "selectedTemplate",
    "templateRender",
    "targeting",
    "adCreative",
  ]);
  const targeting = get(social, "selectedFlowCampaign.targeting", undefined);

  if (screenStep === CampaignSteps.Summary) {
    const customLocations = get(
      social,
      "selectedFlowCampaign.targeting.geo_locations.custom_locations",
      []
    );
    //remove suburb from custom location
    const custom_locations: CustomLocations[] = customLocations.map(
      (loc: CustomLocations) => {
        return omit(loc, ["suburb"]);
      }
    );

    if (!isEmpty(targeting)) {
      targeting.geo_locations.custom_locations = custom_locations;
    }
  }

  const adsets: any[] = [
    {
      targeting,
      ads: [
        {
          adCreative: get(social, "selectedFlowCampaign.adCreative", undefined),
        },
      ],
    },
  ];

  screenStep = CampaignSteps.Summary;
  Object.assign(payload, { adsets, screenStep });
  return payload;
};
export const getDataOptions = (data: any[], _myfunction: Function) => {
  const status = get(data, "status", null);
  return !isEmpty(data) && !status ? _myfunction(data) : [];
};

export const getCampaignDefaultValues = (
  social: any,
  matchedPlaceholders?: Record<string, string[]>,
  placeholderValues?: Record<string, string>,
  previousPlaceholderValues?: Record<string, string>,
) => {

  const selectedTemplate = get(social, "selectedFlowCampaign.selectedTemplate");


  if (selectedTemplate && selectedTemplate.hasCustomCopy) {
    const prefillText = {
      name: get(social, 'selectedFlowCampaign.adCreative.name', get(selectedTemplate, 'copyData.copyHeadline')),
      message: get(social, 'selectedFlowCampaign.adCreative.message', get(selectedTemplate, 'copyData.copyMainDescription')),
      description: get(social, 'selectedFlowCampaign.adCreative.description', get(selectedTemplate, 'copyData.copyDescription')),
    };

    const mappedPrefills = mapAndReplacePostSetupPrefillPlaceholders(
      prefillText,
      matchedPlaceholders,
      placeholderValues,
      previousPlaceholderValues,
    );

    return {
      headline: mappedPrefills.name,
      mainDescription: mappedPrefills.message,
      description: mappedPrefills.description,
    };
  } else {

    const prefillText = {
      name: get(social, 'selectedFlowCampaign.adCreative.name', ''),
      message: get(social, 'selectedFlowCampaign.adCreative.message', ''),
      description: get(social, 'selectedFlowCampaign.adCreative.description', ''),
    };

    const mappedPrefills = mapAndReplacePostSetupPrefillPlaceholders(
      prefillText,
      matchedPlaceholders,
      placeholderValues,
      previousPlaceholderValues,
    );

    return {
      headline: mappedPrefills.name,
      mainDescription: mappedPrefills.message,
      description: mappedPrefills.description,
    };
  }

};

export const getBillingCurrencyFromLocales = (
  locales: (LocaleWithCountry | LocaleSettingsProps)[],
  countryCode: string
): Currency => {
  const locale = locales.find(
    (loc) => get(loc, "country.code") === countryCode
  )!;
  return get(locale, "billingCurrency", DEFAULT_LOCALE_SETTINGS.currency);
};

export const getCampaignCountryBudget = (
  locale: LocaleSettingsProps
): Record<string, BudgetAndDays> => {
  const billingCurrency = get(
    locale,
    "billingCurrency",
    DEFAULT_LOCALE_SETTINGS.currency
  );
  const campaignCountryBudget = get(locale, "campaignCountryBudget", null);
  let campaignCountryBudgetDataset = {};

  if (!isEmpty(billingCurrency)) {
    Object.entries(campaignCountryBudget).map((entry) => {
      const [key, value] = entry;
      let x = {};
      const budgetKey = key.substring(0, key.indexOf(" "));
      const daysKey = key.substring(key.indexOf(" ") + 1);
      const formattedBudget = formatBillingAmountValueOnly(
        +budgetKey,
        billingCurrency!
      );
      x[formattedBudget + " " + daysKey] = value;
      Object.assign(campaignCountryBudgetDataset, x);
      return x;
    });
  }
  return campaignCountryBudgetDataset;
};

export const minimumDailyBudgetInValid = (
  days: number,
  commission: number,
  budget: number,
  minimumDailyBudget: number
): boolean => {
  if (budget === 0) return true;
  const commissionValue = (commission / 100) * budget;
  const balanceAfterCommission = budget - commissionValue;
  const dailyBudget = balanceAfterCommission / days;
  return dailyBudget < minimumDailyBudget;
};

export const CAMPAIGNS_PAGE_LIMIT = 5;
export const DEFAULT_MINIMUM_DAILY_BUDGET = 40;
export const DEFAULT_COMMISSION = 30;
