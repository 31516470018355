import { createRoutine, promisifyRoutine } from "redux-saga-routines";
import { SelectedFlowCampaign } from ".";
import {
  AdCreative,
  AdpostDefault,
  AgentData,
  DashboardCampaignsQuery,
  FieldsToMap,
  ConnectClientFacebookPagesPayload,
  Targeting,
  TemplateRender,
  UploadAdCreativePayload,
  RemoveFbPagePayload,
  DashboardKpiCounts,
  FlowBrandOrFuel,
  SocialState,
  RenderTemplatePayload,
  GetCreditsResponse,
  GetCreditsPayload,
  SaveDraftCampaignStepPayload,
  LinkSelectedClientFacebookPagesPayload,
  GetLinkedFacebookPagesByUserTokenPayload,
  GenerateCreativeImageRequestPayload,
} from "./types";

// start social asset
export const fetchAdAccounts = createRoutine("@@socials/FETCH_ADACCOUNTS");
export const fetchAdAccountsPromise = promisifyRoutine(fetchAdAccounts);

export const fetchFacebookPages = createRoutine(
  "@@socials/FETCH_FACEBOOK_PAGES"
);
export const fetchfacebookPagesPromise = promisifyRoutine(fetchFacebookPages);

export const fetchInstagramAccounts = createRoutine(
  "@@socials/FETCH_INSTAGRAM_ACCOUNTS"
);
export const fetchinstagramAccountsPromise = promisifyRoutine(
  fetchInstagramAccounts
);

export const fetchProductCatalogue = createRoutine(
  "@@socials/FETCH_PRODUCT_CATALOGUE"
);
export const fetchProductCataloguePromise = promisifyRoutine(
  fetchProductCatalogue
);
export const fetchFacebookPixels = createRoutine(
  "@@socials/FETCH_FACEBOOK_PIXELS"
);
export const fetchFacebookPixelsPromise = promisifyRoutine(fetchFacebookPixels);

export const fetchFacebookAudiences = createRoutine(
  "@@socials/FETCH_FACEBOOK_AUDIENCES"
);
export const fetchFacebookAudiencesPromise = promisifyRoutine(
  fetchFacebookAudiences
);

export const fetchGoogleAdAccounts = createRoutine("@@socials/FETCH_GOOGLE_ADACCOUNTS");
export const fetchGoogleAdAccountsPromise = promisifyRoutine(fetchGoogleAdAccounts);

export const fetchFilteredData = createRoutine("@@socials/FETCH_FILTERED_DATA");
export const fetchFilteredDataPromise = promisifyRoutine(fetchFilteredData);

export const fetchAdAccountCampaigns = createRoutine(
  "@@socials/FETCH_ADACCOUNT_CAMPAIGNS"
);
export const fetchAdAccountCampaignsPromise = promisifyRoutine(
  fetchAdAccountCampaigns
);

export const importFacebookCampaign = createRoutine(
  "@@socials/IMPORT_FACEBOOK_CAMPAIGN"
);
export const importFacebookCampaignPromise = promisifyRoutine(
  importFacebookCampaign
);

export const updateFacebookCampaign = createRoutine(
  "@@socials/UPDATE_FACEBOOK_CAMPAIGN"
);
export const updateFacebookCampaignPromise = promisifyRoutine(
  updateFacebookCampaign
);

export const fetchFacebookAds = createRoutine("@@socials/FETCH_FACEBOOK_ADS");
export const fetchFacebookAdsPromise = promisifyRoutine(fetchFacebookAds);

export const fetchAdIframeContainer = createRoutine(
  "@@socials/FETCH_IFRAME_CONTAINER"
);
export const fetchAdIframeContainerPromise = promisifyRoutine(
  fetchAdIframeContainer
);

export const setSelectedAdvert = createRoutine("@@socials/SET_SELECTED_ADVERT");
export const setSelectedAdvertPromise = promisifyRoutine(setSelectedAdvert);

export const fetchAdvertMetaData = createRoutine(
  "@@socials/FETCH_ADVERT_META_DATA"
);
export const fetchAdvertMetaDataPromise = promisifyRoutine(fetchAdvertMetaData);

export const fetchCampaignDetails = createRoutine(
  "@@socials/FETCH_CAMPAIGN_DETAILS"
);
export const fetchCampaignDetailsPromise =
  promisifyRoutine(fetchCampaignDetails);

export const setSelectedAccordion = createRoutine(
  "@@socials/SET_SELECTED_ACCORDION"
);
export const setSelectedAccordionPromise =
  promisifyRoutine(setSelectedAccordion);

export const archiveCampaign = createRoutine("@@socials/ARCHIVE_CAMPAIGN");
export const archiveCampaignPromise = promisifyRoutine(archiveCampaign);

export const addFlowCampaignStepper = createRoutine(
  "@@socials/ADD_FLOW_CAMPAIGN_STEPPER"
);
export const addFlowCampaignStepperPromise = promisifyRoutine(
  addFlowCampaignStepper
);

export const setSelectedCampaign = createRoutine(
  "@@socials/SET_SELECTED_CAMPAIGN"
);
export const setSelectedCampaignPromise = promisifyRoutine(setSelectedCampaign);

export const getCredits = createRoutine<GetCreditsPayload>(
  "@@socials/GET_CREDIT"
);
export const getCreditsPromise: (
  payload: GetCreditsPayload
) => Promise<GetCreditsResponse> = promisifyRoutine(getCredits);

export const getCampaignTemplates = createRoutine(
  "@@socials/GET_CAMPAIGN_TEMPLATES"
);
export const getCampaignTemplatesPromise =
  promisifyRoutine(getCampaignTemplates);

export const createCampaign = createRoutine("@@socials/CREATE_CAMPAIGN");
export const createCampaignPromise = promisifyRoutine(createCampaign);

export const deleteDraftCampaign = createRoutine(
  "@@socials/DELETE_DRAFT_CAMPAIGN"
);
export const deleteDraftCampaignPromise = promisifyRoutine(deleteDraftCampaign);

export const updateCampaignStatus = createRoutine("@@socials/UPDATE_STATUS");
export const updateCampaignStatusPromise =
  promisifyRoutine(updateCampaignStatus);

export const editCampaign = createRoutine("@@socials/EDIT_CAMPAIGN_BUDGET");
export const editCampaignPromise = promisifyRoutine(editCampaign);

export const fetchCampaignMetadata = createRoutine(
  "@@socials/FETCH_CAMPAIGN_METADATA"
);
export const fetchCampaignMetadataPromise = promisifyRoutine(
  fetchCampaignMetadata
);

export const uploadAdCreative = createRoutine("@@socials/UPLOAD_AD_CREATIVE");
export const uploadAdCreativePromise: (
  payload: UploadAdCreativePayload
) => Promise<any> = promisifyRoutine(uploadAdCreative);

export const uploadCreativeVideoThumbnail = createRoutine(
  "@@socials/UPLOAD_CREATIVE_VIDEO_THUMBNAIL"
);
export const uploadCreativeVideoThumbnailPromise = promisifyRoutine(
  uploadCreativeVideoThumbnail
);

export const fetchCampaignModeById = createRoutine(
  "@@socials/FETCH_CAMPAIGNMODE_BY_ID"
);
export const fetchCampaignModeByIdPromise = promisifyRoutine(
  fetchCampaignModeById
);

export const patchAdCreative = createRoutine<Partial<AdCreative>>(
  "@@socials/PATCH_AD_CREATIVE"
);
export const patchAdCreativePromise: (
  payload: Partial<AdCreative>
) => Promise<any> = promisifyRoutine(patchAdCreative);

export const fetchPendingCampaignSubscription = createRoutine(
  "@@socials/FETCH_PENDING_CAMPAIGN_SUBSCRIPTION"
);
export const fetchPendingCampaignSubscriptionPromise = promisifyRoutine(
  fetchPendingCampaignSubscription
);

export const addNewAd = createRoutine("@@socials/ADD_NEW_AD");
export const addNewAdPromise = promisifyRoutine(addNewAd);

export const setAddNewAdFlag = createRoutine("@@socials/SET_ADD_NEW_AD_FLAG");
export const setAddNewAdFlagPromise = promisifyRoutine(setAddNewAdFlag);

export const patchAgentData = createRoutine<Partial<AgentData>>(
  "@@socials/PATCH_AGENT_DATA"
);
export const patchAgentDataPromise: (
  payload: Partial<AgentData>
) => Promise<any> = promisifyRoutine(patchAgentData);

export const patchAdPostDefault = createRoutine(
  "@@socials/PATCH_AD_POST_DEFAULT"
);
export const patchAdPostDefaultPromise: (
  payload: Partial<AdpostDefault>
) => Promise<any> = promisifyRoutine(patchAdPostDefault);

export const patchSelectedFlowCampaign = createRoutine<
  Partial<SelectedFlowCampaign>
>("@@socials/PATCH_SELECTED_FLOW_CAMPAIGN");
export const patchSelectedFlowCampaignPromise: (
  payload: Partial<SelectedFlowCampaign>
) => Promise<any> = promisifyRoutine(patchSelectedFlowCampaign);

export const patchTargeting = createRoutine<Partial<Targeting>>(
  "@@socials/PATCH_TARGETING"
);
export const patchTargetingPromise: (
  payload: Partial<Targeting>
) => Promise<any> = promisifyRoutine(patchTargeting);

export const patchTemplateRender = createRoutine<Partial<TemplateRender>>(
  "@@socials/PATCH_TEMPLATE_RENDER"
);
export const patchTemplateRenderPromise: (
  payload: Partial<TemplateRender>
) => Promise<any> = promisifyRoutine(patchTemplateRender);

export const patchFieldsToMap = createRoutine("@@socials/PATCH_FIELDS_TO_MAP");
export const patchFieldsToMapPromise: (
  payload: Partial<FieldsToMap> | Partial<FieldsToMap>[]
) => Promise<any> = promisifyRoutine(patchFieldsToMap);

export const patchDashboardCampaignsQuery = createRoutine(
  "@@socials/PATCH_DASHBOARD_CAMPAIGNS_QUERY"
);
export const patchDashboardCampaignsQueryPromise: (
  payload: Partial<DashboardCampaignsQuery>
) => Promise<any> = promisifyRoutine(patchDashboardCampaignsQuery);

export const connectClientFacebookPages = createRoutine(
  "@@socials/CONNECT_CLIENT_FACEBOOK_PAGES"
);
export const connectClientFacebookPagesPromise: (
  payload: ConnectClientFacebookPagesPayload
) => Promise<any> = promisifyRoutine(connectClientFacebookPages);

export const removeFbPage = createRoutine("@@socials/REMOVE_FB_PAGE");
export const removeFbPagePromise: (
  payload: RemoveFbPagePayload
) => Promise<any> = promisifyRoutine(removeFbPage);

export const getCampaignById = createRoutine("@@socials/GET_CAMPAIGN_BY_ID");
export const getCampaignByIdPromise: (payload: string) => Promise<any> =
  promisifyRoutine(getCampaignById);

export const getMasterCampaignById = createRoutine(
  "@@socials/GET_MASTER_CAMPAIGN_BY_ID"
);
export const getMasterCampaignByIdPromise: (payload: string) => Promise<any> =
  promisifyRoutine(getMasterCampaignById);

export const upsertPublishedCampaign = createRoutine(
  "@@socials/UPSERT_PUBLISHED_CAMPAIGN"
);
export const upsertPublishedCampaignPromise: (
  payload: FlowBrandOrFuel
) => Promise<any> = promisifyRoutine(upsertPublishedCampaign);

export const addToDashboardKpiCounts = createRoutine(
  "@@socials/ADD_TO_DASHBOARD_KPI_COUNTS"
);
export const addToDashboardKpiCountsPromise: (
  payload: DashboardKpiCounts
) => Promise<any> = promisifyRoutine(addToDashboardKpiCounts);

export const getAdTemplateById = createRoutine(
  "@@socials/GET_AD_TEMPLATE_BY_ID"
);
export const getAdTemplateByIdPromise: (payload: string) => Promise<any> =
  promisifyRoutine(getAdTemplateById);

export const cancelCheckoutByTransactionId = createRoutine(
  "@@socials/CANCEL_CHECKOUT_BY_TRANSACTION_ID"
);
export const cancelCheckoutByTransactionIdPromise: (
  payload: string
) => Promise<any> = promisifyRoutine(cancelCheckoutByTransactionId);

export const setSeenCampaign = createRoutine("@@socials/SET_SEEN_CAMPAIGN");
export const setSeenCampaignPromise: (payload: string) => Promise<any> =
  promisifyRoutine(setSeenCampaign);

export const patchSocial =
  createRoutine<Partial<SocialState>>("@@socials/PATCH");
export const patchSocialPromise: (
  payload: Partial<SocialState>
) => Promise<any> = promisifyRoutine(patchSocial);

export const getCampaignByIdOrExternalRef = createRoutine<string>(
  "@@socials/GET_CAMPAIGN_BY_ID_OR_EXTERNAL_REF"
);
export const getCampaignByIdOrExternalRefPromise: (
  payload: string
) => Promise<any> = promisifyRoutine(getCampaignByIdOrExternalRef);

export const renderTemplate = createRoutine<RenderTemplatePayload>(
  "@@socials/RENDER_TEMPLATE"
);
export const renderTemplatePromise: (
  payload: RenderTemplatePayload
) => Promise<any> = promisifyRoutine(renderTemplate);

export const saveDraftCampaignStep =
  createRoutine<SaveDraftCampaignStepPayload>(
    "@@socials/SAVE_DRAFT_CAMPAIGN_STEP"
  );
export const saveDraftCampaignStepPromise: (
  payload: SaveDraftCampaignStepPayload
) => Promise<any> = promisifyRoutine(saveDraftCampaignStep);

export const campaignCheckout = createRoutine("@@socials/CAMPAIGN_CHECKOUT");
export const campaignCheckoutPromise: (payload?: any) => Promise<any> =
  promisifyRoutine(campaignCheckout);

export const saveCampaignStep = createRoutine<string>(
  "@@socials/SAVE_CAMPAIGN_STEP"
);
export const saveCampaignStepPromise: (payload: string) => Promise<any> =
  promisifyRoutine(saveCampaignStep);

export const addNewAdToCampaign = createRoutine(
  "@@socials/ADD_NEW_AD_TO_CAMPAIGN"
);
export const addNewAdToCampaignPromise: (payload?: any) => Promise<any> =
  promisifyRoutine(addNewAdToCampaign);

export const getLinkedFacebookPagesByUserToken = createRoutine(
  "@@socials/GET_LINKED_FACEBOOK_PAGES_BY_USER_TOKEN"
);
export const getLinkedFacebookPagesByUserTokenPromise: (
  payload: GetLinkedFacebookPagesByUserTokenPayload
) => Promise<any> = promisifyRoutine(getLinkedFacebookPagesByUserToken);

export const linkSelectedClientFacebookPages = createRoutine(
  "@@socials/LINK_SELECTED_CLIENT_FACEBOOK_PAGES"
);
export const linkSelectedClientFacebookPagesPromise: (
  payload: LinkSelectedClientFacebookPagesPayload
) => Promise<any> = promisifyRoutine(linkSelectedClientFacebookPages);

export const generateCreativeImage =
  createRoutine<GenerateCreativeImageRequestPayload>(
    "@@socials/GENERATE_CREATIVE_IMAGE"
  );
export const generateCreativeImagePromise: (
  payload: GenerateCreativeImageRequestPayload
) => Promise<any> = promisifyRoutine(generateCreativeImage);

export const getTargetingTemplateById = createRoutine<string>(
  "@@socials/GET_TARGETING_TEMPLATE_BY_ID"
);
export const getTargetingTemplateByIdPromise: (
  payload: string
) => Promise<any> = promisifyRoutine(getTargetingTemplateById);

export const resetCampaignState = createRoutine(
  "@@socials/RESET_CAMPAIGN_STATE"
);
export const resetCampaignStatePromise: (payload?: any) => Promise<void> =
  promisifyRoutine(resetCampaignState);

export const fetchTargetingTemplates = createRoutine(
  "@@organisation/FETCH_TARGETING_TEMPLATES"
);
