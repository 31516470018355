import { get } from "lodash";
import React from "react";
import styled from "../../utils/styledComponents";

// eslint-disable-next-line
const Title = styled.span<{
  fontSize: number;
  color: string;
  fontWeight: string;
}>`
  && {
    color: ${({ theme }) => theme.colors.grey4};
    font-family: ${({ theme, fontWeight }) => theme.fonts[fontWeight]};
    font-weight: ${({ theme, fontWeight }) => theme.fontWeights[fontWeight]};
    font-size: ${({ theme, fontSize }) =>
      fontSize > 0 ? `${fontSize}px` : theme.fontSizes.navbarHeading};
    line-height: 1.15;
  }
`;

interface OwnProps {
  title: any;
  fontSize?: number;
  fontWeight?: number;
  color?: string;
}

const TitleWithProps: React.FunctionComponent<OwnProps> = (props) => {
  const { title } = props;
  const titleFontSize = get(props, "fontSize", 0);
  const fontWeight = get(props, "fontWeight", 700);
  const color = get(props, "color", "default");

  return (
    <Title
      color={color}
      fontSize={titleFontSize}
      fontWeight={`MS${fontWeight}`}
    >
      {title}
    </Title>
  );
};

export default TitleWithProps;
