import { routerMiddleware } from "connected-react-router";
import { History } from "history";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { createMigrate, persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";

import RouteMiddleware from "./middleware/RouteMiddleware";
import { rootReducer, rootSaga } from "./store";
import { migrations } from "./store/migrations";

const MIGRATION_DEBUG = false;

export default function configureStore(history: History) {
  const composeEnhancers = composeWithDevTools({});
  const sagaMiddleware = createSagaMiddleware();

  const persistConfig = {
    key: "ll-cms",
    storage,
    whitelist: [],
    version: 1,
    timeout: 0,
    migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG }),
  };

  const rootReducerWithHistory = rootReducer(history);
  const persistedReducer = persistReducer(
    persistConfig,
    rootReducerWithHistory
  );
  const store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware,
        RouteMiddleware
      )
    )
  );

  // Don't forget to run the root saga, and return the store object.
  sagaMiddleware.run(rootSaga);
  return store;
}
