import { withSnackbar, withSnackbarProps } from 'notistack';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../store';
import { Notification, removeSnackbar } from '../../store/notifications';

interface PropsFromDispatch {
  removeSnackbar: typeof removeSnackbar;
}

interface PropsFromState {
  notifications: Notification[];
}

type AllProps = PropsFromDispatch & PropsFromState & withSnackbarProps;

class Notifier extends React.Component<AllProps> {
  private displayed: string[] = [];

  public shouldComponentUpdate(newprops: AllProps) {
    const { notifications: newStacks } = newprops;
    const { notifications: currentSnacks } = this.props;
    let notExists = false;
    let snack: any;
    for (snack of newStacks) {
      if (notExists) {
        continue;
      }
      notExists =
        notExists ||
        // eslint-disable-next-line
        !currentSnacks.filter(({ key }: Notification) => snack.key === key)
          .length;
    }
    return notExists;
  }

  public componentDidUpdate() {
    const { notifications = [], enqueueSnackbar } = this.props;

    notifications.forEach((notification: Notification) => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(notification.key)) {
        return;
      }
      // Display snackbar using notistack
      enqueueSnackbar(notification.message, {
        anchorOrigin: {
          horizontal: "right",
          vertical: "bottom"
        },
        ...notification.options
      });
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.key);
      // Dispatch action to remove snackbar from redux store
      this.props.removeSnackbar(notification.key);
    });
  }
  public render() {
    return null;
  }

  private storeDisplayed = (id: string) => {
    this.displayed = [...this.displayed, id];
  };
}

const mapStateToProps = ({ notifications }: ApplicationState) => ({
  notifications: notifications.notifications
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({ removeSnackbar }, dispatch)
});

export default withSnackbar(connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifier) as any);
