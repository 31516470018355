import { OptionType } from "../../components/inputs/AsyncAutoCompleteInput";
import { FlowExtra } from "../flowExtras";
import { Unit } from "../units";

export enum PropertyOverviewFields {
  propertyType = "Property Type",
  propertyTitle = "Property Title",
  monthlyLevy = "Monthly levy",
  specialLevy = "Special levy",
  monthlyRates = "Monthly rates",
  occupation = "Occupation",
  occupationDate = "Occupation date",
  erfSize = "Erf size",
  floorSize = "Floor size",
}

export interface PropertyOverviewField {
  name: string;
  datatype: string;
  measurementType?: string;
  index: number;
  value: string | number;
}

export interface Property extends ApiResponse {
  _id: string;
  title: string;
  description: string;
  shortDescription: string;
  status: string;
  propertyType: string;
  showOnMap: boolean;
  images: any;
  videos: Video[];
  landlordUser: string;
  landlord?: string;
  agent?: string;
  address?: AddressProps;
  location?: Location;
  features: Feature[];
  neighbourhood?: string;
  region?: string;
  listedOn: Date;
  units: Unit[];
  numberOfUnits: number;
  minRentalAmount: number;
  maxRentalAmount: number;
  pictures: Image[];
  flowExtras: FlowExtra[];
  syndicator?: object;
  listingType: ListingTypes;
  listingSector: string;
  coverImageUrl?: string;
  isSelected?: boolean;
  propertyOverView?: PropertyOverviewField[];
  size?: number | null;
  hasUnits?: boolean;
  link?: string;
  hasLeadUrl?: boolean;
  leadUrl?: {
    url: string;
    title: string;
    message: string;
  }
}
export interface AddressProps {
  line1?: string;
  line2?: string;
  suburb?: string;
  postCode?: string;
  province?: string;
  country?: string;
  city?: string;
  countryCode?: string;
  locality?: string;
  addressString?: string;
  fullAddress?: string;
}
export interface Location {
  coordinates: number[];
  type?: string;
}

export interface GoogleSearchProps {
  address: AddressProps;
  location?: { lng?: number; lat?: number };
  formattedAddress?: string;
}

export interface PropertyCreateModel {
  title: string;
  propertyType: string;
  listingType: string;
  listingSector: string;
  organisation: string;
  agent?: string;
  hasUnits?: boolean;
  externalPropertyLink?: string;
  hasLeadUrl?: boolean;
  leadUrl?: {
    url: string;
    title: string;
    message: string;
  }
  externalId?: string;
}

export interface Image {
  _id: string;
  name: string;
  size: number;
  ext: string;
  hash: string;
  mime: string;
  url: string;
  provider: string;
  media: Media;
  title: string;
  type: string;
  componentId: string;
  index?: number;
  path: string;
}
export interface Video {
  url: string;
  isValidUrl?: boolean;
}

interface Media {
  sizes: object;
}

export enum ImageSizes {
  AVATAR = "avatar",
  COVER = "cover",
  LARGE = "large",
  MEDIUM = "medium",
  THUMBNAIL = "thumbnail",
  SMALL = "small",
}

export interface ComponentListingImage {
  _id: string;
  type: string;
  title: string;
}

export interface Feature {
  name: string;
  isSelected: boolean;
}

export type ApiResponse = Record<string, any>;

export interface PropertiesState {
  loading: boolean;
  readonly properties: Property[];
  readonly totalRecords?: number;
  errors?: string;
  readonly addProperty: AddPropertyState;
  readonly selectedProperty?: Property;
  seenProperties: Record<string, boolean>;
  isCreatingProperty: boolean;
  isUpdatingProperty: boolean;
}

export interface AddPropertyState {
  canContinue: boolean;
  submitForm: boolean;
  doneUploading: boolean;
}

export enum ListingTypes {
  Sale = "sale",
  Rent = "rent",
  Auction = "auction",
}

export interface AgentOption extends OptionType {
  firstName: string;
  lastName: string;
  profileImageUrl: string,
  contact: {
    email: string;
    contactNumber: string;
  };
}

export enum PropertyStatuses {
  Draft = 'draft',
  Listed = 'listed',
  Pending = 'pending',
  Reported = 'reported',
  Sold = 'sold',
  Archived = 'archived',
  PriceReduced = 'price reduced',
}

export interface FlattenOrganisationsMember {
  [organisationId: string]: AgentOption[];
}