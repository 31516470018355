import produce from "immer";
import { concat, isArray, isEmpty } from "lodash";
import get from "lodash/get";
import { Reducer } from "redux";

import {
  createAccount,
  createBilling,
  createSubscriptionCheckoutId,
  createSubscriptionCheckoutWithExistingCard,
  deleteCard,
  fetchAccount,
  fetchAccountSubscriptions,
  fetchBilling,
  fetchLoggedInUserCampaignsSubscriptions,
  fetchSubscriptionCheckoutResult,
  updateAccount,
  updateAccountBillingDetails,
  updateBilling,
  updateCard,
  updateMessage,
  fetchLoggedInUserPaymentFeeds,
  addUserMasterCampaignSubscriptionToList,
} from "./routines";
import { AccountState } from "./types";

const initialState: AccountState = {
  account: undefined,
  billingDetails: undefined,
  accountDetails: undefined,
  errors: undefined,
  loading: false,
  paymentIntervals: undefined,
  paymentMethods: undefined,
  subscriptionTiers: undefined,
  subscriptions: [],
  loadingSubscriptions: false,
  feeds: { next: null, previous: null, results: [] },
  loadingFeeds: false,
};

const reducer: Reducer<AccountState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger
    case createAccount.TRIGGER:
    case fetchAccount.TRIGGER:
    case fetchAccountSubscriptions.TRIGGER:
    case createSubscriptionCheckoutId.TRIGGER:
    case fetchSubscriptionCheckoutResult.TRIGGER:
    case deleteCard.TRIGGER:
    case updateCard.TRIGGER:
    case createSubscriptionCheckoutWithExistingCard.TRIGGER:
    case updateMessage.TRIGGER:
    case updateAccount.TRIGGER:
    case fetchBilling.TRIGGER:
    case createBilling.TRIGGER:
    case updateBilling.TRIGGER:
    case updateAccountBillingDetails.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }

    case fetchLoggedInUserPaymentFeeds.TRIGGER: {
      return { ...state, loadingFeeds: true, errors: undefined };
    }
    case fetchLoggedInUserCampaignsSubscriptions.TRIGGER: {
      const newDerivedState = produce<AccountState>(
        state,
        (draft: AccountState) => {
          draft.loadingSubscriptions = true;
          draft.errors = undefined;
        }
      );
      return newDerivedState;
    }

    // Success
    case fetchAccountSubscriptions.SUCCESS: {
      const {
        billingCountries,
        paymentIntervals,
        paymentMethods,
        subscriptionTiers,
        subscriptions,
      } = action.payload;
      return {
        ...state,
        billingCountries,
        paymentIntervals,
        paymentMethods,
        subscriptionTiers,
        subscriptions,
      };
    }

    case createAccount.SUCCESS:
    case updateAccount.SUCCESS:
    case createSubscriptionCheckoutId.SUCCESS:
    case deleteCard.SUCCESS:
    case updateCard.SUCCESS:
    case createSubscriptionCheckoutWithExistingCard.SUCCESS:
    case fetchAccount.SUCCESS: {
      return { ...state, account: action.payload, errors: undefined };
    }

    case updateMessage.SUCCESS: {
      return { ...state, message: action.payload, errors: undefined };
    }

    case fetchSubscriptionCheckoutResult.SUCCESS: {
      return { ...state, account: action.payload.account, errors: undefined };
    }

    case createBilling.SUCCESS:
    case updateBilling.SUCCESS:
    case fetchBilling.SUCCESS: {
      return {
        ...state,
        billingDetails: action.payload,
        accountDetails: get(action, "payload.accountDetails", undefined),
        errors: undefined,
      };
    }

    case updateAccountBillingDetails.SUCCESS: {
      return {
        ...state,
        accountDetails: get(action, "payload.accountDetails", undefined),
        errors: undefined,
      };
    }
    case fetchLoggedInUserPaymentFeeds.SUCCESS: {
      const existingFeedsResults = get(state, "feeds.results", []);
      const newFeedsResults = get(action, "payload.results", []);
      const results = concat(existingFeedsResults, newFeedsResults);
      return {
        ...state,
        feeds: { ...action.payload, results },
        errors: undefined,
      };
    }

    case fetchLoggedInUserCampaignsSubscriptions.SUCCESS: {
      const newDerivedState = produce<AccountState>(
        state,
        (draft: AccountState) => {
          draft.subscriptions = action.payload;
        }
      );
      return newDerivedState;
    }

    case addUserMasterCampaignSubscriptionToList.SUCCESS: {
      const newDerivedState = produce<AccountState>(
        state,
        (draft: AccountState) => {
          if (isEmpty(draft.subscriptions) || !isArray(draft.subscriptions)) draft.subscriptions = [action.payload];
          else draft.subscriptions.push(action.payload);
        }
      );
      return newDerivedState;
    }

    // Failure
    case createAccount.FAILURE:
    case fetchAccount.FAILURE: {
      return { ...state, account: undefined, errors: action.payload };
    }

    case fetchAccountSubscriptions.FAILURE:
    case createSubscriptionCheckoutId.FAILURE:
    case fetchSubscriptionCheckoutResult.FAILURE:
    case deleteCard.FAILURE:
    case updateCard.FAILURE:
    case createSubscriptionCheckoutWithExistingCard.FAILURE:
    case updateMessage.FAILURE:
    case updateAccount.FAILURE:
    case fetchBilling.FAILURE:
    case updateBilling.FAILURE:
    case fetchLoggedInUserPaymentFeeds.FAILURE:
    case updateAccountBillingDetails.FAILURE: {
      return { ...state, errors: action.payload };
    }

    case fetchLoggedInUserCampaignsSubscriptions.FAILURE: {
      const newDerivedState = produce<AccountState>(
        state,
        (draft: AccountState) => {
          draft.errors = action.payload;
        }
      );
      return newDerivedState;
    }

    // Fulfill
    case createAccount.FULFILL:
    case fetchAccount.FULFILL:
    case fetchAccountSubscriptions.FULFILL:
    case createSubscriptionCheckoutId.FULFILL:
    case fetchSubscriptionCheckoutResult.FULFILL:
    case deleteCard.FULFILL:
    case updateCard.FULFILL:
    case createSubscriptionCheckoutWithExistingCard.FULFILL:
    case updateMessage.FULFILL:
    case updateAccount.FULFILL:
    case fetchBilling.FULFILL:
    case createBilling.FULFILL:
    case updateBilling.FULFILL:
    case updateAccountBillingDetails.FULFILL: {
      return { ...state, loading: false };
    }

    case fetchLoggedInUserPaymentFeeds.FULFILL: {
      return { ...state, loadingFeeds: false };
    }

    case fetchLoggedInUserCampaignsSubscriptions.FULFILL: {
      const newDerivedState = produce<AccountState>(
        state,
        (draft: AccountState) => {
          draft.loadingSubscriptions = false;
        }
      );
      return newDerivedState;
    }

    default: {
      return state;
    }
  }
};

export { reducer as accountReducer };
