import { Reducer } from 'redux';

import {
    fetchClaimedRewards, fetchLeaseBreakdown, fetchTenantLeaderboard, fetchTenantLevels,
    fetchTransactionsBreakdown
} from './routines';
import { ReportState } from './types';

const initialState: ReportState = {
  loading: false,
  claimedRewards: {
    loading: false,
    data: []
  },
  errors: undefined,
  leases: {
    loading: false,
    data: { active: 0, expiringThisMonth: 0, invited: 0, total: 0 }
  },
  tenants: {
    loading: false,
    data: []
  },
  tenantLevels: { loading: false, data: [] },
  transactions: { loading: false, data: [] }
};

const reducer: Reducer<ReportState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger
    case fetchTenantLevels.TRIGGER: {
      return {
        ...state,
        tenantLevels: {
          ...state.tenantLevels,
          loading: true
        }
      };
    }
    case fetchClaimedRewards.TRIGGER: {
      return {
        ...state,
        claimedRewards: {
          ...state.claimedRewards,
          loading: true
        }
      };
    }
    case fetchLeaseBreakdown.TRIGGER: {
      return {
        ...state,
        leases: {
          ...state.leases,
          loading: true
        }
      };
    }
    case fetchTransactionsBreakdown.TRIGGER: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          loading: true
        }
      };
    }
    case fetchTenantLeaderboard.TRIGGER: {
      return {
        ...state,
        tenants: {
          ...state.tenants,
          loading: true
        }
      };
    }
    // Success
    case fetchTenantLevels.SUCCESS: {
      return {
        ...state,
        tenantLevels: {
          ...state.tenantLevels,
          data: action.payload
        }
      };
    }
    case fetchLeaseBreakdown.SUCCESS: {
      return {
        ...state,
        leases: {
          ...state.leases,
          data: action.payload
        }
      };
    }
    case fetchClaimedRewards.SUCCESS: {
      return {
        ...state,
        claimedRewards: {
          ...state.claimedRewards,
          data: action.payload
        }
      };
    }
    case fetchTenantLeaderboard.SUCCESS: {
      return {
        ...state,
        tenants: {
          ...state.tenants,
          data: action.payload
        }
      };
    }
    case fetchTransactionsBreakdown.SUCCESS: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          data: action.payload
        }
      };
    }
    // Failure
    case fetchTenantLevels.FAILURE:
    case fetchClaimedRewards.FAILURE:
    case fetchLeaseBreakdown.FAILURE:
    case fetchTenantLeaderboard.FAILURE:
    case fetchTransactionsBreakdown.FAILURE: {
      return { ...state, errors: action.payload };
    }
    // Fulfill
    case fetchTenantLevels.FULFILL: {
      return {
        ...state,
        tenantLevels: {
          ...state.tenantLevels,
          loading: false
        }
      };
    }
    case fetchClaimedRewards.FULFILL: {
      return {
        ...state,
        claimedRewards: {
          ...state.claimedRewards,
          loading: false
        }
      };
    }
    case fetchLeaseBreakdown.FULFILL: {
      return {
        ...state,
        leases: {
          ...state.leases,
          loading: false
        }
      };
    }

    case fetchTransactionsBreakdown.FULFILL: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          loading: false
        }
      };
    }
    case fetchTenantLeaderboard.FULFILL: {
      return {
        ...state,
        tenants: {
          ...state.tenants,
          loading: false
        }
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as reportReducer };
