import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const API = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Version": "v1"
  }
});

API.interceptors.response.use(
  response => {
    // Set header in global axios
    const AUTH_TOKEN = response.headers["authorization-token"];
    if (AUTH_TOKEN) {
      axios.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
      localStorage.setItem("auth_token", AUTH_TOKEN);
    }

    return response;
  },

  error => {
    return Promise.reject(error);
  }
);

API.interceptors.request.use(
  config => {
    const token = localStorage.getItem("auth_token");

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

export default function callApi(method: string, path: string, options?: any) {
  const axiosOptions = {
    data: {},
    method,
    params: {},
    url: path,
    ...options
  };

  return API(axiosOptions);
}
