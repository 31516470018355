import MuiSvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import { IconProps } from '.';

const ViewingsIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 22 26">
    <svg width="22px" height="26px" viewBox="0 0 22 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="CMS-V2---Dashboard,-Profile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Landlord-CMS-Profile" transform="translate(-424.000000, -114.000000)">
              <g id="Group-11" transform="translate(380.250000, 90.000000)">
                  <g id="Mask-Copy-7">
                      <use fill="black" fill-opacity="1" filter="url(#filter-2)"/>
                      <use fill="#FFFFFF" fill-rule="evenodd"/>
                  </g>
                  <g id="Group-6" transform="translate(44.000000, 24.000000)" fill={nativeColor} fill-rule="nonzero">
                      <path d="M10.3051338,12.1203121 C11.9650545,12.1203121 13.402415,11.5231077 14.5768367,10.344824 C15.7512583,9.1667323 16.3466023,7.72526404 16.3466023,6.05996409 C16.3466023,4.39524005 15.7512583,2.95357979 14.5766453,1.77510417 C13.4020323,0.597204405 11.9648632,9.68400987e-14 10.3051338,9.68400987e-14 C8.64502175,9.68400987e-14 7.208044,0.597204405 6.03362235,1.77529612 C4.85920071,2.95338783 4.2636654,4.39504809 4.2636654,6.05996409 C4.2636654,7.72526404 4.85920071,9.16692425 6.03381371,10.345016 C7.20842676,11.5229157 8.64559582,12.1203121 10.3051338,12.1203121 Z" id="Path"/>
                      <path d="M20.8760769,20.1865195 C20.8422049,19.6962398 20.7736954,19.1614242 20.6728448,18.5966619 C20.5710374,18.0276765 20.4399507,17.4897894 20.2830294,16.998166 C20.1209413,16.4900335 19.900486,15.988236 19.6279789,15.5073626 C19.3451378,15.0082525 19.0129241,14.5736428 18.6401404,14.2160113 C18.250325,13.8418707 17.7730547,13.5410609 17.2211512,13.3216445 C16.6711613,13.1033797 16.0616562,12.9928077 15.4096675,12.9928077 C15.1536179,12.9928077 14.9059886,13.0981967 14.4277614,13.4105244 C14.1334384,13.6030657 13.7891685,13.8257456 13.4049028,14.0720372 C13.076325,14.2820474 12.6312044,14.478812 12.0814059,14.6569559 C11.5450031,14.8310684 11.0003716,14.9193726 10.4628205,14.9193726 C9.92526955,14.9193726 9.38082935,14.8310684 8.84385248,14.6569559 C8.29462805,14.4790039 7.84950748,14.2822394 7.52131245,14.0722292 C7.14068268,13.8282411 6.79622138,13.6055613 6.4974969,13.4103325 C6.0198439,13.0980048 5.77202312,12.9926157 5.51597358,12.9926157 C4.86379353,12.9926157 4.25447976,13.1033797 3.70468127,13.3218364 C3.15316043,13.5408689 2.67569884,13.8416787 2.28550072,14.2162033 C1.91290841,14.5740268 1.58050327,15.0084445 1.298045,15.5073626 C1.0257292,15.988236 0.805273967,16.4898416 0.642994423,16.998358 C0.48626452,17.4899814 0.355177872,18.0276765 0.253370421,18.5966619 C0.152519796,19.1606563 0.0840102954,19.695664 0.0501382801,20.1870954 C0.0168403301,20.6685447 -3.57563441e-14,21.1682306 -3.57563441e-14,21.6729077 C-3.57563441e-14,22.986335 0.41622408,24.0496314 1.23699879,24.8338101 C2.04763105,25.6076227 3.12024524,26.000192 4.42460536,26.000192 L16.5021839,26.000192 C17.806544,26.000192 18.8787755,25.6078147 19.6895991,24.8338101 C20.5105653,24.0502073 20.9267893,22.986719 20.9267893,21.6727157 C20.9267893,21.165735 20.9095662,20.6656652 20.8760769,20.1865195 L20.8760769,20.1865195 Z" id="Path"/>
                  </g>
              </g>
          </g>
      </g>
  </svg>
  </MuiSvgIcon>
);

export default ViewingsIcon;
