export const addressAsString = (address: {
  line1?: string;
  line2?: string;
  suburb?: string;
  city?: string;
  fullAddress?: string;
}): string => {
  if (!address) return "";

  if (address.fullAddress) return address.fullAddress;

  return `${address.line1 ? `${address.line1}, ` : ""}
    ${address.suburb ? `${address.suburb}, ` : ""}
    ${address.city}`;
};
