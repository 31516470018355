import { omit } from 'lodash';
import * as React from 'react';

import Grid, { GridProps } from '@material-ui/core/Grid';

import styled from '../../utils/styledComponents';
import { HeaderContextConsumer } from '../context/HeaderContext';

interface OwnProps {
  backgroundColor?: string;
  hidden?: boolean;
}

export type HeaderInterface = GridProps & OwnProps;

const Wrapper = styled((props: HeaderInterface) => (
  <Grid {...omit(props, ["backgroundColor", "hidden"])} />
))``;

const Header: React.FunctionComponent<any> = ({
  backgroundColor,
  children,
  ...props
}) => (
  <HeaderContextConsumer>
    {context => (
      <Wrapper
        container={true}
        hidden={context.isHeaderHidden}
        {...props}
      >
        {children}
      </Wrapper>
    )}
  </HeaderContextConsumer>
);

export default Header;
