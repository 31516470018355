import { AnyAction } from "redux";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import callApi from "../../utils/callApi";
import { errorHandler } from "../../utils/errorHandler";
import { enqueueSnackbar } from "../notifications";
import { fetchFLowExtras } from "./routines";

function* handleFetchFlowExtras(action: AnyAction) {
  try {
    const res = yield call(callApi, "get", "/flow-extras", {});
    yield put(fetchFLowExtras.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(fetchFLowExtras.failure(errorHandler(err.response)));
    } else {
      yield put(fetchFLowExtras.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchFLowExtras.fulfill());
  }
}

function* fetchFlowExtrasWatcher() {
  yield takeEvery(fetchFLowExtras.TRIGGER, handleFetchFlowExtras);
}

// Error handlers
function* handleFlowExtrasError(action: AnyAction) {
  yield put(
    enqueueSnackbar({
      message: action.payload,
      options: {
        variant: "error",
      },
    })
  );
}

function* flowExtrasErrorWatcher() {
  yield takeEvery([fetchFLowExtras.FAILURE], handleFlowExtrasError);
}

export function* flowExtrasSaga() {
  yield all([fork(fetchFlowExtrasWatcher), fork(flowExtrasErrorWatcher)]);
}
