import React from "react";
import { ColouredIconProps } from ".";
import { theme } from "../../styles/theme";

const CheckCircleIcon: React.FunctionComponent<ColouredIconProps> = ({
  nativeColor = theme.colors.grey4,
}) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 24  24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={nativeColor}
      fill-rule="evenodd"
      d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
      clip-rule="evenodd"
    />
  </svg>
);

export default CheckCircleIcon;
