import MuiSvgIcon from "@material-ui/core/SvgIcon";
import React from "react";
import { IconProps } from "../icons";

const UpgradeIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon viewBox="0 0 18 18" {...props}>
    <svg width={18} height={18} viewBox="0 0 18 18" {...props}>
      <title>{"Upgrade"}</title>
      <g transform="translate(-440 -330)" fill="none" fillRule="evenodd">
        <rect fill="#FFF" x={331} y={104} width={837} height={538} rx={4} />
        <g transform="translate(440 330)">
          <rect
            fill="#FF7418"
            fillRule="nonzero"
            transform="rotate(-180 9 9)"
            width={18}
            height={18}
            rx={9}
          />
          <g stroke="#FFF" strokeWidth={2}>
            <path d="M4 9l5-5 5 5" />
            <path strokeLinecap="square" d="M9 6v8" />
          </g>
        </g>
      </g>
    </svg>
  </MuiSvgIcon>
);

export default UpgradeIcon;
