import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const addProperty = createRoutine("@@properties/ADD_PROPERTY");
export const getProperties = createRoutine("@@properties/GET_PROPERTIES");
export const getPropertyById = createRoutine<{ propertyId: string }>("@@properties/GET_PROPERTY_BYID");
export const selectFeature = createRoutine("@@properties/SELECT_FEATURE");
export const updateField = createRoutine("@@properties/UPDATE_FIELD");
export const deleteProperty = createRoutine("@@properties/DELETE_PROPERTY");
export const deselectProperty = createRoutine("@@properties/DESELECT_PROPERTY");
export const uploadImage = createRoutine("@@properties/UPLOAD_IMAGE");
export const selectProperty = createRoutine("@@properties/SELECT_PROPERTY");
export const updateFlowExtras = createRoutine("@@properties/updateFlowExtras");
export const deleteImageById = createRoutine("@@properties/DELETE_IMAGE_BYID");

export const generatePreviewToken = createRoutine(
  "@@properties/GENERATE_PREVIEW_TOKEN"
);

export const savePropertyInfo = createRoutine(
  "@@properties/SAVE_PROPERTY_INFO"
);
export const updatePropertyInfo = createRoutine(
  "@@properties/UPDATE_PROPERTY_INFO"
);
export const updatePropertyStatus = createRoutine(
  "@@properties/UPDATE_PROPERTY_STATUS"
);

export const addPropertyStepper = createRoutine(
  "@@properties/ADD_PROPERTY_STEPPER"
);

export const setSelectedProperty = createRoutine(
  "@@properties/SET_SELECTED_PROPERTY"
);

export const setSeenProperty = createRoutine("@@properties/SET_SEEN_PROPERTY");

export const addPropertyPromise = promisifyRoutine(addProperty);
export const savePropertyInfoPromise = promisifyRoutine(savePropertyInfo);
export const updatePropertyInfoPromise = promisifyRoutine(updatePropertyInfo);
export const addPropertyStepperPromise = promisifyRoutine(addPropertyStepper);
export const deletePropertyPromise = promisifyRoutine(deleteProperty);
export const getPropertyByIdPromise: (payload: { propertyId: string }) => Promise<any> = promisifyRoutine(getPropertyById);
export const selectFeaturePromise = promisifyRoutine(selectFeature);
export const updateFieldPromise = promisifyRoutine(updateField);
export const selectPropertyPromise = promisifyRoutine(selectProperty);
export const deselectPropertyPromise = promisifyRoutine(deselectProperty);
export const uploadImagePromise = promisifyRoutine(uploadImage);
export const getPropertiesPromise = promisifyRoutine(getProperties);
export const updateFlowExtrasPromise = promisifyRoutine(updateFlowExtras);
export const deleteImageByIdPromise = promisifyRoutine(deleteImageById);
export const setSelectedPropertyPromise = promisifyRoutine(setSelectedProperty);

export const updatePropertyStatusPromise = promisifyRoutine(updatePropertyStatus);
export const generatePreviewTokenPromise = promisifyRoutine(generatePreviewToken);

export const setSeenPropertyPromise: (payload: string) => Promise<any> = promisifyRoutine(setSeenProperty);
