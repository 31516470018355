import React from "react";

import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton
} from "@material-ui/core";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";

import CloseIcon from "@material-ui/icons/Close";
import AppStoreButton from "../../components/buttons/AppStoreButton";
import GooglePlayStoreButton from "../../components/buttons/GooglePlayStoreButton";
import Title from "../../components/typography/Title";
import styled, { mediaDown } from "../../utils/styledComponents";

interface OwnProps {
  rounded: "yes" | "no";
  open: boolean;
  onClose: (event: React.SyntheticEvent) => void;
  title?: string;
  description?: string;
}

type AllProps = OwnProps & DialogProps;

const StyledTitle = styled(Title)`
  && {
    font-family: ${({ theme }) => theme.fonts.MS700};
    font-weight: ${({ theme }) => theme.fontWeights.MS500};
    font-size: ${({ theme }) => theme.fontSizes.subtitle};
    line-height: 1.2em;
    text-align: center;
    color: ${({ theme }) => theme.colors.grey4};
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    position: absolute;
    margin-left: 40px;
    margin-top: -18px;
    ${mediaDown.sm`margin-left: 20px; `}
  }
`;

const BadgeContainer = styled(Grid)`
  padding-bottom: 20px;
  ${mediaDown.sm`&&{
  justify-content: center;
  padding-bottom: 0px;
}`}
`;

const StyledDialog = styled(Dialog as React.FunctionComponent<AllProps>)`
  .MuiPaper-root {
    margin: 15px;
    width: 100%;
    ${({ rounded }) => {
      return rounded === "yes" ? "border-radius: 25px" : "border-radius: 0px";
    }}
  }
`;

const Description = styled.div`
  ${mediaDown.sm`text-align: center; padding-left: 7.5px;`}
  color: ${({ theme }) => theme.colors.grey2};
  font-weight: ${({ theme }) => theme.fontWeights.MS300};
  font-size: ${({ theme }) => theme.fontSizes.formSectionDescription};
  font-family: ${({ theme }) => theme.fonts.MS300};
  margin-bottom: 20px;
  text-align: center;
`;

const DownloadDialog: React.FunctionComponent<AllProps> = ({
  description,
  open,
  rounded,
  title,
  ...props
}) => {
  const handleOnClose = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    props.onClose(event);
  };

  return (
    <StyledDialog open={open} {...props} rounded={rounded}>
      <DialogContent>
        <DialogTitle>
          <Grid container={true}>
            <Grid item={true} md={11} lg={11} sm={11} xs={11} xl={11}>
              <StyledTitle>{title}</StyledTitle>
            </Grid>
            <Grid item={true} md={1} lg={1} sm={1} xs={1} xl={1}>
              <StyledIconButton
                edge="end"
                aria-label="close"
                onClick={handleOnClose}
              >
                <CloseIcon />
              </StyledIconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Grid container={true}>
          <Grid item={true} md={12} lg={12} sm={12} xs={12} xl={12}>
            <Description>{description}</Description>
          </Grid>
        </Grid>
        <Grid container={true} justify="center" spacing={2}>
          <Grid item={true} xs={12} sm={6} md={6} lg={6} xl={6}>
            <BadgeContainer justify="flex-end" container={true}>
              <AppStoreButton />
            </BadgeContainer>
          </Grid>
          <Grid item={true} xs={12} sm={6} md={6} lg={6} xl={6}>
            <BadgeContainer container={true} justify="flex-start">
              <GooglePlayStoreButton />
            </BadgeContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};

export default DownloadDialog;
