import React from "react";

import MuiSvgIcon from "@material-ui/core/SvgIcon";

import { IconProps } from ".";

const BillingIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => {
  return (
    <MuiSvgIcon {...props}>
      <svg width="23px" height="26px" viewBox="0 0 23 26" {...props}>
        <g
          stroke={nativeColor}
          strokeWidth={1.5}
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M17 8H5m12 0a1 1 0 0 1 1 1v2.6M17 8l-4-4M5 8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.6M5 8l4-4 4 4m6 4h-4a2 2 0 1 0 0 4h4a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Z" />
        </g>
      </svg>
    </MuiSvgIcon>
  );
};

export default BillingIcon;
