import { createRoutine, promisifyRoutine } from 'redux-saga-routines';

export const fetchInvites = createRoutine("@@invites/FETCH_INVITES");
export const fetchInvitesPromise = promisifyRoutine(fetchInvites);
export const selectInvite = createRoutine("@@invites/SELECT_INVITE");
export const validateInviteToken = createRoutine("@@invites/VALIDATE");
export const acceptInvite = createRoutine("@@invites/ACCEPT");
export const acceptInvitePromise = promisifyRoutine(acceptInvite);
export const createInvite = createRoutine("@@invites/CREATE");
export const createInvitePromise = promisifyRoutine(createInvite);
