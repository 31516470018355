import { isEmpty } from "lodash";

export const DEFAULT_LOCALE_SETTINGS = Object.freeze({
  language: {
    code: "en",
    description: "English",
  },
  country: {
    code: "za",
    description: "South Africa",
    phone: {
      mask: ".. ... ....",
      dialCode: "+27",
    },
  },
  currency: {
    code: "ZAR",
    symbol: "R",
    description: "South African Rand",
  },
  measurementSystem: "metric",
});

export const DEFAULT_TAX_VALUE = 15;
export const DEFAULT_LOCALE_SETTINGS_WITH_MULTIPLE_LANGUAGES = Object.freeze({
  languages: [
    {
      code: "en",
      description: "English",
    },
  ],
  country: {
    code: "za",
    description: "South Africa",
    phone: {
      mask: ".. ... ....",
      dialCode: "+27",
    },
  },
  currencies: [
    {
      code: "ZAR",
      symbol: "R",
      description: "South African Rand",
    },
  ],
  measurementSystem: "metric",
});

export const getArrayStringData = (
  arrayData: any[],
  isCurrency = false
): object => {
  const stringValues: any = {};
  if (!isEmpty(arrayData)) {
    for (let ad of arrayData) {
      const label = isCurrency
        ? `${ad.code} - ${ad.description}`
        : ad.description;
      stringValues[label] = label;
    }
  }
  return stringValues;
};
