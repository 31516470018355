import { createRoutine } from 'redux-saga-routines';

export const fetchTenantLevels = createRoutine("@@report/FETCH_TENANT_LEVELS");
export const fetchLeaseBreakdown = createRoutine(
  "@@report/FETCH_LEASE_BREAKDOWN"
);
export const fetchClaimedRewards = createRoutine(
  "@@report/FETCH_CLAIMED_REWARDS"
);
export const fetchTenantLeaderboard = createRoutine(
  "@@report/FETCH_TENANT_LEADERBOARD"
);
export const fetchTransactionsBreakdown = createRoutine(
  "@@report/FETCH_TRANSACTIONS_BREAKDOWN"
);
