import React from 'react';

import MuiSvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '../icons';

const FacebookIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 20 20">
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"facebook-square"}</title>
      <path
        d="M17.857 0H2.143C.959 0 0 .96 0 2.143v15.714C0 19.041.96 20 2.143 20H8.27v-6.8H5.458V10H8.27V7.56c0-2.774 1.652-4.306 4.182-4.306 1.211 0 2.478.216 2.478.216v2.723h-1.396c-1.375 0-1.804.853-1.804 1.729V10h3.07l-.49 3.2h-2.58V20h6.127C19.041 20 20 19.04 20 17.857V2.143C20 .959 19.04 0 17.857 0z"
        fill="#4267B2"
        fillRule="nonzero"
      />
    </svg>
  </MuiSvgIcon>
)

export default FacebookIcon;


