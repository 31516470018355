import { Reducer } from 'redux';

import { fetchTenants, selectTenant, uploadTenants } from './routines';
import { TenantsState } from './types';

const initialState: TenantsState = {
  errors: undefined,
  loading: false,
  selectedTenant: undefined,
  tenants: []
};

const reducer: Reducer<TenantsState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger
    case fetchTenants.TRIGGER:
    case selectTenant.TRIGGER:
    case uploadTenants.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }

    // Success
    case selectTenant.SUCCESS: {
      return { ...state, selectedTenant: action.payload };
    }
    case fetchTenants.SUCCESS: {
      return { ...state, tenants: action.payload };
    }

    // Failure
    case selectTenant.FAILURE: {
      return { ...state, selectedTenant: undefined };
    }

    case fetchTenants.FAILURE: {
      return { ...state, tenants: [] };
    }

    // Fullfill
    case fetchTenants.FULFILL:
    case selectTenant.FULFILL:
    case uploadTenants.FULFILL: {
      return { ...state, loading: false };
    }

    default: {
      return state;
    }
  }
};

export { reducer as tenantsReducer };
