import React from 'react';

import MuiSvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '../icons';

const MaintenanceIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 20 26">
    <svg width={20} height={26} {...props}>
      <g
        transform="translate(-2 1)"
        stroke={nativeColor}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M13 13.5a2 2 0 0 0-2 2v6a2 2 0 1 0 4 0v-6a2 2 0 0 0-2-2zM5 3.5H4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h8a1 1 0 0 1 1 1v2.938" />
        <rect x={5} y={0.5} width={15} height={6} rx={1} />
        <path d="M20 3.5h1" />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default MaintenanceIcon;
