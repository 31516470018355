import React from "react";

import MuiSvgIcon from "@material-ui/core/SvgIcon";

import { IconProps } from "../../icons";

const AdAccountIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 40 41">
    <svg
      width={40}
      height={41}
      viewBox="0 0 40 41"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"adaccount"}</title>
      <g
        id="CMS-Settings-V2"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="adaccount" transform="translate(0.000000, 0.682439)">
          <path
            d="M14.4767857,28.9858537 L10.3303571,28.9858537 L10.3303571,15.6396886 L14.4767857,15.6396886 L14.4767857,28.9858537 Z M12.4013393,13.8191485 C11.0754464,13.8191485 10,12.7214699 10,11.3962239 C10,10.0706478 11.0751162,8.996056 12.4013393,8.996056 C13.7275623,8.996056 14.8026786,10.0706478 14.8026786,11.3962239 C14.8026786,12.7214699 13.7267857,13.8191485 12.4013393,13.8191485 Z M29.9955357,28.9858537 L25.8580357,28.9858537 L25.8580357,22.4890244 C25.8580357,20.9406729 25.8267857,18.9550348 23.7022321,18.9550348 C21.5464286,18.9550348 21.2160714,20.6372496 21.2160714,22.3774717 L21.2160714,28.9858537 L17.0741071,28.9858537 L17.0741071,15.6396886 L21.0508929,15.6396886 L21.0508929,17.4602287 L21.1089286,17.4602287 C21.6625,16.4116333 23.0147321,15.3050305 25.0321429,15.3050305 C29.2285714,15.3050305 30,18.0670753 30,21.6546102 L30,28.9858537 L29.9955357,28.9858537 Z"
            id="Shape-Copy-3"
            fill="#FFFFFF"
            fill-rule="nonzero"
          ></path>
          <rect
            id="Rectangle-Copy-21"
            fill="#F0F0F0"
            x="0"
            y="0"
            width="40"
            height="39.9804878"
            rx="19.9902439"
          ></rect>
          <g
            id="wallet-copy"
            transform="translate(10.000000, 11.317561)"
            fill="#868788"
            fill-rule="nonzero"
          >
            <path
              d="M18.015625,3.85714286 L3.125,3.85714286 C2.7796875,3.85714286 2.5,3.56946429 2.5,3.21428571 C2.5,2.85910714 2.7796875,2.57142857 3.125,2.57142857 L18.125,2.57142857 C18.4703125,2.57142857 18.75,2.28375 18.75,1.92857143 C18.75,0.8634375 17.9105469,0 16.875,0 L2.5,0 C1.11914062,0 0,1.15111607 0,2.57142857 L0,15.4285714 C0,16.8488839 1.11914062,18 2.5,18 L18.015625,18 C19.1101562,18 20,17.1349554 20,16.0714286 L20,5.78571429 C20,4.7221875 19.1101563,3.85714286 18.015625,3.85714286 Z M16.25,12.2142857 C15.5597656,12.2142857 15,11.6385268 15,10.9285714 C15,10.2186161 15.5597656,9.64285714 16.25,9.64285714 C16.9402344,9.64285714 17.5,10.2186161 17.5,10.9285714 C17.5,11.6385268 16.9402344,12.2142857 16.25,12.2142857 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </MuiSvgIcon>
);

export default AdAccountIcon;
