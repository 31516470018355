import { AnyAction } from 'redux';
import { all, fork, put, takeEvery } from 'redux-saga/effects';

import { setDeeplink, unsetDeeplink } from './routines';

function* handleSetDeeplink(action: AnyAction) {
  yield put(setDeeplink.success(action.payload));
}

function* handleUnsetDeeplink(action: AnyAction) {
  yield put(unsetDeeplink.success(action.payload));
}

function* setDeeplinkWatcher() {
  yield takeEvery(setDeeplink.TRIGGER, handleSetDeeplink);
}

function* unsetDeeplinkWatcher() {
  yield takeEvery(unsetDeeplink.TRIGGER, handleUnsetDeeplink);
}

export function* deeplinkSaga() {
  yield all([fork(setDeeplinkWatcher), fork(unsetDeeplinkWatcher)]);
}
