import React from "react";

import MuiSvgIcon from "@material-ui/core/SvgIcon";

import { IconProps } from "../icons";

const SubscriptionPlansIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => {
  return (
    <MuiSvgIcon {...props}>
      <svg width="1em" height="1em" viewBox="0 0 26 25" {...props}>
        <path
          d="M9 10V6a3 3 0 0 1 3-3v0a3 3 0 0 1 3 3v4m3-2 .917 11.923A1 1 0 0 1 17.92 21H6.08a1 1 0 0 1-.997-1.077L6 8h12Z"
          stroke={nativeColor}
          strokeWidth={1.5}
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </MuiSvgIcon>
  );
};
export default SubscriptionPlansIcon;
