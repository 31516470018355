import { createRoutine, promisifyRoutine } from "redux-saga-routines";
import { Subscription } from '../subscriptions';

export const createAccount = createRoutine("@@account/CREATE_ACCOUNT");
export const createAccountPromise = promisifyRoutine(createAccount);

export const fetchAccount = createRoutine("@@account/FETCH_ACCOUNT");
export const fetchAccountPromise = promisifyRoutine(fetchAccount);

export const updateOrganisation = createRoutine(
  "@@account/UPDATE_ORGANISATION"
);
export const updateOrganisationPromise = promisifyRoutine(updateOrganisation);

export const updateAccount = createRoutine("@@account/UPDATE_ACCOUNT");
export const updateAccountPromise = promisifyRoutine(updateAccount);

export const fetchAccountSubscriptions = createRoutine(
  "@@account/FETCH_ACCOUNT_SUBSCRIPTIONS"
);
export const fetchAccountSubscriptionsPromise = promisifyRoutine(
  fetchAccountSubscriptions
);

export const createSubscriptionCheckoutId = createRoutine(
  "@@account/CREATE_CHECKOUT_ID"
);
export const createSubscriptionCheckoutIdPromise = promisifyRoutine(
  createSubscriptionCheckoutId
);

export const fetchSubscriptionCheckoutResult = createRoutine(
  "@@account/FETCH_SUBSCRIPTION_CHECKOUT_ID"
);
export const fetchSubscriptionCheckoutResultPromise = promisifyRoutine(
  fetchSubscriptionCheckoutResult
);

export const deleteCard = createRoutine("@@account/DELETE_CARD");
export const deleteCardPromise = promisifyRoutine(deleteCard);

export const updateCard = createRoutine("@@account/UPDATE_CARD");
export const updateCardPromise = promisifyRoutine(updateCard);

export const createSubscriptionCheckoutWithExistingCard = createRoutine(
  "@@account/CREATE_SUBSCRIPTION_CHECKOUT_WITH_EXISTING_CARD"
);
export const createSubscriptionCheckoutWithExistingCardPromise =
  promisifyRoutine(updateCard);

export const updateMessage = createRoutine("@@account/UPDATE_MESSAGE");
export const updateMessagePromise = promisifyRoutine(updateMessage);

export const createBilling = createRoutine("@@billing/CREATE_BILLING_INFO");
export const createBillingPromise = promisifyRoutine(createBilling);

export const fetchBilling = createRoutine("@@billing/FETCH_BILLING_INFO");
export const fetchBillingPromise = promisifyRoutine(fetchBilling);

export const updateBilling = createRoutine("@@billing/UPDATE_BILLING");
export const updateBillingPromise = promisifyRoutine(updateBilling);

export const createAccountBillingDetails = createRoutine(
  "@@billing/account/CREATE_ACCOUNT_DETAILS"
);
export const createAccountBillingDetailsPromise = promisifyRoutine(
  createAccountBillingDetails
);

export const updateAccountBillingDetails = createRoutine(
  "@@billing/account/UPDATE_ACCOUNT_DETAILS"
);
export const updateAccountBillingDetailsPromise = promisifyRoutine(
  updateAccountBillingDetails
);

export const fetchLoggedInUserCampaignsSubscriptions = createRoutine(
  "@@account/subscriptions/FETCH_LOGGED_IN_USER_CAMPAIGNS_SUBSCRIPTIONS"
);
export const fetchLoggedInUserCampaignsSubscriptionsPromise = promisifyRoutine(
  fetchLoggedInUserCampaignsSubscriptions
);

export const fetchLoggedInUserPaymentFeeds = createRoutine(
  "@@account/payment/FETCH_LOGGED_IN_USER_PAYMENT_FEEDS"
);
export const fetchLoggedInUserPaymentFeedsPromise = promisifyRoutine(
  fetchLoggedInUserPaymentFeeds
);

export const addUserMasterCampaignSubscriptionToList = createRoutine<Subscription>(
  "@@account/subscriptions/ADD_USER_MASTER_CAMPAIGN_SUBSCRIPTION_TO_LIST"
);
export const addUserMasterCampaignSubscriptionToListPromise: (payload: Subscription) => Promise<any> = promisifyRoutine(
  addUserMasterCampaignSubscriptionToList
);
