import React from 'react';

import MuiSvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '../icons';

const EditPencilIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 12 12" fontSize="inherit">
    <svg width={12} height={12} {...props}>
      <g fillRule="nonzero" stroke="#C1C1C1" fill="none">
        <path d="M6.414 3.464L.697 9.182a.5.5 0 0 0-.147.354v1.414a.5.5 0 0 0 .5.5h1.414a.5.5 0 0 0 .354-.147l5.718-5.717-2.122-2.122zM8.536 1.343l2.121 2.121-.707.708L7.83 2.05z" />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default EditPencilIcon;
