import { Reducer } from "redux";
import { fetchFLowExtras } from "./routines";
import { FlowExtrasState } from "./types";

const initialState: FlowExtrasState = {
  errors: undefined,
  flowExtras: [],
  loading: false,
};

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    case fetchFLowExtras.TRIGGER: {
      return { ...state, errors: undefined, flowExtras: [], loading: true };
    }

    case fetchFLowExtras.SUCCESS: {
      return {
        ...state,
        errors: undefined,
        flowExtras: action.payload,
        loading: false,
      };
    }

    case fetchFLowExtras.FAILURE: {
      return { ...state, errors: action.payload, loading: false };
    }

    default: {
      return state;
    }
  }
};

export { reducer as flowExtrasReducer };
