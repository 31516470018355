import React from "react";

import MuiSvgIcon from "@material-ui/core/SvgIcon";

import { IconProps } from "../icons";

const FacebookSocialIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 40 41">
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>facebook</title>
      <g
        id="CMS-Settings-V2"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="facebook">
          <rect
            id="Rectangle-Copy-21"
            fill="#F0F0F0"
            x="0"
            y="0"
            width="40"
            height="40"
            rx="20"
          ></rect>
          <path
            d="M26.1480928,21.5 L26.822223,17.1565625 L22.6072497,17.1565625 L22.6072497,14.3379687 C22.6072497,13.1496875 23.196047,11.9914063 25.0838013,11.9914063 L27,11.9914063 L27,8.2934375 C27,8.2934375 25.2611042,8 23.598534,8 C20.1273795,8 17.8584713,10.0803125 17.8584713,13.84625 L17.8584713,17.1565625 L14,17.1565625 L14,21.5 L17.8584713,21.5 L17.8584713,32 L22.6072497,32 L22.6072497,21.5 L26.1480928,21.5 Z"
            id="Path-Copy"
            fill="#868788"
            fill-rule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  </MuiSvgIcon>
);

export default FacebookSocialIcon;
