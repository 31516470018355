import * as React from "react";

const SuccessTickIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={134} height={134} viewBox="0 0 134 134" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#F7FAFD" cx={67} cy={67} r={67} />
        <circle fill="#EAF2F9" cx={67} cy={67} r={51} />
        <circle fill={props.fill} cx={67} cy={67} r={35} />
        <path
          stroke="#FFF"
          strokeWidth={5}
          d="M83.117 55.575L59.075 79.617l-9.192-9.192"
        />
      </g>
    </svg>
  );
};

export default SuccessTickIcon;
