import produce from 'immer';
import { filter, get, has, isEmpty } from "lodash";
import { Reducer } from "redux";

import { isValidUrl } from "../../utils/general";
import { FlowExtra } from "../flowExtras";
import {
  addPropertyStepper,
  deleteImageById,
  deleteProperty,
  deselectProperty,
  getProperties,
  getPropertyById,
  savePropertyInfo,
  selectFeature,
  selectProperty,
  updateField,
  updateFlowExtras,
  updatePropertyInfo,
  updatePropertyStatus,
  uploadImage,
  setSelectedProperty,
  setSeenProperty,
} from "./routines";
import { Feature, PropertiesState, Property } from "./types";

const initialState: PropertiesState = {
  addProperty: {
    canContinue: false,
    doneUploading: false,
    submitForm: false,
  },
  errors: undefined,
  loading: false,
  properties: [],
  selectedProperty: undefined,
  totalRecords: 0,
  seenProperties: {},
  isCreatingProperty: false,
  isUpdatingProperty: false,
};

const reducer: Reducer<PropertiesState> = (state = initialState, action) => {
  switch (action.type) {
    // Trigger

    case deleteImageById.TRIGGER:
    case updateFlowExtras.TRIGGER:
    case selectProperty.TRIGGER:
    case deselectProperty.TRIGGER:
    case updateField.TRIGGER:
    case uploadImage.TRIGGER:
    case getPropertyById.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }

    case deleteProperty.TRIGGER:
    case updatePropertyStatus.TRIGGER:
    case getProperties.TRIGGER: {
      return { ...state, loading: true, errors: undefined };
    }
    case setSelectedProperty.TRIGGER: {
      return { ...state, loading: false, errors: undefined };
    }
    case savePropertyInfo.TRIGGER: {
      const newDerivedState = produce<PropertiesState>(state, (draft: PropertiesState) => {
        draft.isCreatingProperty = true;
        draft.errors = undefined;
      });

      return newDerivedState;
    }

    case updatePropertyInfo.TRIGGER: {
      const newDerivedState = produce<PropertiesState>(state, (draft: PropertiesState) => {
        draft.isUpdatingProperty = true;
        draft.errors = undefined;
        draft.loading = true;
      });

      return newDerivedState;
    }
    // Success
    case getProperties.SUCCESS: {
      return {
        ...state,
        loading: false,
        properties: loadProperties(action.payload.properties),
        totalRecords: action.payload.count,
      };
    }

    case getPropertyById.SUCCESS: {
      return {
        ...state,
        selectedProperty: selectPropertyFromResponse(action.payload),
        loading: false,
      };
    }

    case deleteImageById.SUCCESS:
    case deleteProperty.SUCCESS: {
      return { ...state, loading: false, errors: undefined };
    }

    case selectProperty.SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedProperty: selectPropertyById(
          state.properties,
          action.payload.propertyId
        ),
      };
    }

    case deselectProperty.SUCCESS: {
      return { ...state, selectedProperty: undefined, loading: false };
    }

    case updateField.SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedProperty: handleUpdateField(
          action.payload,
          state.selectedProperty
        ),
      };
    }

    case selectFeature.SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedProperty: handleFeatureSelection(
          action.payload,
          state.selectedProperty
        ),
      };
    }
    case setSelectedProperty.SUCCESS: {
      const properties = get(state, "properties", []);
      const newArray = [...properties];
      newArray.map(
        (prop) => (prop.isSelected = prop._id === action.payload.propId)
      );

      return {
        ...state,
        loading: false,
      };
    }

    case updateFlowExtras.SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedProperty: handleAddRemoveFlowExtra(
          action.payload.selectedExtra,
          state.selectedProperty
        ),
      };
    }

    case savePropertyInfo.SUCCESS: {
      return { ...state, selectedProperty: action.payload, loading: false };
    }

    case addPropertyStepper.SUCCESS: {
      return { ...state, addProperty: action.payload, loading: false };
    }

    case setSeenProperty.SUCCESS: {
      const newDerivedState = produce<PropertiesState>(state, (draft: PropertiesState) => {
        draft.seenProperties[action.payload] = true;
      });

      return newDerivedState;
    }



    // Failure
    case deleteImageById.FAILURE:
    case updateFlowExtras.FAILURE:
    case deleteProperty.FAILURE:
    case updatePropertyStatus.FAILURE:
    case getProperties.FAILURE:
    case getPropertyById.FAILURE: {
      return { ...state, errors: action.payload, loading: false };
    }

    case savePropertyInfo.FAILURE:
    case updatePropertyInfo.FAILURE: {
      const newDerivedState = produce<PropertiesState>(state, (draft: PropertiesState) => {
        draft.errors = action.payload;
      });

      return newDerivedState;
    }

    // Fulfill
    case deleteImageById.FULFILL:
    case updateFlowExtras.FULFILL:
    case deleteProperty.FULFILL:
    case updatePropertyStatus.FULFILL:
    case getProperties.FULFILL:
    case updateField.FULFILL:
    case getPropertyById.FULFILL: {
      return { ...state, loading: false };
    }

    case savePropertyInfo.FULFILL: {
      const newDerivedState = produce<PropertiesState>(state, (draft: PropertiesState) => {
        draft.isCreatingProperty = false;
      });

      return newDerivedState;
    }

    case updatePropertyInfo.FULFILL: {
      const newDerivedState = produce<PropertiesState>(state, (draft: PropertiesState) => {
        draft.isUpdatingProperty = false;
        draft.loading = false;
      });

      return newDerivedState;
    }
    default: {
      return state;
    }
  }
};

const loadProperties = (properties: Property[]): Property[] => {
  properties.forEach((property) =>
    property.units.forEach((unit) => (unit.listingType = property.listingType))
  );
  return properties;
};

const handleFeatureSelection = (payload: Feature, property?: Property) => {
  if (property) {
    Object.keys(property.features).forEach((key) => {
      const feature: Feature = property.features[key];
      if (payload.name === feature.name) {
        feature.isSelected = payload.isSelected;
      }
    });
  }

  return property;
};

const handleUpdateField = (payload: any, property?: Property) => {
  if (property) {
    if (payload.field === "videoUrl") {
      // handle video urls here
      const videos = property.videos ? property.videos : [];
      if (payload.index) {
        if (payload.value === undefined) {
          // delete the record
          videos.splice(payload.index, 1);
        } else {
          // update the value
          videos[payload.index].url = payload.value;
          videos[payload.index].isValidUrl = isValidUrl(payload.value);
        }
      } else {
        if (videos.length < 3) {
          videos.push({ url: payload.value }); // create a new record
        }
      }
      property.videos = videos;
    } else if (payload.field.startsWith("leadUrl.")) {
      const leadUrlField = payload.field.split(".")[1];
      if (property.leadUrl) {
        property.leadUrl[leadUrlField] = payload.value;
      } else {
        property.leadUrl = { [leadUrlField]: payload.value } as { url: string; title: string; message: string; };;
      }
    }
    else {
      property[payload.field] = payload.value;
    }
  }
  return property;
};

const selectPropertyById = (properties: Property[], propertyId: string) => {
  return filter(properties, (property) => property._id === propertyId)[0];
};

const selectPropertyFromResponse = (property: Property) => {
  property.videos =
    has(property, "videos") && !isEmpty(property.videos)
      ? Object.keys(property.videos).map((key) => {
        const url = property.videos[key].url;
        return { isValidUrl: url, url };
      })
      : [];
  return property;
};

const handleAddRemoveFlowExtra = (
  flowExtra: FlowExtra,
  property?: Property
) => {
  if (property) {
    let hasExtra = false;
    Object.keys(property.flowExtras).forEach((key: any) => {
      const extra = property.flowExtras[key];
      if (has(property, "flowExtras") && extra._id === flowExtra._id) {
        hasExtra = true;
        delete property.flowExtras[key];
      }
    });

    if (!hasExtra) {
      property.flowExtras.push(flowExtra);
    }
  }

  return property;
};

export { reducer as propertiesReducer };
