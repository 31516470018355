import { get } from "lodash";
import React from "react";
import styled from "../../utils/styledComponents";

const Description = styled.span<{ fontSize: number, paddingBottom? :number }>`
  && {
    color: ${({ theme }) => theme.colors.grey3};
    font-family: ${({ theme }) => theme.fonts.MS300};
    font-weight: ${({ theme }) => theme.fontWeights.MS300};
    font-size: ${({ fontSize }) => fontSize}px;
  }
`;
interface OwnProps {
  text: string;
  fontSize?: number;
}

const DescriptionOnlyWidget: React.FunctionComponent<OwnProps> = (props) => {
  const { text } = props;
  const fontSize = get(props, "fontSize", 14);
  return <Description fontSize={fontSize} >{text}</Description>;
};

export default DescriptionOnlyWidget;
