import { AnyAction } from "redux";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import callApi from "../../utils/callApi";
import { errorHandler } from "../../utils/errorHandler";
import { fetchActiveCampaigns } from "./routines";

function* handleFetchActiveCampaigns(action: AnyAction) {
  try {
    const res = yield call(callApi, "get", "/marketplace-campaigns/active");
    yield put(fetchActiveCampaigns.success(res.data));
  } catch (err) {
    if (err.response) {
      yield put(fetchActiveCampaigns.failure(errorHandler(err.response)));
    } else {
      yield put(fetchActiveCampaigns.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchActiveCampaigns.fulfill());
  }
}

function* fetchActiveCampaignsWatcher() {
  yield takeEvery(fetchActiveCampaigns.TRIGGER, handleFetchActiveCampaigns);
}

export function* campaignsSaga() {
  yield all([fork(fetchActiveCampaignsWatcher)]);
}
