import { get, has, isEmpty, omitBy, isNumber, isBoolean, omit } from "lodash";
import TagManager from "react-gtm-module";

import { Chat } from "../store/chat";
import { CampaignSteps } from "../store/socials";
import { getUnitPrice } from "./units.helper";

const gtmId: string = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID as string;
const preview: string = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENV as string;
const auth: string = process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH as string;
interface LoggedInUser {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  designation: string;
  organisationRole: string;
  isSuperUser?: boolean;
  profilePicture?: string;
  userId: string;
}

interface Organisation {
  id: string;
  name: string;
  accountType: string;
  feedType: string;
  profilePicture?: string;
  countryCode?: string;
  currencyCode?: string;
  languageCode?: string;
  subscription?: string;
}

interface Property {
  listingId?: string;
  propertyType?: string;
  title?: string;
  suburb?: string;
  city?: string;
  province?: string;
  listingType?: string;
  price?: number | string;
}

interface DataObject {
  user?: LoggedInUser;
  organisation?: Organisation;
  property?: Property;
  chat?: Chat;
}

interface CampaignDataObject {
  campaignType?: string | null;
  organisationId?: string | null;
  agentId?: string | null;
  agentRole?: string | null;
  user?: LoggedInUser;
  campaignId?: string;
  step?: string;
  data?: any;
  name?: string;
  facebookCampaignId?: string;
}

export interface DataLayer {
  event: string;
  page: string;
  data?: DataObject | CampaignDataObject;
}

export const initializeGTM = (rawData: any = undefined): void => {
  TagManager.initialize({
    auth,
    gtmId,
    preview,
    dataLayer: { data: deriveRawData(rawData) },
  });
};

export const addGTMDataLayer = (
  tagManagerArgs: DataLayer | DataObject | CampaignDataObject
): void => {
  const event = get(tagManagerArgs, "event");
  const page = get(tagManagerArgs, "page");

  TagManager.dataLayer({
    dataLayer: {
      event,
      page,
      data: { ...omit(tagManagerArgs, ["event", "page"]) },
    },
  });
};

export const deriveRawData = (rawData: any) => {
  const organisation = get(rawData, "organisation");
  let user = get(rawData, "user");
  const property = get(rawData, "property");
  const campaignData = get(rawData, "campaignData");

  const _organisation: Organisation = {
    name: get(organisation, "name", undefined),
    accountType: get(organisation, "type", undefined),
    feedType: get(organisation, "settings.syndicator.type", undefined),
    id: get(organisation, "_id", undefined),
    profilePicture: get(organisation, "logoUrl", undefined),
    countryCode: get(organisation, "settings.locale.country.code"),
    currencyCode: get(organisation, "settings.locale.currency.code"),
    languageCode: get(organisation, "settings.locale.language.code"),
    subscription: get(organisation, "subscription.product.name"),
  };

  const memberUser = get(organisation, "members", []).find(
    (member: any) =>
      has(member, "user") && get(member, "user._id") === get(user, "id")
  );

  const _user: LoggedInUser = {
    contactNumber: get(
      memberUser,
      "user.contactNumber",
      get(user, "contactNumber")
    ),
    designation: get(memberUser, "designation", "Agent"),
    email: get(user, "email", get(memberUser, "user.email")),
    firstName: get(user, "firstName", get(memberUser, "user.firstName")),
    lastName: get(user, "lastName", get(memberUser, "user.lastName")),
    organisationRole: get(memberUser, "role", "Member"),
    isSuperUser: get(user, "roles", []).includes("flow-admin")
      ? true
      : undefined,
    profilePicture: get(
      user,
      "profileImageUrl",
      get(
        user,
        "profileImage.publicUrl",
        get(
          memberUser,
          "user.profileImageUrl",
          get(memberUser, "user.profileImage.publicUrl", undefined)
        )
      )
    ),
    userId: get(memberUser, "user._id", get(user, "_id")),
  };

  const unit = get(property, "units[0]");
  const price = getUnitPrice(unit);

  const _property: Property = {
    city: get(property, "addreess.city"),
    listingId: get(property, "_id"),
    listingType: get(property, "listingType"),
    price,
    propertyType: get(property, "propertyType"),
    province: get(property, "address.province"),
    suburb: get(property, "address.suburb"),
    title: get(property, "title"),
  };

  const flowPlan = {
    adViews: get(campaignData, "flowPlan.adViews"),
    name: get(campaignData, "flowPlan.name"),
    price: get(campaignData, "flowPlan.price", get(campaignData, "price")),
    productVersion: get(campaignData, "flowPlan.productVersion"),
  };

  let step = get(rawData, "step", undefined);
  step =
    step === CampaignSteps.AdCreative
      ? "Step 1 - Ad Builder"
      : step === CampaignSteps.Targeting
      ? "Step 2 - Targeting"
      : step === CampaignSteps.Summary
      ? "Step 3 - Cart Summary"
      : undefined;

  const campaign = {
    type: get(campaignData, "campaignType"),
    billingFrequency: get(campaignData, "billingFrequency", undefined),
    budget: get(
      campaignData,
      "campaignPayload.budget",
      get(campaignData, "budget", undefined)
    ),
    creditEntity: get(campaignData, "creditEntity", undefined),
    isRecurring: get(campaignData, "isRecurring", undefined),
    numberOfWeeks: get(campaignData, "numberOfWeeks"),
    adViews: get(campaignData, "adViews"),
    flowPlan:
      !isEmpty(flowPlan) &&
      omitBy(
        flowPlan,
        (param) => isEmpty(param) && !isNumber(param) && param !== 0
      ),
    objective: get(campaignData, "objective"),
    campaignId: get(campaignData, "campaignLocalId"),
    step,
    name: get(campaignData, "name"),
    facebookCampaignId: get(campaignData, "facebookCampaignId"),
  };

  const _campaign = omitBy(
    campaign,
    (param) =>
      isEmpty(param) && !isNumber(param) && !isBoolean(param) && param !== 0
  );

  return {
    organisation: !isEmpty(_organisation.id) ? _organisation : undefined,
    property: !isEmpty(_property.listingId) ? _property : undefined,
    user: !isEmpty(_user.email) ? _user : undefined,
    campaign: isEmpty(_campaign) ? undefined : _campaign,
  };
};

export const addGTMDataLayerWithRawData = (
  tagManagerArgs: DataLayer,
  rawData: any
): void => {
  const { organisation, property, user, campaign } = deriveRawData(rawData);
  const event = get(tagManagerArgs, "event");
  const page = get(tagManagerArgs, "page");
  TagManager.dataLayer({
    dataLayer: {
      event,
      page,
      data: {
        ...omit(tagManagerArgs, ["event", "page"]),
        organisation,
        property,
        user,
        campaign,
      },
    },
  });
};
