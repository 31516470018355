import { get } from "lodash";
import { Action } from "redux-actions";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import { ApplicationState } from "../";
import callApi from "../../utils/callApi";
import { errorHandler } from "../../utils/errorHandler";
import { fetchWalletAccountByEntityId } from "./routines";
import { WalletQueryPayload } from "./types";

function* getCurrentUser(): any {
  const { auth } = yield select((state: ApplicationState) => state);
  const roles = get(auth, "user.roles", []);
  const role = roles.includes("flow-admin") ? "admin/" : "";
  const landlordUser = get(auth, "user._id", undefined);
  return { role, landlordUser };
}

function* handleFetchWalletAccountByEntityId(action: Action<WalletQueryPayload>): any {
  try {
    yield put(fetchWalletAccountByEntityId.request());

    const { role } = yield call(getCurrentUser);
    const { entityType, entityId } = action.payload;

    const response = yield call(
      callApi,
      "get",
      `/${role}creditiomanagement/history?${entityType}=${entityId}`
    );

    yield put(fetchWalletAccountByEntityId.success(response.data));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(fetchWalletAccountByEntityId.failure(errorHandler(err)));
    }
  } finally {
    yield put(fetchWalletAccountByEntityId.fulfill());
  }
}

function* handleFetchWalletAccountByEntityIdWatcher(): any {
  yield takeEvery(fetchWalletAccountByEntityId.TRIGGER, handleFetchWalletAccountByEntityId);
}

export function* billingSaga(): any {
  yield all([
    fork(handleFetchWalletAccountByEntityIdWatcher),
  ]);
}
