import { get } from "lodash";
import { Reducer } from "redux";
import { fetchWalletAccountByEntityId } from "./routines";
import { BillingState } from "./types";

const initialState: BillingState = {
  billingLoading: false,
  walletAccount: undefined,
  errors: undefined,
};

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    //#region  Trigger

    case fetchWalletAccountByEntityId.TRIGGER: {
      return { ...state, billingLoading: true, errors: undefined };
    }

    //#endregion

    //#region  Success

    case fetchWalletAccountByEntityId.SUCCESS: {
      const walletAccount = get(action, "payload", undefined);
      return {
        ...state,
        walletAccount,
      };
    }

    //#endregion

    //#region  Failure

    case fetchWalletAccountByEntityId.FAILURE: {
      return {
        ...state,
        errors: action.payload,
        billingLoading: false,
      };
    }

    //#endregion

    //#region  Fulfill

    case fetchWalletAccountByEntityId.FULFILL: {
      return {
        ...state,
        billingLoading: false,
      };
    }

    //#endregion

    default: {
      return state;
    }
  }
};

export { reducer as billingReducer };
