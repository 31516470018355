import { History } from "history";
import * as React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import MuiAppBar, { AppBarProps } from "@material-ui/core/AppBar";
import MuiToolbar from "@material-ui/core/Toolbar";

import circle_logo from "../../assets/images/brand/circleLogo.png";
import logo from "../../assets/images/brand/logo.png";
import { Account } from "../../store/account";
import { AuthenticatedUser } from "../../store/auth";
import styled, { mediaDown, mediaUp } from "../../utils/styledComponents";
import { MenuIcon } from "../icons";
import NavBarHeading from "../typography/NavBarHeading";
import { isEmpty } from "lodash";

const StyledAppBar = styled(MuiAppBar as React.FunctionComponent<AppBarProps>)`
  && {
    background-color: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey1};
    height: 60px;
    box-shadow: none;
    font-size: ${({ theme }) => theme.fontSizes.navbarItem};
    font-family: ${({ theme }) => theme.fonts.MS500};
  }
`;

const Logo = styled.img`
  height: 30px;
  border-radius: 5px;
`;
const MobileLogo = styled.img`
  height: 45px;
  margin: 10px;
  border-radius: 5px;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const RightMenuItems = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
  height: 100%;
  text-align: right;
  color: ${({ theme }) => theme.colors.grey4};
`;

const DesktopToolbar = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  ${mediaDown.sm`display: none;`}
`;

const MobileToolbar = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  ${mediaUp.sm`display: none;`}
`;

const Container = styled.div`
  display: flex;
  ${mediaUp.sm`flex-direction: column`};
  ${mediaDown.sm`flex-direction: column-reverse`};
`;

interface OwnProps {
  title: string;
  showTitle?: boolean;
  rightMenuComponent: React.FunctionComponent<{}> | null;
  mobileToggle: React.ReactEventHandler;
  authenticated: boolean;
  account: Account;
  user: AuthenticatedUser;
  upgradeSubscriptionEvent?: typeof Function;
  showDialog: (event: React.SyntheticEvent) => void;
  logoUrl?: string;
}
interface ConnectedProps {
  history: History;
}

type AllProps = OwnProps & AppBarProps & ConnectedProps;

const NavBar: React.FunctionComponent<AllProps> = ({
  title,
  showTitle,
  logoUrl,
  rightMenuComponent,
  mobileToggle,
  authenticated,
  children,
}) => {
  return (
    <Container>
      <StyledAppBar position="static">
        <MuiToolbar>
          <DesktopToolbar>
            {!authenticated && (
              <LogoWrapper>
                <Link to="/">
                  <Logo src={logo} />
                </Link>
              </LogoWrapper>
            )}
            {showTitle ? (
              <NavBarHeading>{title}</NavBarHeading>
            ) : (
              <LogoWrapper>
                <Link to="/tenants">
                  <Logo src={circle_logo} />
                </Link>
              </LogoWrapper>
            )}
            {authenticated && rightMenuComponent && (
              <RightMenuItems>
                {children}

                {rightMenuComponent({})}
              </RightMenuItems>
            )}
          </DesktopToolbar>
          <MobileToolbar>
            <LogoWrapper>
              <Link to="/tenants">
                <MobileLogo
                  src={
                    !authenticated ? logo : !isEmpty(logoUrl) ? logoUrl : logo
                  }
                />
              </Link>
            </LogoWrapper>
            {authenticated && <MenuIcon onClick={mobileToggle} />}
          </MobileToolbar>
        </MuiToolbar>
      </StyledAppBar>
    </Container>
  );
};

export default withRouter(NavBar as React.FC<any>);
